import ApiService from './ApiService'

class TechniqueService {

  getRankingsForStudent = async (studentId) => {
    var url = `/behavioursmart/user/${studentId}/technique-ranking`
    try {
      var techniques = await ApiService.get(null, url)
    } catch (err) {
      console.log(err)
    }
    return techniques
  }
}
export default new TechniqueService()
