import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Error handler
// import useErrorHandler from '../utils/useErrorHandler'
// import FlashMessage from '../utils/FlashMessage'

// Services
import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'
import OrganisationService from '../services/OrganisationService'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import Form from '../components/simple/form/Form.js'
import FormField from '../components/simple/formField/FormField.js'
import Text from '../components/simple/text/Text'
import TextInput from '../components/simple/input/TextInput'

function OrganisationEdit () {
  const history = useHistory()
  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const [organisationDetails, setOrganisationDetails] = useState({
    name: '',
    primaryColour: '',
    secondaryColour: '',
    logoUrl: ''
  })

  // const { error, showError } = useErrorHandler(null)

  const params = useParams()

  const organisationId = params.organisationid

  // Submit form values
  const submitForm = async (values) => {
    const apiToken = LocalStorageService.get('apiToken')

    if (organisationId === 'new') {
      const organisationAdded = await OrganisationService.addOrganisation(apiToken, values)
      if (organisationAdded.error) {
        NotificationService.error('Error adding the organisation, please try again - ' + organisationAdded.error)
      } else {
        NotificationService.info('Organisation added')
        console.log('Organisation created ', organisationAdded)
      }
    } else {
      const organisationUpdated = await OrganisationService.updateOrganisation(apiToken, values)
      if (organisationUpdated.error) {
        NotificationService.error('Error updating the organisation, please try again - ' + organisationUpdated.error)
      } else {
        NotificationService.info('Organisation updated')
        console.log('Organisation updated ', organisationUpdated)
      }
    }
  }

  const goBack = () => {
    history.goBack()
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get organisation details
    const getOrganisationDetails = async (organisationId) => {
      const apiToken = LocalStorageService.get('apiToken')

      const organisationDetails = await OrganisationService.getOrganisation(apiToken, organisationId)
      if (organisationDetails.error) {
        NotificationService.error(organisationDetails.error)
      } else {
        if (!unmounted) {
          setOrganisationDetails(organisationDetails.data[0])
          console.log(organisationDetails.data[0])
        }
      }
    }

    if (organisationId !== 'new') {
      getOrganisationDetails(organisationId)
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Organisation: {organisationDetails.id ? organisationDetails.name : 'New Organisation'}</Text>
          {/* {error &&
            <FlashMessage message={error.error} type={error.type} />} */}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              //   validate='blur'
              onChange={nextValue => {
                // console.log(nextValue)
                setOrganisationDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={organisationDetails}
            >
              <FormField
                direction='row'
                label='Name'
                name='name'
                required
              >
                <TextInput
                  name='name'
                  type='text'
                  // value={organisationDetails.data ? organisationDetails.data[0].name : ''}
                />
              </FormField>

              <Box direction='row-responsive' gap='medium'>
                <FormField
                  direction='row'
                  label='Primary Colour'
                  name='primaryColour'
                  required
                >
                  <TextInput
                    name='primaryColour'
                    type='text'
                    // value={organisationDetails.data ? organisationDetails.data[0].primaryColour : ''}
                  />
                </FormField>

                <FormField
                  direction='row'
                  label='Secondary Colour'
                  name='secondaryColour'
                  required
                >
                  <TextInput
                    name='secondaryColour'
                    type='text'
                    // value={organisationDetails.data ? organisationDetails.data[0].secondaryColour : ''}
                  />
                </FormField>
              </Box>

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OrganisationEdit
