import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ReportService from '../../../services/ReportService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'
import UserTagService from '../../../services/UserTagService'

import moment from 'moment'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import FieldBox from '../shared/FieldBox.js'
import FormField from '../../../components/simple/formField/FormField.js'
import IncidentDraftResults from './IncidentDraftResults.js'
import JoinBox from '../shared/JoinBox.js'
import OperatorBox from '../shared/OperatorBox.js'
import Text from '../../../components/simple/text/Text'
import ValueBox from '../shared/ValueBox.js'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

function IncidentDrafts (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  const [criteriaIndex, setCriteriaIndex] = useState(0)

  const [fields, setFields] = useState([])

  const [query, setQuery] = useState()
  const [queryResults, setQueryResults] = useState()

  // Add to Quick Search has been clicked
  // so request the name
  const [getQuickSearchName, displayQuickSearchName] = useState(false)

  // List of Quick Searches
  const [quickReportSearches, setQuickReportSearches] = useState([])

  const getQuickSearches = () => {
    (async () => {
      const params = {
        fields: 'type,key,value',
        limit: 100,
        orgId: activeOrg.id
      }

      const where = {
        organisation: activeOrg.id,
        user: activeUser.id,
        type: 'incidentSearch',
        key: 'incidentQuickSearch'
      }

      const quickSearches = await UserTagService.get(apiToken, params, where)

      var searches = []

      if (quickSearches !== undefined) {
        for (const search of quickSearches) {
          if (search.value) {
            try {
              var value = JSON.parse(search.value)
              searches = value
              // if (value) {
              //   searches.push(value)
              // }
            } catch (err) {
              console.error(err)
            }
          }
        }
      }

      if (searches.length) {
        setQuickReportSearches(searches)
      }
    })()
  }

  useEffect(() => {
    let unmounted = false

    if (!unmounted) {
      getQuickSearches()
    }

    return () => { unmounted = true }
  }, [])

  const goBack = () => {
    history.goBack()
  }

  // If a different field has been selected then
  // set the field type of the field selected
  // so that the Operator dropdown can be
  // populated accordingly
  const handleFieldChanged = (fieldName, value) => {
    const fieldIndex = fieldName.substring(fieldName.indexOf('-') + 1)
    const newFields = fields

    newFields[fieldIndex] = value

    setFields(newFields)
  }

  // Delete a Criteria row
  const removeCriteria = (index) => {
    console.log(index)

    // Remove the query
    if (query && query[`Field-${index}`]) {
      delete query[`Field-${index}`]
    }

    if (query && query[`Query-${index}`]) {
      delete query[`Query-${index}`]
    }

    if (query && query[`Value-${index}`]) {
      delete query[`Value-${index}`]
    }

    if (query && query[`Join-${index}`]) {
      delete query[`Join-${index}`]
    }

    // Rename the remaining query keys
    // eg. Field-1 -> Field-0
    //
    // if current key > index decrement by 1
    const newQuery = {}

    for (const item in query) {
      const fieldName = item.split('-')[0]
      let fieldIndex = item.split('-')[1]
      const fieldValue = query[fieldName.concat('-', fieldIndex)]

      if (parseInt(fieldIndex) > index) {
        fieldIndex--
      }

      newQuery[fieldName.concat('-', fieldIndex)] = fieldValue
    }

    console.log(newQuery)

    // Update new values
    const newFields = fields

    newFields.splice(index, 1)
    setFields(newFields)

    setQuery(newQuery)
    setCriteriaIndex(criteriaIndex - 1)
    buildCriteria()
  }

  // When Field changes default the Query box to the first option
  const setDefaultOperatorValue = (defautValue, index) => {
    if (query && query[`Field-${index}`]) {
      query[`Query-${index}`] = defautValue
    }
  }

  const criteriaRow = (index, addJoin) => {
    return (
      <>
        <Box direction='row-responsive' gap='medium'>
          <FormField
            direction='column'
            label='Field'
            name={`Field-${index}`}
          >
            <FieldBox
              handleFieldChanged={handleFieldChanged}
              name={`Field-${index}`}
            />
          </FormField>

          <FormField
            direction='column'
            label='Query'
            name={`Query-${index}`}
          >
            <OperatorBox
              index={index}
              name={`Query-${index}`}
              type={fields[index]?.type}
              operators={fields[index]?.operators}
              setDefaultOperatorValue={setDefaultOperatorValue}
            />
          </FormField>

          <FormField
            direction='column'
            label='Value'
            name={`Value-${index}`}
          >
            <ValueBox
              name={`Value-${index}`}
              type={fields[index]?.type}
              list={fields[index]?.list}
              fieldType={fields[index]?.value}
            />
          </FormField>

          <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall', vertical: 'medium' }} style={{ background: '#333' }} onClick={() => removeCriteria(index)} target='_self' />
        </Box>

        {addJoin &&
          <Box direction='row-responsive' gap='medium'>
            <FormField
              direction='column'
              label='Join'
              name={`Join-${index}`}
            >
              <JoinBox
                name={`Join-${index}`}
              />
            </FormField>
          </Box>}
      </>

    )
  }

  const buildCriteria = () => {
    const criteriaTable = []

    for (let i = 0; i <= criteriaIndex; i++) {
      criteriaTable.push(criteriaRow(i, (criteriaIndex > 0 && i !== criteriaIndex)))
    }

    return criteriaTable
  }

  const buildQuery = async () => {
    const queryArray = []

    const results = await getResults(queryArray)

    return queryArray
  }

  const getResults = async (queryArray) => {
    if (queryArray) {
      const body = {
        submissionType: 'incidentreport',
        query: queryArray,
        reports: ['incidentDrafts']
      }

      const params = {
        orgId: activeOrg.id
      }

      const results = await ReportService.getReport(apiToken, params, body)

      setQueryResults(results.incidentDrafts)

      return results.incidentDrafts
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get first 20 Published incidentreports on load
    buildQuery()

    return () => { unmounted = true }
  }, [])

  useEffect(() => {
    buildCriteria()
  }, [fields])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Incomplete Incident Reports
      </BreadcrumbBar>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad={{ horizontal: 'small', bottom: 'medium' }}
          round='small'
        >
          <Box direction='column' gap='small'>
            <Box direction='column' gap='xsmall' margin={{ top: 'small' }}>
              <Text margin={{ top: 'small', bottom: 'small' }} size='xlarge'>Results</Text>

              {queryResults?.length > 0 &&
                <IncidentDraftResults
                  results={queryResults}
                  terms={props.terms}
                />}
              {!queryResults?.length > 0 && <Text color='#666'>- No results to display -</Text>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default IncidentDrafts
