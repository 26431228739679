// Find a value in an array of objects
// Finds prop=propValue in myArray
//
// eg.
// const array = [
//    { name:"string 1", value:"this", other: "that" },
//    { name:"string 2", value:"this", other: "that" }
// ]
//
// const resultObject = search(array, "name", "string 1" )
//
// returns { name:"string 1", value:"this", other: "that" }
function search (myArray, prop, propValue) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][prop] === propValue) {
      return myArray[i]
    }
  }
}

// Return an array of unique values of prop from myArray
function getUniqueValues (myArray, prop) {
  const valueExists = []
  const uniqueValues = []
  for (let i = 0; i < myArray.length; i++) {
    if (valueExists[myArray[i][prop]]) continue
    valueExists[myArray[i][prop]] = true
    uniqueValues.push(myArray[i][prop])
  }

  return uniqueValues
}

export { search, getUniqueValues }
