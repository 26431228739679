import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// utils
import { capitalize } from '../../utils/text'

// Appt Components
import Box from '../../components/simple/box/Box'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Button from '../../components/simple/button/Button'
import Text from '../../components/simple/text/Text'

// Services
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'

const BehaviourDraftResults = (props) => {
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [submissions, setSubmissions] = useState([])
  const [responses, setResponses] = useState([])

  const history = useHistory()

  const deleteReport = async (submissionId) => {
    if (window.confirm('Are you sure you want to delete this?')) {
      await FormService.updateSubmission(apiToken, submissionId, { status: 'Archived' })
      window.location.reload() // this is not ideal
    }
  }

  const resultColumns = [
    {
      property: 'id',
      header: <Text>Draft ID</Text>,
      render: datum => (
        <Text>{datum.id}</Text>
      )
    },
    {
      property: 'reference',
      header: <Text>Created</Text>,
      render: datum => (
        <Text>{moment(datum.createdAt).fromNow()}</Text>
      )
    },
    {
      property: 'firstName',
      header: <Text>{capitalize(props?.terms?.serviceUser || 'Service User')}</Text>,
      render: datum => (
        <Text>{`${datum.firstname || ''} ${datum.lastname || ''}`}</Text>
      )
    },
    {
      property: 'actions',
      header: <Text>Edit</Text>,
      render: datum => (
        <>
          <Box direction='row' width='100%'>

            <Button
              onClick={() => { history.push(`/behaviour/${datum.user}/edit`) }}
              target='_self'
              plain
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'edit']} color='#fe8300' />
                </Box>
              }
            />

            <Button
              onClick={() => { deleteReport(datum.id) }}
              target='_self'
              plain
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'trash']} color='#fe8300' />
                </Box>
              }
            />
          </Box>
        </>
      )
    }
  ]

  return (
    <>
      <DataTable
        columns={resultColumns}
        data={props.results}
        primaryKey='id'
        // sort={sort}
        // onSort={setSort}
      />
      {!props.results.length && <Text>There are no results to display.</Text>}
    </>
  )
}

export default BehaviourDraftResults
