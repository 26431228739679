import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import List from '../../components/simple/list/List.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import SimpleCounter from '../../components/compound/simpleCounter/SimpleCounter'
import ReactToPrint from "react-to-print";

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

import { Pie, Bar } from 'react-chartjs-2'

import LocalStorageService from '../../services/LocalStorageService'
import { capitalize } from '../../utils/text'

import { backgroundColours, borderColours } from './colours.js';

const InterventionReport = (props) => {
  const history = useHistory()

  const [totalDurationPhysicalIntervention, setTotalDurationPhysicalIntervention] = useState(0)
  const [averageDurationPhysicalIntervention, setAverageDurationPhysicalIntervention] = useState(0)

  const [incidentDamageCountData, setIncidentDamageCountData] = useState([])
  const [commonInterventions, setCommonInterventions] = useState([])
  const [commonTechniques, setCommonTechniques] = useState([])
  const [commonPhysicalTechniques, setCommonPhysicalTechniques] = useState([])
  const [commonDeescalationTechniques, setCommonDeescalationTechniques] = useState([])
  const [breathingMonitoredByStudentGraph, setBreathingMonitoredByStudentGraph] = useState([])
  const [breathingMonitoredByYearGroupGraph, setBreathingMonitoredByYearGroupGraph] = useState([])
  const [breathingMonitoredByStudentGroupGraph, setBreathingMonitoredByStudentGroupGraph] = useState([])
  const [numIncidentsRestrictedInterventionUsed, setNumIncidentsRestrictedInterventionUsed] = useState(0)

  const terms = LocalStorageService.get('terms')

  // Demo Data
  const studentDataWithBreathingMonitored = {
    labels: breathingMonitoredByStudentGraph[1],
    datasets: [
      {
        label: '# of Votes',
        data: breathingMonitoredByStudentGraph[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  const yeargroupData = {
    labels: breathingMonitoredByYearGroupGraph[1],
    datasets: [
      {
        label: '# of Votes',
        data: breathingMonitoredByYearGroupGraph[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  const studentgroupData = {
    labels: breathingMonitoredByStudentGroupGraph[1],
    datasets: [
      {
        label: '# of Votes',
        data: breathingMonitoredByStudentGroupGraph[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  const damageReportChartData = {
    labels: incidentDamageCountData[1],
    datasets: [
      {
        // label: '# of Incidents',
        data: incidentDamageCountData[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  const printScreen = () => {
    window.print()
  }

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets

    // Common Interventions
    if (props?.location?.state?.commonIntervention !== null) {
      const commonIntervention = props?.location?.state?.commonIntervention

      setCommonInterventions(commonIntervention)
    }

    // Common Techniques
    if (props?.location?.state?.commonTechnique !== null) {
      const commonTechnique = props?.location?.state?.commonTechnique

      setCommonTechniques(commonTechnique)
    }

    // Common Techniques
    if (props?.location?.state?.commonPhysicalTechnique !== null) {
      const commonPhysicalTechnique = props?.location?.state?.commonPhysicalTechnique

      setCommonPhysicalTechniques(commonPhysicalTechnique)
    }

    // Common Techniques
    if (props?.location?.state?.commonDeescalationTechnique !== null) {
      const commonDeescalationTechnique = props?.location?.state?.commonDeescalationTechnique

      setCommonDeescalationTechniques(commonDeescalationTechnique)
    }

    // Average Duration Physical Intervention
    if (props?.location?.state?.averageDurationPhysicalIntervention !== undefined) {
      const averageDurationPhysicalIntervention = props?.location?.state?.averageDurationPhysicalIntervention
      if (averageDurationPhysicalIntervention?.value?.[0]?.average) {
        setAverageDurationPhysicalIntervention(averageDurationPhysicalIntervention)
      }
    }

    // Average Duration Physical Intervention
    if (props?.location?.state?.totalDurationPhysicalIntervention !== undefined) {
      const totalDurationPhysicalIntervention = props?.location?.state?.totalDurationPhysicalIntervention
      if (totalDurationPhysicalIntervention?.value?.[0]?.total) {
        setTotalDurationPhysicalIntervention(totalDurationPhysicalIntervention)
      }
    }

    if (props?.location?.state?.breathingMonitoredByStudentGraph !== null) {
      const breathingMonitoredByStudentGraph = props?.location?.state?.breathingMonitoredByStudentGraph

      const breathingMonitoredByStudentGraphLabels = []
      const breathingMonitoredByStudentGraphData = []

      for (var x in breathingMonitoredByStudentGraph) {
        if (x === 'null') { x = null }
        breathingMonitoredByStudentGraphLabels.push((breathingMonitoredByStudentGraph[x].name || 'n/a'))
        breathingMonitoredByStudentGraphData.push(breathingMonitoredByStudentGraph[x].count)
      }

      setBreathingMonitoredByStudentGraph([breathingMonitoredByStudentGraphData, breathingMonitoredByStudentGraphLabels])
    }

    if (props?.location?.state?.breathingMonitoredByYearGroupGraph !== null) {
      const breathingMonitoredByYearGroupGraph = props?.location?.state?.breathingMonitoredByYearGroupGraph

      const breathingMonitoredByYearGroupGraphLabels = []
      const breathingMonitoredByYearGroupGraphData = []

      for (var x in breathingMonitoredByYearGroupGraph) {
        if (x === 'null') { x = null }
        breathingMonitoredByYearGroupGraphLabels.push((breathingMonitoredByYearGroupGraph[x].name || 'n/a'))
        breathingMonitoredByYearGroupGraphData.push(breathingMonitoredByYearGroupGraph[x].count)
      }

      setBreathingMonitoredByYearGroupGraph([breathingMonitoredByYearGroupGraphData, breathingMonitoredByYearGroupGraphLabels])
    }

    if (props?.location?.state?.breathingMonitoredByStudentGroupGraph !== null) {
      const breathingMonitoredByStudentGroupGraph = props?.location?.state?.breathingMonitoredByStudentGroupGraph

      const breathingMonitoredByStudentGroupGraphLabels = []
      const breathingMonitoredByStudentGroupGraphData = []

      for (var x in breathingMonitoredByStudentGroupGraph) {
        if (x === 'null') { x = null }
        breathingMonitoredByStudentGroupGraphLabels.push((breathingMonitoredByStudentGroupGraph[x].groupname || 'n/a'))
        breathingMonitoredByStudentGroupGraphData.push(breathingMonitoredByStudentGroupGraph[x].count)
      }

      setBreathingMonitoredByStudentGroupGraph([breathingMonitoredByStudentGroupGraphData, breathingMonitoredByStudentGroupGraphLabels])
    }

    // Restricted Physical Intervension
    if (props?.location?.state?.numberIncidentsRestrictedInterventionUsed !== null) {
      setNumIncidentsRestrictedInterventionUsed(props?.location?.state?.numberIncidentsRestrictedInterventionUsed)
    }

    // incidentDamageCount
    const incidentDamageCount = props?.location?.state?.incidentDamageCount
    if (incidentDamageCount?.length) {
      const incidentDamageCountLabels = incidentDamageCount.map((data, index) => {

        var label = 'n/a'
        switch (data.questionKey) {
          case 'hurtingSelf':
            label = 'Hurting self'
            break
          case 'hurtingOthers':
            label = 'Hurting others'
            break
          case 'propertyDamage':
            label = 'Damage to property'
            break
          case 'commitOffence':
            label = 'Committing an offence'
            break
          case 'behaviour':
            label = 'Behaviour we are trying to support'
            break
        }


        return label
      })
      const incidentDamageCountValue = incidentDamageCount.map((data, index) => {
        return data.count
      })
      setIncidentDamageCountData([incidentDamageCountValue, incidentDamageCountLabels])
    }
  }, [])

  let componentRef = null
  let setComponentRef = (ref) => {
    componentRef = ref;
  };

  let reactToPrintContent = () => {
    return componentRef;
  };
  const reactToPrintTrigger = () => {
    return <Button label={<FontAwesome icon={['fal', 'print']} size='xs' />} className='noprint' plain target='_self' />
  };

  return (
    <>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        <Box direction='row' justify='between'>
          <Text>Global Intervention Report</Text>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </Box>
      </BreadcrumbBar>

      <Box ref={setComponentRef} direction='column' gap='small' round='small'>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto', 'auto', 'auto'],
            large: ['auto', 'auto', 'auto'],
            xlarge: ['auto', 'auto', 'auto']
          }}
          gap='small'
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Most Common Locations for Intervention</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
            {commonInterventions && typeof (commonInterventions) === 'object' &&
              <List
                border={false}
                pad={{ horizontal: 'none', vertical: 'xsmall' }}
                data={commonInterventions}
              />
            }

            {(!commonTechniques || typeof (commonInterventions) !== 'object') &&
              <Text size='large' weight='bold'>Insufficient data, please check back soon.</Text>
            }
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Most Frequent Physical Technique</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
            {commonPhysicalTechniques &&
              <List
                pad={{ horizontal: 'none', vertical: 'xsmall' }}
                border={false}
                data={commonPhysicalTechniques}
              />}
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Most Frequent Deescalation Technique</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
            {commonDeescalationTechniques &&
              <List
                pad={{ horizontal: 'none', vertical: 'xsmall' }}
                border={false}
                data={commonDeescalationTechniques}
              />}
          </Box>

          <Box
            direction='column'
            flex='grow'
            gap='medium'
          >

            <SimpleCounter count={String(numIncidentsRestrictedInterventionUsed || 0)} label='Incidents involving restrictive physical intervention' />



            <Box
              background='white'
              direction='column'
              flex='grow'
              pad='small'
              // height='xsmall'
              round='small'
            >
              <Text size='xxlarge' weight='bold'>
                {averageDurationPhysicalIntervention?.value?.[0]?.average ? parseFloat(averageDurationPhysicalIntervention.value[0].average).toFixed(2) : 'n/a'} {averageDurationPhysicalIntervention?.units || ''} </Text>
              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
              <Text size='small'>Avg. Duration of Physical Interventions</Text>
            </Box>

            <Box
              background='white'
              direction='column'
              flex='grow'
              pad='small'
              // height='xsmall'
              round='small'
            >
              <Text size='xxlarge' weight='bold'>
                {totalDurationPhysicalIntervention?.value?.[0]?.total || 'n/a'} {totalDurationPhysicalIntervention?.units || ''} </Text>
              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
              <Text size='small'>Total Duration of Physical Interventions</Text>
            </Box>
          </Box>
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>{capitalize(terms?.serviceUser || 'Service User')}</Text>
            <Text margin={{ bottom: 'small' }} size='small'>% of Incidents in which Breathing Monitored</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Pie
              data={studentDataWithBreathingMonitored}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Year Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>% of Incidents in which Breathing Monitored</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Pie
              data={yeargroupData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['1/3', '2/3'],
            large: ['1/3', '2/3'],
            xlarge: ['1/3', '2/3']
          }}
          gap='small'
        >

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>{capitalize(terms?.serviceUser || 'Service User')} Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>% of Incidents in which Breathing Monitored</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Pie
              data={studentgroupData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Total Number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
            <Bar
              data={damageReportChartData}
              height={25}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>
        </ResponsiveGrid>
      </Box>
    </>
  )
}

export default InterventionReport
