import LocalStorageService from '../services/LocalStorageService';

const userDetails = LocalStorageService.get('userDetails');
const activeOrg = LocalStorageService.get('activeOrg');
const activeSite = LocalStorageService.get('activeSite');

const currentOrgHasSitesEnabled = () => {
  // if there's no activeOrg something is wrong, return false
  if (!activeOrg) {
    return false;
  }
  // get the active org, does it have sites?
  return activeOrg.hasSites || false;
};

const hasAnyPermissionIn = permissions => {
  if (!permissions?.length) {
    return false;
  }

  for (const permission of permissions) {
    if (hasPermission(permission)) {
      return true;
    }
  }

  // otherwise, return no
  return false;
};

const hasPermission = permission => {
  // if there's no activeOrg something is wrong, return false
  if (!activeOrg) {
    return false;
  }

  // get the active org, does it have sites?
  const hasSites = currentOrgHasSitesEnabled();

  // if the org has sites, use the permission for the site
  if (hasSites) {
    // no active site or no permissions no access
    if (!activeSite || !activeSite.permissions) {
      return false;
    }
    return activeSite.permissions.includes(permission);
    // otherwise, use the permission for the org
  } else {
    // no permissions on the active org, no check
    if (!activeOrg.permissions) {
      return false;
    }

    return activeOrg.permissions.includes(permission);
  }
};

const isGlobalAdmin = () => {
  return userDetails?.isGlobalAdmin;
};

const isCurrentOrgAdmin = () => {
  if (
    userDetails &&
    userDetails.id === 1 &&
    userDetails.email === 'dean@behavioursmart.co.uk'
  )
    return true;

  if (!activeOrg || !activeOrg.admin) {
    // if there's no activeOrg something is wrong, return false
    return false;
  }

  // Return true for gloabal admin
  if (isGlobalAdmin()) return true;

  // is the org admin me?
  return activeOrg.admin === userDetails.id;
};

export {
  currentOrgHasSitesEnabled,
  hasPermission,
  hasAnyPermissionIn,
  isGlobalAdmin,
  isCurrentOrgAdmin,
};
