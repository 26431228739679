import React, { useState } from 'react'

// Appt Components
import Select from '../../../components/simple/input/Select'

const JoinBox = (props) => {
  const [queryOptions] = useState([
    {
      label: 'ALL OF THE ABOVE',
      value: 'AND'
    },
    {
      label: 'ANY OF THE ABOVE',
      value: 'OR'
    }
  ])

  return (
    <>
      <Select
        options={queryOptions}
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        defaultValue={queryOptions[0].value}
        {...props}
      />
    </>
  )
}

export default JoinBox
