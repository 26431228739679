import React, { useState, useEffect } from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'

function FollowUp (props) {
  const [questions, setQuestions] = useState(props.questions)

  const [localValues, setLocalValues] = useState(props.behaviourPlanValues)
  useEffect(() => {
    setLocalValues(props.behaviourPlanValues)
  }, [props.behaviourPlanValues])

  const previousPage = () => {
    props.previousPage(localValues)
  }

  const nextPage = () => {
    props.nextPage(localValues)
  }

  const concernColumns = [
    {
      property: 'concernCol',
      header: <Text>Log</Text>
      // primaryKey: true
    },
    {
      property: 'dateCol',
      header: <Text>Recorded</Text>
      // primaryKey: true
    },
    {
      property: 'recordedByCol',
      header: <Text>By</Text>
      // primaryKey: true
    },
    {
      property: 'actionCol',
      header: <Text>Action</Text>
    },
    {
      property: 'actionedByCol',
      header: <Text>By</Text>
    },
    {
      property: 'actionDateCol',
      header: <Text>Date</Text>
    },
    // {
    //   property: 'actions',
    //   header: <Text>Actions</Text>,
    //   render: datum => (
    //     <Box direction='row'>
    //       <Button href={`role/${datum.id}/edit`} icon={<FormEdit />} margin={{ horizontal: 'xsmall' }} primary target='_self' />
    //       <Button icon={<FormTrash />} margin={{ horizontal: 'xsmall' }} onClick={() => showDeleteRoleOptions(datum)} primary target='_self' />
    //     </Box>
    //   )
    // }
  ]

  // Submit form values
  const submitForm = async (values) => {
    // props.setBehaviourPlanValues(localValues)
    props.submitForm(true, localValues)
  }

  const deleteDraft = () => {
    props.deleteDraft()
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Follow up Support</H2>
          <Button label={<Text><FontAwesomeIcon color='#fe8300' icon={['fas', 'question-circle']} /></Text>} plain tip='This is the first page of the Incident Report' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            setLocalValues(nextValue)
          }}
          onSubmit={() => {
            submitForm(localValues)
          }}
          value={localValues}
        >
          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto', 'auto', 'auto'],
              large: ['auto', 'auto', 'auto', 'auto'],
              xlarge: ['auto', 'auto', 'auto', 'auto']
            }}
            gap='small'
          >
            <FormField
              label={questions[0].questionText}
              name={questions[0].key}
              required
            >
              <TextArea
                name={questions[0].key}
              />
            </FormField>

            <FormField
              label={questions[2].questionText}
              name={questions[2].key}
              required
            >
              <TextArea
                name={questions[2].key}
              />
            </FormField>

            <FormField
              label={questions[6].questionText}
              name={questions[6].key}
            >
              <TextArea
                name={questions[6].key}
              />
            </FormField>

            <FormField
              label={questions[1].questionText}
              name={questions[1].key}
              required
            >
              <TextArea
                name={questions[1].key}
              />
            </FormField>
          </ResponsiveGrid>

          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto', 'auto'],
              large: ['auto', 'auto', 'auto'],
              xlarge: ['auto', 'auto', 'auto']
            }}
            gap='small'
          >
            <FormField
              label={questions[3].questionText}
              name={questions[3].key}
            >
              <TextArea
                name={questions[3].key}
              />
            </FormField>

            <FormField
              label={questions[4].questionText}
              name={questions[4].key}
            >
              <TextArea
                name={questions[4].key}
              />
            </FormField>

            <FormField
              label={questions[5].questionText}
              name={questions[5].key}
            >
              <TextArea
                name={questions[5].key}
              />
            </FormField>

          </ResponsiveGrid>

        </Form>

        {props.concerns.length > 0 &&
          <>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Box>
              <Text size='xlarge'>Logs</Text>
              <DataTable
                columns={concernColumns}
                data={props.concerns}
              />
            </Box>
          </>}

        <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

        <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
          <Button label='< Back' onClick={() => previousPage()} secondary />
          <Box direction='row' gap='small'>
            <Button icon={<FontAwesome icon={['fal', 'save']} color='#fff' />} label='Save for later' onClick={() => submitForm()} />
            <Button icon={<FontAwesome icon={['fal', 'trash']} color='#fff' />} label='Delete Draft' onClick={() => deleteDraft()} />
          </Box>
          <Button label='Next Page' onClick={() => nextPage()} primary />
        </Box>
      </Box>
    </Box>
  )
}

export default FollowUp
