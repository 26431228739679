import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ReportService from '../../../services/ReportService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'
import UserTagService from '../../../services/UserTagService'

import moment from 'moment'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import FieldBox from '../shared/FieldBox.js'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import FormFieldRequired from '../../../components/simple/formField/FormFieldRequired.js'
import Select from '../../../components/simple/input/Select'
import IncidentSearchResults from './IncidentSearchResults.js'
import JoinBox from '../shared/JoinBox.js'
import OperatorBox from '../shared/OperatorBox.js'
import Text from '../../../components/simple/text/Text'
import TextInput from '../../../components/simple/input/TextInput'
import ValueBox from '../shared/ValueBox.js'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

function IncidentSearch (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  const [criteriaIndex, setCriteriaIndex] = useState(0)

  const [fields, setFields] = useState([])

  const [query, setQuery] = useState({
    'Field-0': '',
    'Value-0': ''
  })
  const [queryResults, setQueryResults] = useState()

  // Add to Quick Search has been clicked
  // so request the name
  const [getQuickSearchName, displayQuickSearchName] = useState(false)

  // List of Quick Searches
  const [quickReportSearches, setQuickReportSearches] = useState([])

  // Selected Quick Search
  const [quickSearchSelected, setQuickSearchValue] = useState([])

  // Selected Quick Search
  const [fullSelectedQuickSearch, setFullSelectedQuickSearch] = useState([])

  // Set the submission type for the prop passed in
  const [submissionType, setSubmissionType] = useState(props.submissionType)

  // Query after being built
  // const [builtQuery, setBuiltQuery] = useState()


  // Save the Quick Search just to Local Storage for now
  const saveQuickSearch = async (searchesToSave) => {
    var searchToSubmit = []
    searchToSubmit.push({
      organisation: activeOrg.id,
      user: activeUser.id,
      key: 'incidentQuickSearch',
      type: 'incidentSearch',
      value: JSON.stringify(searchesToSave)
    })

    const tagSet = await UserTagService.set(apiToken, searchToSubmit)
    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    } else {
      NotificationService.success('Quick Search has been saved')
    }
  }

  // Save the Quick Search just to Local Storage for now
  const submitQuickSearch = async (values) => {
    const queryToSave = await buildQuery()
    // const queryToSave = builtQuery

    const queryObject = {}

    queryObject.name = values.quickSearchName
    queryObject.query = queryToSave

    const searchToSubmit = []

    const quickSearchArray = quickReportSearches

    quickSearchArray.push(queryObject)

    searchToSubmit.push({
      organisation: activeOrg.id,
      user: activeUser.id,
      key: 'incidentQuickSearch',
      type: 'incidentSearch',
      value: JSON.stringify(quickSearchArray)
    })

    const tagSet = await UserTagService.set(apiToken, searchToSubmit)

    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    } else {
      values.quickSearchName = ''
      displayQuickSearchName(false)
      NotificationService.success('Quick Search has been saved')
    }
  }



  const getQuickSearches = () => {
    (async () => {
      const params = {
        fields: 'type,key,value',
        limit: 100,
        orgId: activeOrg.id
      }

      const where = {
        organisation: activeOrg.id,
        user: activeUser.id,
        type: 'incidentSearch',
        key: 'incidentQuickSearch'
      }

      const quickSearches = await UserTagService.get(apiToken, params, where)

      var searches = []

      if (quickSearches !== undefined) {
        for (const search of quickSearches) {
          if (search.value) {
            try {
              var value = JSON.parse(search.value)
              searches = value
              // if (value) {
              //   searches.push(value)
              // }
            } catch (err) {
              console.error(err)
            }
          }
        }
      }

      if (searches.length) {
        setQuickReportSearches(searches)
      }
    })()
  }

  useEffect(() => {
    let unmounted = false

    if (!unmounted) {
      getQuickSearches()
    }

    return () => { unmounted = true }
  }, [])

  const goBack = () => {
    history.goBack()
  }

  // If a different field has been selected then
  // set the field type of the field selected
  // so that the Operator dropdown can be
  // populated accordingly
  const handleFieldChanged = (fieldName, value) => {
    const fieldIndex = fieldName.substring(fieldName.indexOf('-') + 1)
    const newFields = fields

    newFields[fieldIndex] = value

    setFields(newFields)
  }

  // Delete a Criteria row
  const removeCriteria = (index) => {

    // Remove the query
    if (query && query[`Field-${index}`]) {
      delete query[`Field-${index}`]
    }

    if (query && query[`Query-${index}`]) {
      delete query[`Query-${index}`]
    }

    if (query && query[`Value-${index}`]) {
      delete query[`Value-${index}`]
    }

    if (query && query[`Join-${index}`]) {
      delete query[`Join-${index}`]
    }

    // Rename the remaining query keys
    // eg. Field-1 -> Field-0
    //
    // if current key > index decrement by 1
    const newQuery = {}

    for (const item in query) {
      const fieldName = item.split('-')[0]
      let fieldIndex = item.split('-')[1]
      const fieldValue = query[fieldName.concat('-', fieldIndex)]

      if (parseInt(fieldIndex) > index) {
        fieldIndex--
      }

      newQuery[fieldName.concat('-', fieldIndex)] = fieldValue
    }

    // Update new values
    const newFields = fields

    newFields.splice(index, 1)
    setFields(newFields)

    setQuery(newQuery)
    setCriteriaIndex(criteriaIndex - 1)
    buildCriteria()
  }

  // When Field changes default the Query box to the first option
  const setDefaultOperatorValue = (defautValue, index) => {
    if (query && query[`Field-${index}`]) {
      query[`Query-${index}`] = defautValue
    }
  }

  const criteriaRow = (index) => {
    return (
      <>
        <Box direction='row-responsive' gap='medium'>
          <FormField
            direction='column'
            label='Field'
            name={`Field-${index}`}
          >
            <FieldBox
              handleFieldChanged={handleFieldChanged}
              name={`Field-${index}`}
            />
          </FormField>

          <FormField
            direction='column'
            label='Query'
            name={`Query-${index}`}
          >
            <OperatorBox
              index={index}
              name={`Query-${index}`}
              type={fields[index]?.type}
              operators={fields[index]?.operators}
              setDefaultOperatorValue={setDefaultOperatorValue}
            />
          </FormField>

          <FormField
            direction='column'
            label='Value'
            name={`Value-${index}`}
          >
            <ValueBox
              name={`Value-${index}`}
              type={fields[index]?.type}
              list={fields[index]?.list}
              fieldType={fields[index]?.value}
            />
          </FormField>

          <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall', vertical: 'medium' }} style={{ background: '#333' }} onClick={() => removeCriteria(index)} target='_self' />
        </Box>
      </>

    )
  }

  const buildCriteria = () => {
    const criteriaTable = []

    for (let i = 0; i <= criteriaIndex; i++) {
      criteriaTable.push(criteriaRow(i))
    }

    if (criteriaIndex > 0) {
      criteriaTable.push(
        <Box direction='row-responsive' gap='medium'>
          <FormField
            direction='column'
            label='Find results that match'
            name='Join'
          >
            <JoinBox
              name='Join'
            />
          </FormField>
        </Box>)
    }

    return criteriaTable
  }

  const addCriteria = () => {
    // default the Join to AND
    query[`Join-${(criteriaIndex + 1)}`] = 'AND'
    setQuery(query)
    setCriteriaIndex(criteriaIndex + 1)
  }

  const buildQuery = async () => {
    let endOfQuery = false
    let queryCounter = 0
    const queryArray = []

    // don't try to build a query if there are no fields
    if (!query) {
      endOfQuery = true
    }

    while (!endOfQuery) {
      if (
        query[`Field-${queryCounter}`].value === 'deescalationTechniques' ||
        query[`Field-${queryCounter}`].value === 'physicalTechnique'
      ) {
        queryArray.push({
          key: query[`Value-${queryCounter}`].split(' ').join('').toLowerCase(),
          operator: 'exists',
          response: null,

          relation: queryCounter > 0 && query.Join ? query.Join : null
        })
      } else {
        queryArray.push({
          key: query[`Field-${queryCounter}`].value,
          operator: query[`Query-${queryCounter}`],
          response: (query[`Field-${queryCounter}`].type === 'autocomplete' || query[`Field-${queryCounter}`].type === 'integer' || query[`Field-${queryCounter}`].type === 'text' || query[`Field-${queryCounter}`].type === 'time') ? query[`Value-${queryCounter}`] : query[`Field-${queryCounter}`].type === 'date' ? moment(query[`Value-${queryCounter}`]).format('YYYY-MM-DD') : query[`Value-${queryCounter}`].value,
          relation: queryCounter > 0 && query.Join ? query.Join : null
        })
      }

      queryCounter++
      var checkNextCriteria = `Field-${queryCounter}` in query

      if (!checkNextCriteria) {
        endOfQuery = true
      }
    }

    // setBuiltQuery(queryArray)

    const results = await getResults(queryArray)
    // setQueryResults(results)
    return queryArray
  }

  const removeQuickSearch = async (thisQuickSearch) => {
    if (window.confirm('Are you sure you want to remove this quick search?')) {
      const valuesToSave = quickReportSearches.filter(s => s.name !== fullSelectedQuickSearch.name)
      await saveQuickSearch(valuesToSave)
      getQuickSearches()
    }
  }

  const getResults = async (queryArray) => {
    if (queryArray) {
      const body = {
        submissionType: 'incidentreport',
        query: queryArray,
        reports: ['incidentSearch']
      }

      const params = {
        orgId: activeOrg.id
      }

      const results = await ReportService.getReport(apiToken, params, body)

      setQueryResults(results.incidentSearch)

      return results.incidentSearch
    }
  }
  // Basically componentDidMount
  useEffect(() => {
    buildQuery()
  }, [])

  useEffect(() => {
    buildCriteria()
  }, [fields])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Incident Search
      </BreadcrumbBar>

      {/* Quick Search */}
      {quickReportSearches &&
        <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge'>Quick Search</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              <Select
                labelKey='name'
                name='quickSearch'
                onChange={({ value: nextValue }) => {
                  // console.log(quickReportSearches)
                  let selectedQuery = null
                  quickReportSearches.forEach((item) => {
                    if (item.name === nextValue) {
                      selectedQuery = item
                    }
                  })
                  // console.log('XXX', selectedQuery)
                  setFullSelectedQuickSearch(selectedQuery)
                  setQuickSearchValue(selectedQuery.query)
                }}
                options={quickReportSearches}
                // NOTE: Normally we'd use 'query' as the key here
                // but because that is an array it seems to mess
                // things up and when selected the item does not
                // display in the dropdown. So we'll use the 'name'
                // and search for the 'query' when selected
                valueKey={{ key: 'name', reduce: true }}
              />
              <Button
                disabled={!quickSearchSelected?.length}
                label='View Results >'
                margin={{ horizontal: 'xsmall' }}
                onClick={() => getResults(quickSearchSelected)}
                target='_self'
              />
              <Button
                disabled={!quickSearchSelected?.length}
                label='Delete'
                grey
                margin={{ horizontal: 'xsmall' }}
                onClick={() => removeQuickSearch(quickSearchSelected)}
                target='_self'
              />
            </Box>
          </Box>
        </Box>}

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad={{ horizontal: 'small', bottom: 'medium' }}
          round='small'
        >
          <Box direction='column' gap='small'>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'none', vertical: 'small' }}>
              <Text margin={{ top: 'xsmall' }} size='large'>Search Criteria</Text>
              <Box alignSelf='center' direction='row'>
                {!getQuickSearchName &&
                  <Button
                    onClick={() => {
                      // if (validateQuery()) {
                      displayQuickSearchName(true)
                      // }
                    }}
                    label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Add to Quick Search</Text>}
                    margin={{ horizontal: 'xsmall' }} target='_self'
                  />}
                {getQuickSearchName &&
                  <Form
                    onSubmit={({ value: nextValue }) => {
                      submitQuickSearch(nextValue)
                    }}
                  >
                    <FormFieldRequired
                      direction='row'
                      label='Quick Search Name'
                      name='quickSearchName'
                      required
                    >
                      <TextInput
                        name='quickSearchName'
                        type='text'
                      />
                    </FormFieldRequired>

                    <Button type='submit' label='Save' primary />
                  </Form>}
              </Box>
            </Box>

            <Form
              //   validate='blur'
              onChange={nextValue => {
                setQuery(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                buildQuery(nextValue)
              }}
              value={query}
              // value={organisationDetails}
            >
              <Box direction='column' gap='xsmall' margin={{ bottom: 'small' }}>
                {buildCriteria()}
                <Button label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add Criteria</Text>} color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} />
              </Box>
              <Box direction='row' justify='between' margin={{ top: 'medium', bottom: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button disabled={criteriaIndex < 0} type='submit' label='View Results' primary />
              </Box>
              <Divider />
              <Box direction='column' gap='xsmall' margin={{ top: 'small' }}>
                <Text margin={{ top: 'small', bottom: 'small' }} size='xlarge'>Results ({queryResults?.length || 0} found)</Text>

                {queryResults?.length &&
                  <IncidentSearchResults
                    results={queryResults}
                    terms={props.terms}
                  />}
                {!queryResults?.length && <Text color='#666'>- No results to display -</Text>}
              </Box>

            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default IncidentSearch
