import React, { useState, useEffect } from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import CheckBox from '../../components/simple/input/CheckBox'

function BehaviourDetails (props) { 
  const [specialKeys, setspecialKeys] = useState(props.specialKeys)
  const [questions, setQuestions] = useState(props.questions)

  const [localValues, setLocalValues] = useState(props.behaviourPlanValues)

  const handleCheckboxChange = (index, whichArray, localValueKey) => {
    const updatedList = { ...localValues };
    updatedList[localValueKey][whichArray][index].checked = !updatedList[localValueKey][whichArray][index].checked;
    setLocalValues(updatedList);
  };

  const handleTextAreaChange = (index, newValue, whichArray, localValueKey) => {
    setLocalValues((prevItems) => {
      prevItems[localValueKey][whichArray][index].value = newValue;
      return { ...prevItems };
    });
  };
  const addItem = (localValueKey) => {
    const updatedList = { ...localValues };
    updatedList[localValueKey].extra.push({ checked: false, value: ""}) 
    setLocalValues(updatedList);
  }
  const removeItem = (index, localValueKey) => {
    const updatedList = { ...localValues };
    delete updatedList[localValueKey].extra.splice(index, 1)
    setLocalValues(updatedList);
  }
  useEffect(() => {
    setLocalValues(props.behaviourPlanValues)
  }, [props.behaviourPlanValues])
  
  // Submit form values
  const submitForm = async (values) => {
    // props.setBehaviourPlanValues(localValues)
    props.submitForm(true, localValues)
  }
  const previousPage = () => {
    props.previousPage(localValues)
  }

  const nextPage = () => {
    props.nextPage(localValues)
  }

  const deleteDraft = () => {
    props.deleteDraft()
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Behaviour Details</H2>
          <Button label={<Text><FontAwesomeIcon color='#fe8300' icon={['fas', 'question-circle']} /></Text>} plain tip='This is the Behaviour Details page of the Incident Report' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            setLocalValues(nextValue)
          }}
          onSubmit={() => {
            submitForm(localValues)
          }}
          value={localValues}
        >
          <Box>
            <Text weight='bold'>As concisely as possible</Text>
          </Box>
          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto', 'auto', 'auto'],
              large: ['auto', 'auto', 'auto', 'auto'],
              xlarge: ['auto', 'auto', 'auto', 'auto']
            }}
            gap='small'
          >
          {/* <Grid
            rows={['auto', 'auto', 'auto', 'auto']}
            columns={['auto', 'auto', 'auto', 'auto']}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          > */}


            {
              specialKeys?.map((value, index) => {
                return (<FormField
                  label={(
                    <Box fill direction='row' gap='small'>
                      <Text size='small'>{questions[index].questionText}</Text>
                    </Box>)}
                  required
                >

                  {
                    localValues[value]?.original?.map((item, index) => (
                      <div key={`original-${value}-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckBox
                          checked={item?.checked} onChange={() => handleCheckboxChange(index, 'original', value)}
                        />
                        <TextArea
                          value={item?.value} onChange={(e) => handleTextAreaChange(index, e.target.value, 'original', value)}
                          disabled
                        />
                      </div>
                    ))
                  }

                  <Divider key={`divider-${value}`} color='primary' margin={{ top: 'small', bottom: 'small' }} />

                  {
                    localValues[value]?.extra?.map((item, index) => (
                      <div key={`extra-${value}-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckBox
                          checked={item?.checked} onChange={() => handleCheckboxChange(index, 'extra', value)}
                        />
                        <TextArea
                          value={item?.value} onChange={(e) => handleTextAreaChange(index, e.target.value, 'extra', value)}
                        />
                        <Button icon={<FontAwesome icon={['fal', 'trash']} color='#fff' />} onClick={() => removeItem(index, value)} />
                      </div>
                    ))
                  }

                  <div key={`add-${value}`}>
                    <Button icon={<FontAwesome icon={['fa', 'plus']} color='#fff' />} onClick={() => addItem(value)} />
                  </div>
                </FormField>)
              })
            }
          </ResponsiveGrid>

          <Box>
            <FormField
              label={questions[12].questionText}
              name={questions[12].key}
            >
              <TextArea
                name={questions[12].key}
              />
            </FormField>
          </Box>

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

          <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            <Box direction='row' gap='small'>
              <Button icon={<FontAwesome icon={['fal', 'save']} color='#fff' />} label='Save for later' onClick={() => submitForm()} />
              <Button icon={<FontAwesome icon={['fal', 'trash']} color='#fff' />} label='Delete Draft' onClick={() => deleteDraft()} />
            </Box>
            <Button label='Next Page' onClick={() => nextPage()} primary />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default BehaviourDetails
