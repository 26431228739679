import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import AuditTrailService from '../../services/AuditTrailService'
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import Text from '../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function IncidentEditCompletion (props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useState()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  const params = useParams()

  const userId = params.userid

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const viewReport = () => {

  }

  const viewProfile = () => {
    history.push(`/serviceuser/${userId}/profile`)
  }

  useEffect(() => {
    let unmounted = false

    const getUserDetails = async (userId) => {
      const params = {
        fields: 'id,firstName,lastName',
        limit: 1,
        orgId: activeOrg.id,
        type: 'student'
      }

      const userDetails = await UserService.getUser(apiToken, params, userId)
      if (userDetails.error) {
        // NotificationService.error(userDetails.error)
      } else {
        if (!unmounted) {
          setUserDetails(userDetails.data[0])
        }
      }
    }

    // const getAuditTrail = async (userId) => {
    //   let params = {
    //     fields: 'id,description,type,key,actionedBy,createdAt,subject,organisation,additionalData',
    //     limit: 1000,
    //     orgId: activeOrg.id,
    //     sort: 'id DESC'
    //   }

    //   let where = {
    //     subject: userId,
    //     organisation: activeOrg.id
    //   }
    //   const auditTrail = await AuditTrailService.get(apiToken, params, where)
    //   if (!auditTrail.error) {
    //     if (!unmounted) {
    //       // If an action log entry is a published submission
    //       // get and attach the file for download purposes
    //       for (let i = 0; i < auditTrail.data.length; i++) {
    //         // auditTrail.data.forEach(element => {
    //         if (auditTrail.data[i].description === 'Published incidentreport has been submitted') {
    //           // Get submission id
    //           const additionalData = JSON.parse(auditTrail.data[i].additionalData)

    //           params = {
    //             fields: 'id,pdfUrl',
    //             orgId: activeOrg.id
    //           }

    //           where = {
    //             id: additionalData.submissionId
    //           }

    //           const submission = await FormService.getSubmissions(apiToken, params, where)

    //           if (submission.data[0].pdfUrl) {
    //             auditTrail.data[i].pdfUrl = submission.data[0].pdfUrl
    //           }
    //         }
    //       }

    //       setAuditTrailDetails(auditTrail.data)
    //     }
    //   }
    // }

    const getLatestSubmission = async (userId) => {
      const params = {
        fields: 'id,pdfUrl',
        orgId: activeOrg.id,
        limit: 1,
        sort: 'id DESC'
      }

      const where = {
        status: 'Published',
        type: 'incidentreport',
        user: userId
      }

      const submission = await FormService.getSubmissions(apiToken, params, where)

      if (submission.data[0].pdfUrl) {
        setUserDetails(userDetails => ({
          ...userDetails,
          pdfUrl: submission.data[0].pdfUrl
        }))
      }
    }

    if (userId !== 'new') {
      getUserDetails(userId)
      getLatestSubmission(userId)
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Incident Report Added
      </BreadcrumbBar>

      <Box gridArea='main' background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Text>The information in this incident report has been added to {userDetails?.firstName} {userDetails?.lastName} Smart Behaviour plan.</Text>
          {userDetails?.pdfUrl &&
            <Button label='View Incident Report' onClick={() => viewReport()} />}
          <Button href={userDetails?.pdfUrl} label={`View ${userDetails?.firstName} ${userDetails?.lastName} profile`} />
        </Box>
      </Box>
    </Box>
  )
}

export default IncidentEditCompletion
