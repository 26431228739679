export const borderColours = [
  'rgba(62, 100, 137, 1)',
  'rgba(216, 233, 100, 1)',
  'rgba(90, 182, 154, 1)',
  'rgba(67, 56, 173, 1)',
  'rgba(19, 113, 39, 1)',
  'rgba(181, 19, 174, 1)',
  'rgba(237, 179, 156, 1)',
  'rgba(20, 255, 251, 1)',
  'rgba(41, 49, 113, 1)',
  'rgba(155, 99, 79, 1)',
  'rgba(52, 137, 168, 1)',
  'rgba(42, 115, 205, 1)',
  'rgba(148, 99, 115, 1)',
  'rgba(27, 43, 1, 1)',
  'rgba(27, 22, 115, 1)',
  'rgba(203, 40, 252, 1)',
  'rgba(161, 151, 92, 1)',
  'rgba(89, 13, 239, 1)',
  'rgba(174, 148, 63, 1)',
  'rgba(103, 168, 32, 1)',
  'rgba(81, 70, 8, 1)',
  'rgba(233, 254, 74, 1)',
  'rgba(34, 54, 54, 1)',
  'rgba(6, 84, 100, 1)',
  'rgba(52, 249, 140, 1)',
  'rgba(240, 80, 34, 1)',
  'rgba(70, 243, 41, 1)',
  'rgba(12, 185, 207, 1)',
  'rgba(204, 172, 106, 1)',
  'rgba(246, 10, 49, 1)',
  'rgba(89, 86, 39, 1)',
  'rgba(149, 61, 79, 1)',
  'rgba(112, 93, 93, 1)',
  'rgba(179, 41, 19, 1)',
  'rgba(193, 147, 172, 1)',
  'rgba(192, 179, 42, 1)',
  'rgba(116, 203, 201, 1)',
  'rgba(189, 43, 232, 1)',
  'rgba(221, 228, 101, 1)',
  'rgba(226, 71, 60, 1)',
  'rgba(111, 19, 193, 1)',
  'rgba(20, 106, 125, 1)',
  'rgba(5, 230, 23, 1)',
  'rgba(113, 218, 93, 1)',
  'rgba(79, 210, 70, 1)',
  'rgba(14, 162, 226, 1)',
  'rgba(56, 126, 236, 1)',
  'rgba(165, 201, 30, 1)',
  'rgba(119, 60, 243, 1)',
  'rgba(91, 141, 117, 1)',
  'rgba(202, 228, 119, 1)',
  'rgba(60, 43, 185, 1)',
  'rgba(101, 40, 230, 1)',
  'rgba(149, 179, 175, 1)',
  'rgba(234, 255, 186, 1)',
  'rgba(142, 160, 237, 1)',
  'rgba(232, 160, 78, 1)',
  'rgba(173, 23, 91, 1)',
  'rgba(30, 62, 142, 1)',
  'rgba(116, 95, 37, 1)',
  'rgba(33, 96, 45, 1)',
  'rgba(2, 97, 21, 1)',
  'rgba(51, 133, 208, 1)',
  'rgba(2, 88, 177, 1)',
  'rgba(5, 13, 80, 1)',
  'rgba(176, 128, 176, 1)',
  'rgba(228, 106, 10, 1)',
  'rgba(176, 123, 225, 1)',
  'rgba(139, 35, 86, 1)',
  'rgba(255, 208, 192, 1)',
  'rgba(79, 146, 89, 1)',
  'rgba(66, 61, 138, 1)',
  'rgba(83, 122, 86, 1)',
  'rgba(16, 100, 127, 1)',
  'rgba(218, 214, 48, 1)',
  'rgba(104, 80, 103, 1)',
  'rgba(137, 179, 196, 1)',
  'rgba(90, 29, 144, 1)',
  'rgba(238, 236, 55, 1)',
  'rgba(167, 42, 66, 1)',
  'rgba(93, 118, 161, 1)',
  'rgba(146, 103, 61, 1)',
  'rgba(178, 46, 152, 1)',
  'rgba(81, 242, 250, 1)',
  'rgba(1, 54, 110, 1)',
  'rgba(233, 250, 57, 1)',
  'rgba(100, 162, 215, 1)',
  'rgba(129, 232, 177, 1)',
  'rgba(103, 110, 104, 1)',
  'rgba(77, 201, 229, 1)',
  'rgba(190, 161, 241, 1)',
  'rgba(11, 149, 1, 1)',
  'rgba(91, 253, 11, 1)',
  'rgba(32, 19, 93, 1)',
  'rgba(91, 74, 197, 1)',
  'rgba(245, 254, 171, 1)',
  'rgba(154, 230, 188, 1)',
  'rgba(72, 91, 35, 1)',
  'rgba(93, 199, 160, 1)',
  'rgba(183, 85, 255, 1)',
  'rgba(160, 122, 0, 1)',
  'rgba(223, 7, 107, 1)',
  'rgba(0, 176, 35, 1)',
  'rgba(117, 240, 69, 1)',
  'rgba(189, 191, 135, 1)',
  'rgba(119, 38, 192, 1)',
  'rgba(104, 154, 147, 1)',
  'rgba(46, 188, 220, 1)',
  'rgba(57, 98, 248, 1)',
  'rgba(104, 223, 157, 1)',
  'rgba(250, 221, 53, 1)',
  'rgba(77, 72, 56, 1)',
  'rgba(63, 250, 57, 1)',
  'rgba(41, 111, 220, 1)',
  'rgba(19, 57, 50, 1)',
  'rgba(147, 247, 61, 1)',
  'rgba(139, 69, 227, 1)',
  'rgba(6, 42, 99, 1)',
  'rgba(9, 39, 194, 1)',
  'rgba(37, 131, 89, 1)',
  'rgba(122, 30, 136, 1)',
  'rgba(229, 244, 185, 1)',
  'rgba(162, 254, 23, 1)',
  'rgba(34, 114, 100, 1)',
  'rgba(24, 233, 126, 1)',
  'rgba(119, 197, 159, 1)',
  'rgba(63, 196, 117, 1)',
  'rgba(76, 154, 58, 1)',
  'rgba(89, 161, 161, 1)',
  'rgba(72, 47, 55, 1)',
  'rgba(232, 121, 205, 1)',
  'rgba(203, 156, 38, 1)',
  'rgba(90, 58, 225, 1)',
  'rgba(164, 108, 103, 1)',
  'rgba(187, 82, 132, 1)',
  'rgba(140, 76, 170, 1)',
  'rgba(228, 129, 52, 1)',
  'rgba(159, 148, 231, 1)',
  'rgba(20, 109, 218, 1)',
  'rgba(245, 127, 195, 1)',
  'rgba(116, 49, 169, 1)',
  'rgba(46, 170, 166, 1)',
  'rgba(34, 178, 106, 1)',
  'rgba(237, 123, 18, 1)',
  'rgba(34, 77, 28, 1)',
  'rgba(244, 38, 63, 1)',
  'rgba(108, 46, 233, 1)',
  'rgba(152, 7, 217, 1)',
  'rgba(57, 122, 57, 1)',
  'rgba(192, 245, 205, 1)',
  'rgba(204, 2, 193, 1)',
  'rgba(4, 127, 140, 1)',
  'rgba(110, 138, 77, 1)',
  'rgba(145, 150, 229, 1)',
  'rgba(39, 68, 226, 1)',
  'rgba(246, 224, 254, 1)',
  'rgba(64, 247, 213, 1)',
  'rgba(114, 153, 42, 1)',
  'rgba(251, 219, 202, 1)',
  'rgba(41, 3, 180, 1)',
  'rgba(63, 209, 13, 1)',
  'rgba(21, 179, 226, 1)',
  'rgba(93, 68, 199, 1)',
  'rgba(219, 36, 93, 1)',
  'rgba(1, 2, 188, 1)',
  'rgba(255, 197, 149, 1)',
  'rgba(34, 139, 94, 1)',
  'rgba(66, 12, 60, 1)',
  'rgba(221, 195, 14, 1)',
  'rgba(149, 53, 68, 1)',
  'rgba(160, 136, 201, 1)',
  'rgba(111, 18, 58, 1)',
  'rgba(43, 57, 240, 1)',
  'rgba(5, 219, 66, 1)',
  'rgba(104, 50, 188, 1)',
  'rgba(181, 98, 70, 1)',
  'rgba(157, 14, 204, 1)',
  'rgba(23, 126, 98, 1)',
  'rgba(113, 171, 236, 1)',
  'rgba(219, 106, 248, 1)',
  'rgba(120, 135, 57, 1)',
  'rgba(152, 187, 142, 1)',
  'rgba(68, 188, 205, 1)',
  'rgba(133, 147, 5, 1)',
  'rgba(166, 198, 13, 1)',
  'rgba(220, 219, 178, 1)',
  'rgba(145, 118, 87, 1)',
  'rgba(225, 163, 54, 1)',
  'rgba(242, 190, 93, 1)',
  'rgba(222, 151, 170, 1)',
  'rgba(52, 198, 206, 1)',
  'rgba(144, 215, 151, 1)',
  'rgba(203, 251, 170, 1)',
  'rgba(175, 20, 37, 1)',
  'rgba(38, 113, 197, 1)',
  'rgba(127, 130, 116, 1)',
  'rgba(137, 18, 73, 1)',
  'rgba(59, 248, 191, 1)',
  'rgba(87, 84, 17, 1)',
  'rgba(43, 157, 110, 1)',
  'rgba(131, 232, 148, 1)',
  'rgba(46, 106, 157, 1)',
  'rgba(142, 130, 131, 1)',
  'rgba(67, 188, 249, 1)',
  'rgba(71, 77, 111, 1)',
  'rgba(102, 233, 110, 1)',
  'rgba(234, 3, 15, 1)',
  'rgba(140, 203, 168, 1)',
  'rgba(171, 197, 118, 1)',
  'rgba(13, 231, 63, 1)',
  'rgba(104, 155, 150, 1)',
  'rgba(68, 145, 29, 1)',
  'rgba(84, 111, 16, 1)',
  'rgba(100, 170, 49, 1)',
  'rgba(101, 128, 159, 1)',
  'rgba(106, 126, 87, 1)',
  'rgba(57, 102, 64, 1)',
  'rgba(215, 147, 69, 1)',
  'rgba(245, 223, 31, 1)',
  'rgba(69, 156, 214, 1)',
  'rgba(101, 249, 202, 1)',
  'rgba(144, 169, 0, 1)',
  'rgba(156, 200, 105, 1)',
  'rgba(121, 32, 6, 1)',
  'rgba(12, 182, 231, 1)',
  'rgba(119, 243, 29, 1)',
  'rgba(43, 173, 75, 1)',
  'rgba(196, 113, 53, 1)',
  'rgba(182, 5, 160, 1)',
  'rgba(22, 5, 214, 1)',
  'rgba(151, 72, 117, 1)',
  'rgba(168, 30, 66, 1)',
  'rgba(186, 167, 1, 1)',
  'rgba(143, 176, 226, 1)',
  'rgba(106, 38, 90, 1)',
  'rgba(128, 147, 98, 1)',
  'rgba(5, 79, 26, 1)',
  'rgba(82, 150, 54, 1)',
  'rgba(122, 153, 247, 1)',
  'rgba(196, 81, 51, 1)',
  'rgba(77, 198, 105, 1)',
  'rgba(14, 86, 118, 1)',
  'rgba(193, 28, 110, 1)',
  'rgba(226, 128, 88, 1)',
  'rgba(238, 214, 239, 1)',
  'rgba(225, 204, 78, 1)',
  'rgba(234, 67, 195, 1)',
  'rgba(167, 7, 62, 1)',
  'rgba(114, 151, 202, 1)',
  'rgba(181, 22, 164, 1)',
  'rgba(155, 123, 140, 1)',
  'rgba(65, 8, 240, 1)',
  'rgba(255, 18, 38, 1)',
  'rgba(236, 94, 153, 1)',
  'rgba(211, 99, 140, 1)',
  'rgba(187, 181, 71, 1)',
  'rgba(142, 203, 191, 1)',
  'rgba(105, 169, 200, 1)',
  'rgba(93, 41, 72, 1)',
  'rgba(115, 62, 163, 1)',
  'rgba(157, 3, 111, 1)',
  'rgba(28, 104, 108, 1)',
  'rgba(229, 80, 142, 1)',
  'rgba(62, 111, 168, 1)',
  'rgba(215, 187, 160, 1)',
  'rgba(180, 216, 26, 1)',
  'rgba(78, 197, 179, 1)',
  'rgba(29, 4, 247, 1)',
  'rgba(28, 122, 238, 1)',
  'rgba(125, 239, 132, 1)',
  'rgba(8, 93, 84, 1)',
  'rgba(21, 221, 201, 1)',
  'rgba(153, 242, 94, 1)',
  'rgba(225, 67, 95, 1)',
  'rgba(181, 86, 209, 1)',
  'rgba(9, 35, 6, 1)',
  'rgba(49, 186, 227, 1)',
  'rgba(130, 110, 169, 1)',
  'rgba(238, 30, 194, 1)',
  'rgba(63, 34, 112, 1)',
  'rgba(10, 42, 193, 1)',
  'rgba(84, 232, 180, 1)',
  'rgba(182, 160, 236, 1)',
  'rgba(192, 250, 84, 1)',
  'rgba(243, 115, 32, 1)',
  'rgba(181, 137, 13, 1)',
  'rgba(19, 143, 39, 1)',
  'rgba(174, 123, 25, 1)',
  'rgba(158, 248, 27, 1)',
  'rgba(132, 190, 113, 1)',
  'rgba(3, 157, 30, 1)',
  'rgba(146, 224, 154, 1)',
  'rgba(153, 197, 25, 1)',
  'rgba(158, 35, 85, 1)',
  'rgba(120, 149, 62, 1)',
  'rgba(218, 178, 174, 1)',
  'rgba(223, 53, 12, 1)',
  'rgba(225, 241, 78, 1)',
  'rgba(20, 185, 240, 1)',
  'rgba(116, 137, 164, 1)',
  'rgba(68, 234, 211, 1)',
  'rgba(96, 68, 42, 1)',
  'rgba(191, 233, 156, 1)',
  'rgba(162, 123, 234, 1)',
  'rgba(143, 11, 124, 1)',
  'rgba(111, 210, 115, 1)',
  'rgba(106, 90, 227, 1)',
  'rgba(90, 145, 21, 1)',
  'rgba(21, 42, 217, 1)',
  'rgba(101, 17, 114, 1)',
  'rgba(161, 224, 42, 1)',
  'rgba(202, 65, 33, 1)',
  'rgba(63, 248, 219, 1)',
  'rgba(191, 4, 166, 1)',
  'rgba(172, 24, 101, 1)',
  'rgba(53, 23, 236, 1)',
  'rgba(55, 216, 169, 1)',
  'rgba(163, 91, 160, 1)',
  'rgba(157, 81, 215, 1)',
  'rgba(89, 7, 135, 1)',
  'rgba(11, 163, 242, 1)',
  'rgba(205, 87, 167, 1)',
  'rgba(230, 255, 179, 1)',
  'rgba(222, 78, 104, 1)',
  'rgba(157, 212, 220, 1)',
  'rgba(127, 67, 213, 1)',
  'rgba(41, 43, 111, 1)',
  'rgba(105, 133, 43, 1)',
  'rgba(4, 85, 60, 1)',
  'rgba(17, 104, 23, 1)',
  'rgba(215, 1, 101, 1)',
  'rgba(94, 34, 142, 1)',
  'rgba(208, 101, 242, 1)',
  'rgba(45, 51, 201, 1)',
  'rgba(251, 223, 160, 1)',
  'rgba(175, 34, 245, 1)',
  'rgba(53, 164, 2, 1)',
  'rgba(37, 121, 22, 1)',
  'rgba(232, 26, 89, 1)',
  'rgba(212, 233, 150, 1)',
  'rgba(28, 33, 102, 1)',
  'rgba(169, 13, 196, 1)',
  'rgba(67, 82, 80, 1)',
  'rgba(137, 214, 57, 1)',
  'rgba(112, 54, 24, 1)',
  'rgba(101, 247, 10, 1)',
  'rgba(237, 80, 29, 1)',
  'rgba(130, 38, 205, 1)',
  'rgba(115, 63, 132, 1)',
  'rgba(68, 19, 69, 1)',
  'rgba(195, 64, 165, 1)',
  'rgba(4, 150, 126, 1)',
  'rgba(178, 216, 160, 1)',
  'rgba(85, 118, 12, 1)',
  'rgba(253, 2, 49, 1)',
  'rgba(67, 112, 206, 1)',
  'rgba(90, 163, 171, 1)',
  'rgba(157, 25, 36, 1)',
  'rgba(100, 25, 237, 1)',
  'rgba(116, 86, 248, 1)',
  'rgba(177, 89, 248, 1)',
  'rgba(11, 197, 215, 1)',
  'rgba(126, 14, 131, 1)',
  'rgba(244, 131, 109, 1)',
  'rgba(51, 225, 244, 1)',
  'rgba(93, 147, 106, 1)',
  'rgba(109, 185, 115, 1)',
  'rgba(114, 33, 14, 1)',
  'rgba(186, 194, 26, 1)',
  'rgba(22, 76, 19, 1)',
  'rgba(7, 90, 187, 1)',
  'rgba(209, 36, 245, 1)',
  'rgba(115, 192, 53, 1)',
  'rgba(100, 89, 42, 1)',
  'rgba(198, 49, 47, 1)',
  'rgba(212, 94, 123, 1)',
  'rgba(106, 183, 223, 1)',
  'rgba(181, 2, 13, 1)',
  'rgba(249, 233, 115, 1)',
  'rgba(203, 90, 37, 1)',
  'rgba(231, 137, 223, 1)',
  'rgba(125, 64, 72, 1)',
  'rgba(197, 130, 49, 1)',
  'rgba(227, 108, 134, 1)',
  'rgba(19, 191, 75, 1)',
  'rgba(198, 176, 206, 1)',
  'rgba(228, 242, 177, 1)',
  'rgba(20, 82, 24, 1)',
  'rgba(80, 152, 82, 1)',
  'rgba(232, 125, 50, 1)',
  'rgba(178, 133, 35, 1)',
  'rgba(70, 165, 111, 1)',
  'rgba(200, 67, 108, 1)',
  'rgba(251, 133, 36, 1)',
  'rgba(186, 33, 222, 1)',
  'rgba(114, 66, 72, 1)',
  'rgba(33, 84, 17, 1)',
  'rgba(8, 145, 175, 1)',
  'rgba(30, 226, 241, 1)',
  'rgba(220, 48, 255, 1)',
  'rgba(22, 0, 25, 1)',
  'rgba(83, 67, 138, 1)',
  'rgba(61, 201, 217, 1)',
  'rgba(145, 119, 66, 1)',
  'rgba(154, 133, 169, 1)',
  'rgba(66, 151, 89, 1)',
  'rgba(167, 234, 201, 1)',
  'rgba(71, 213, 42, 1)',
  'rgba(46, 233, 110, 1)',
  'rgba(167, 79, 80, 1)',
  'rgba(75, 87, 183, 1)',
  'rgba(31, 170, 200, 1)',
  'rgba(140, 242, 192, 1)',
  'rgba(223, 208, 37, 1)',
  'rgba(254, 1, 232, 1)',
  'rgba(177, 34, 136, 1)',
  'rgba(186, 250, 59, 1)',
  'rgba(22, 226, 180, 1)',
  'rgba(225, 176, 41, 1)',
  'rgba(0, 234, 39, 1)',
  'rgba(196, 20, 253, 1)',
  'rgba(10, 34, 210, 1)',
  'rgba(127, 249, 28, 1)',
  'rgba(119, 150, 3, 1)',
  'rgba(162, 4, 169, 1)',
  'rgba(43, 204, 205, 1)',
  'rgba(53, 25, 15, 1)',
  'rgba(252, 238, 104, 1)',
  'rgba(232, 111, 147, 1)',
  'rgba(232, 36, 66, 1)',
  'rgba(47, 234, 3, 1)',
  'rgba(147, 73, 243, 1)',
  'rgba(27, 144, 232, 1)',
  'rgba(248, 39, 216, 1)',
  'rgba(49, 246, 37, 1)',
  'rgba(168, 66, 161, 1)',
  'rgba(233, 12, 19, 1)',
  'rgba(0, 6, 119, 1)',
  'rgba(185, 196, 47, 1)',
  'rgba(204, 167, 59, 1)',
  'rgba(230, 176, 54, 1)',
  'rgba(133, 163, 106, 1)',
  'rgba(184, 192, 99, 1)',
  'rgba(76, 22, 139, 1)',
  'rgba(252, 219, 97, 1)',
  'rgba(167, 34, 108, 1)',
  'rgba(159, 190, 164, 1)',
  'rgba(199, 37, 53, 1)',
  'rgba(127, 132, 137, 1)',
  'rgba(214, 47, 187, 1)',
  'rgba(120, 188, 144, 1)',
  'rgba(59, 221, 120, 1)',
  'rgba(87, 143, 145, 1)',
  'rgba(132, 204, 1, 1)',
  'rgba(251, 37, 97, 1)',
  'rgba(243, 44, 98, 1)',
  'rgba(108, 128, 204, 1)',
  'rgba(57, 223, 128, 1)',
  'rgba(61, 44, 204, 1)',
  'rgba(252, 242, 143, 1)',
  'rgba(108, 182, 89, 1)',
  'rgba(232, 230, 30, 1)',
  'rgba(65, 157, 189, 1)',
  'rgba(182, 89, 20, 1)',
  'rgba(248, 195, 211, 1)',
  'rgba(226, 196, 74, 1)',
  'rgba(246, 148, 13, 1)',
  'rgba(165, 21, 113, 1)',
  'rgba(98, 29, 112, 1)',
  'rgba(112, 103, 51, 1)',
  'rgba(158, 126, 212, 1)',
  'rgba(148, 20, 237, 1)',
  'rgba(96, 128, 135, 1)',
  'rgba(34, 253, 142, 1)',
  'rgba(10, 94, 122, 1)',
  'rgba(109, 162, 72, 1)',
  'rgba(227, 205, 138, 1)',
  'rgba(199, 16, 5, 1)',
  'rgba(176, 208, 143, 1)',
  'rgba(203, 120, 120, 1)',
  'rgba(180, 159, 130, 1)',
  'rgba(21, 53, 1, 1)',
  'rgba(64, 181, 244, 1)',
  'rgba(79, 90, 30, 1)',
  'rgba(41, 155, 44, 1)',
  'rgba(155, 19, 9, 1)',
  'rgba(160, 173, 235, 1)',
  'rgba(220, 44, 73, 1)',
  'rgba(64, 254, 64, 1)',
  'rgba(67, 216, 194, 1)',
  'rgba(30, 78, 90, 1)',
  'rgba(214, 206, 0, 1)',
  'rgba(170, 194, 172, 1)',
  'rgba(165, 150, 173, 1)',
  'rgba(243, 81, 236, 1)',
  'rgba(34, 200, 189, 1)',
  'rgba(81, 130, 88, 1)',
  'rgba(37, 184, 218, 1)',
  'rgba(140, 176, 197, 1)',
  'rgba(140, 174, 53, 1)',
  'rgba(109, 193, 56, 1)',
  'rgba(7, 186, 50, 1)',
  'rgba(90, 125, 35, 1)',
  'rgba(48, 132, 47, 1)',
  'rgba(86, 129, 29, 1)',
  'rgba(204, 69, 89, 1)',
  'rgba(62, 20, 248, 1)',
  'rgba(68, 17, 137, 1)',
  'rgba(13, 177, 99, 1)',
  'rgba(48, 213, 58, 1)',
  'rgba(241, 49, 156, 1)',
  'rgba(203, 39, 108, 1)',
  'rgba(55, 121, 160, 1)',
  'rgba(37, 211, 75, 1)',
  'rgba(91, 70, 139, 1)',
  'rgba(3, 36, 222, 1)',
  'rgba(106, 140, 106, 1)',
  'rgba(200, 134, 220, 1)',
  'rgba(14, 182, 211, 1)',
  'rgba(51, 218, 16, 1)',
  'rgba(109, 75, 212, 1)',
  'rgba(211, 108, 22, 1)',
  'rgba(100, 59, 91, 1)',
  'rgba(131, 71, 86, 1)',
  'rgba(110, 95, 33, 1)',
  'rgba(64, 182, 161, 1)',
  'rgba(13, 203, 37, 1)',
  'rgba(5, 255, 177, 1)',
  'rgba(10, 24, 12, 1)',
  'rgba(23, 24, 137, 1)',
  'rgba(239, 137, 225, 1)',
  'rgba(4, 53, 126, 1)',
  'rgba(211, 98, 159, 1)',
  'rgba(171, 133, 233, 1)',
  'rgba(137, 11, 100, 1)',
  'rgba(142, 211, 72, 1)',
  'rgba(226, 74, 75, 1)',
  'rgba(200, 17, 204, 1)',
  'rgba(123, 72, 94, 1)',
  'rgba(135, 198, 72, 1)',
  'rgba(216, 190, 33, 1)',
  'rgba(133, 196, 116, 1)',
  'rgba(1, 144, 191, 1)',
  'rgba(224, 40, 123, 1)',
  'rgba(161, 159, 212, 1)',
  'rgba(247, 118, 96, 1)',
  'rgba(32, 221, 67, 1)',
  'rgba(155, 221, 159, 1)',
  'rgba(239, 71, 239, 1)',
  'rgba(137, 94, 122, 1)',
  'rgba(99, 168, 80, 1)',
  'rgba(122, 230, 73, 1)',
  'rgba(178, 21, 138, 1)',
  'rgba(132, 243, 17, 1)',
  'rgba(225, 161, 88, 1)',
  'rgba(238, 180, 126, 1)',
  'rgba(121, 202, 114, 1)',
  'rgba(245, 90, 200, 1)',
  'rgba(29, 42, 165, 1)',
  'rgba(145, 229, 122, 1)',
  'rgba(195, 214, 216, 1)',
  'rgba(32, 230, 223, 1)',
  'rgba(80, 200, 30, 1)',
  'rgba(172, 169, 190, 1)',
  'rgba(223, 32, 107, 1)',
  'rgba(169, 125, 108, 1)',
  'rgba(195, 165, 91, 1)',
  'rgba(215, 130, 75, 1)',
  'rgba(171, 34, 237, 1)',
  'rgba(229, 88, 21, 1)',
  'rgba(82, 146, 193, 1)',
  'rgba(255, 166, 219, 1)',
  'rgba(93, 205, 10, 1)',
  'rgba(120, 147, 86, 1)',
  'rgba(229, 68, 114, 1)',
  'rgba(172, 59, 201, 1)',
  'rgba(164, 94, 205, 1)',
  'rgba(65, 2, 111, 1)',
  'rgba(211, 47, 115, 1)',
  'rgba(203, 176, 226, 1)',
  'rgba(189, 171, 82, 1)',
  'rgba(222, 219, 102, 1)',
  'rgba(23, 226, 252, 1)',
  'rgba(107, 136, 54, 1)',
  'rgba(240, 78, 172, 1)',
  'rgba(140, 82, 231, 1)',
  'rgba(105, 44, 188, 1)',
  'rgba(15, 144, 136, 1)',
  'rgba(131, 248, 67, 1)',
  'rgba(117, 245, 208, 1)',
  'rgba(225, 251, 17, 1)',
  'rgba(234, 150, 116, 1)',
  'rgba(46, 223, 6, 1)',
  'rgba(10, 205, 29, 1)',
  'rgba(68, 168, 76, 1)',
  'rgba(26, 159, 29, 1)',
  'rgba(91, 241, 86, 1)',
  'rgba(250, 188, 117, 1)',
  'rgba(27, 100, 74, 1)',
  'rgba(63, 233, 201, 1)',
  'rgba(253, 69, 240, 1)',
  'rgba(128, 66, 238, 1)',
  'rgba(251, 60, 66, 1)',
  'rgba(175, 71, 193, 1)',
  'rgba(63, 130, 244, 1)',
  'rgba(108, 52, 149, 1)',
  'rgba(1, 163, 237, 1)',
  'rgba(73, 140, 98, 1)',
  'rgba(3, 15, 242, 1)',
  'rgba(105, 229, 126, 1)',
  'rgba(112, 213, 139, 1)',
  'rgba(226, 67, 22, 1)',
  'rgba(220, 188, 226, 1)',
  'rgba(24, 223, 190, 1)',
  'rgba(244, 73, 41, 1)',
  'rgba(206, 227, 190, 1)',
  'rgba(129, 131, 112, 1)',
  'rgba(138, 246, 197, 1)',
  'rgba(131, 111, 10, 1)',
  'rgba(52, 75, 121, 1)',
  'rgba(51, 110, 130, 1)',
  'rgba(32, 214, 178, 1)',
  'rgba(78, 9, 107, 1)',
  'rgba(6, 247, 219, 1)',
  'rgba(145, 1, 161, 1)',
  'rgba(207, 195, 104, 1)',
  'rgba(94, 8, 149, 1)',
  'rgba(153, 24, 224, 1)',
  'rgba(223, 48, 206, 1)',
  'rgba(62, 158, 249, 1)',
  'rgba(255, 222, 54, 1)',
  'rgba(183, 86, 1, 1)',
  'rgba(253, 218, 52, 1)',
  'rgba(243, 49, 209, 1)',
  'rgba(221, 232, 122, 1)',
  'rgba(36, 99, 5, 1)',
  'rgba(203, 133, 75, 1)',
  'rgba(213, 210, 193, 1)',
  'rgba(192, 78, 228, 1)',
  'rgba(116, 92, 210, 1)',
  'rgba(81, 85, 96, 1)',
  'rgba(218, 176, 101, 1)',
  'rgba(109, 13, 161, 1)',
  'rgba(131, 119, 192, 1)',
  'rgba(214, 6, 166, 1)',
  'rgba(110, 34, 86, 1)',
  'rgba(135, 161, 141, 1)',
  'rgba(173, 187, 6, 1)',
  'rgba(76, 72, 176, 1)',
  'rgba(191, 234, 189, 1)',
  'rgba(163, 109, 141, 1)',
  'rgba(185, 107, 31, 1)',
  'rgba(199, 53, 172, 1)',
  'rgba(55, 217, 48, 1)',
  'rgba(177, 100, 73, 1)',
  'rgba(166, 184, 139, 1)',
  'rgba(164, 20, 246, 1)',
  'rgba(0, 248, 242, 1)',
  'rgba(253, 168, 136, 1)',
  'rgba(189, 195, 137, 1)',
  'rgba(118, 231, 251, 1)',
  'rgba(225, 237, 15, 1)',
  'rgba(114, 114, 10, 1)',
  'rgba(2, 120, 210, 1)',
  'rgba(249, 110, 186, 1)',
  'rgba(42, 100, 203, 1)',
  'rgba(83, 191, 150, 1)',
  'rgba(19, 155, 137, 1)',
  'rgba(245, 35, 99, 1)',
  'rgba(229, 45, 153, 1)',
  'rgba(19, 63, 26, 1)',
  'rgba(212, 101, 213, 1)',
  'rgba(9, 73, 175, 1)',
  'rgba(155, 189, 58, 1)',
  'rgba(170, 138, 113, 1)',
  'rgba(241, 154, 24, 1)',
  'rgba(216, 158, 203, 1)',
  'rgba(177, 133, 251, 1)',
  'rgba(178, 53, 154, 1)',
  'rgba(147, 144, 188, 1)',
  'rgba(100, 249, 219, 1)',
  'rgba(236, 20, 132, 1)',
  'rgba(8, 10, 67, 1)',
  'rgba(16, 34, 180, 1)',
  'rgba(204, 111, 187, 1)',
  'rgba(179, 184, 206, 1)',
  'rgba(16, 14, 227, 1)',
  'rgba(166, 183, 45, 1)',
  'rgba(173, 100, 63, 1)',
  'rgba(70, 87, 16, 1)',
  'rgba(80, 89, 29, 1)',
  'rgba(247, 168, 29, 1)',
  'rgba(174, 167, 10, 1)',
  'rgba(132, 207, 157, 1)',
  'rgba(1, 54, 98, 1)',
  'rgba(174, 115, 38, 1)',
  'rgba(160, 110, 1, 1)',
  'rgba(124, 131, 238, 1)',
  'rgba(110, 242, 35, 1)',
  'rgba(59, 1, 136, 1)',
  'rgba(57, 184, 160, 1)',
  'rgba(183, 142, 220, 1)',
  'rgba(120, 107, 154, 1)',
  'rgba(171, 125, 124, 1)',
  'rgba(163, 212, 234, 1)',
  'rgba(250, 0, 49, 1)',
  'rgba(24, 24, 73, 1)',
  'rgba(104, 31, 79, 1)',
  'rgba(4, 146, 155, 1)',
  'rgba(183, 132, 27, 1)',
  'rgba(225, 232, 158, 1)',
  'rgba(45, 175, 205, 1)',
  'rgba(25, 238, 142, 1)',
  'rgba(3, 77, 8, 1)',
  'rgba(205, 198, 12, 1)',
  'rgba(17, 223, 159, 1)',
  'rgba(186, 184, 25, 1)',
  'rgba(215, 68, 57, 1)',
  'rgba(143, 29, 146, 1)',
  'rgba(55, 247, 237, 1)',
  'rgba(44, 141, 36, 1)',
  'rgba(205, 125, 177, 1)',
  'rgba(162, 237, 137, 1)',
  'rgba(238, 234, 201, 1)',
  'rgba(74, 205, 12, 1)',
  'rgba(162, 116, 165, 1)',
  'rgba(174, 156, 225, 1)',
  'rgba(67, 209, 116, 1)',
  'rgba(223, 144, 250, 1)',
  'rgba(234, 214, 0, 1)',
  'rgba(16, 186, 194, 1)',
  'rgba(166, 238, 13, 1)',
  'rgba(111, 254, 250, 1)',
  'rgba(54, 56, 209, 1)',
  'rgba(211, 75, 130, 1)',
  'rgba(21, 240, 66, 1)',
  'rgba(60, 49, 202, 1)',
  'rgba(139, 77, 246, 1)',
  'rgba(39, 69, 204, 1)',
  'rgba(145, 174, 13, 1)',
  'rgba(79, 238, 215, 1)',
  'rgba(19, 113, 8, 1)',
  'rgba(55, 233, 147, 1)',
  'rgba(111, 144, 248, 1)',
  'rgba(140, 159, 217, 1)',
  'rgba(192, 9, 7, 1)',
  'rgba(197, 203, 104, 1)',
  'rgba(7, 33, 6, 1)',
  'rgba(189, 170, 156, 1)',
  'rgba(239, 219, 157, 1)',
  'rgba(59, 164, 180, 1)',
  'rgba(131, 100, 238, 1)',
  'rgba(118, 207, 150, 1)',
  'rgba(15, 137, 223, 1)',
  'rgba(26, 88, 19, 1)',
  'rgba(235, 31, 81, 1)',
  'rgba(252, 150, 144, 1)',
  'rgba(197, 8, 130, 1)',
  'rgba(13, 90, 45, 1)',
  'rgba(83, 105, 212, 1)',
  'rgba(133, 205, 32, 1)',
  'rgba(30, 108, 196, 1)',
  'rgba(171, 207, 243, 1)',
  'rgba(181, 61, 23, 1)',
  'rgba(133, 122, 83, 1)',
  'rgba(124, 88, 212, 1)',
  'rgba(52, 1, 56, 1)',
  'rgba(214, 97, 70, 1)',
  'rgba(199, 2, 214, 1)',
  'rgba(201, 28, 17, 1)',
  'rgba(179, 242, 196, 1)',
  'rgba(205, 16, 8, 1)',
  'rgba(39, 3, 252, 1)',
  'rgba(227, 12, 255, 1)',
  'rgba(248, 43, 217, 1)',
  'rgba(137, 226, 142, 1)',
  'rgba(197, 33, 204, 1)',
  'rgba(103, 146, 144, 1)',
  'rgba(86, 217, 105, 1)',
  'rgba(86, 71, 94, 1)',
  'rgba(33, 5, 158, 1)',
  'rgba(190, 168, 91, 1)',
  'rgba(200, 66, 233, 1)',
  'rgba(21, 187, 78, 1)',
  'rgba(20, 19, 6, 1)',
  'rgba(165, 48, 24, 1)',
  'rgba(13, 201, 220, 1)',
  'rgba(89, 74, 234, 1)',
  'rgba(175, 156, 115, 1)',
  'rgba(35, 106, 200, 1)',
  'rgba(185, 96, 94, 1)',
  'rgba(16, 41, 94, 1)',
  'rgba(80, 104, 116, 1)',
  'rgba(41, 234, 153, 1)',
  'rgba(251, 107, 93, 1)',
  'rgba(179, 137, 33, 1)',
  'rgba(167, 106, 181, 1)',
  'rgba(26, 157, 163, 1)',
  'rgba(36, 82, 100, 1)',
  'rgba(214, 72, 225, 1)',
  'rgba(46, 203, 115, 1)',
  'rgba(87, 80, 50, 1)',
  'rgba(211, 172, 140, 1)',
  'rgba(85, 185, 22, 1)',
  'rgba(248, 89, 242, 1)',
  'rgba(126, 176, 193, 1)',
  'rgba(179, 132, 196, 1)',
  'rgba(165, 0, 227, 1)',
  'rgba(8, 194, 224, 1)',
  'rgba(114, 91, 176, 1)',
  'rgba(224, 116, 184, 1)',
  'rgba(74, 15, 207, 1)',
  'rgba(253, 65, 8, 1)',
  'rgba(118, 251, 185, 1)',
  'rgba(253, 78, 164, 1)',
  'rgba(140, 69, 82, 1)',
  'rgba(192, 87, 12, 1)',
  'rgba(134, 200, 187, 1)',
  'rgba(26, 153, 223, 1)',
  'rgba(7, 64, 186, 1)',
  'rgba(148, 217, 246, 1)',
  'rgba(97, 155, 60, 1)',
  'rgba(208, 80, 196, 1)',
  'rgba(209, 25, 147, 1)',
  'rgba(230, 149, 236, 1)',
  'rgba(95, 152, 49, 1)',
  'rgba(42, 49, 76, 1)',
  'rgba(224, 215, 203, 1)',
  'rgba(121, 128, 74, 1)',
  'rgba(100, 25, 88, 1)',
  'rgba(220, 191, 39, 1)',
  'rgba(30, 26, 95, 1)',
  'rgba(82, 229, 56, 1)',
  'rgba(146, 48, 48, 1)',
  'rgba(12, 222, 97, 1)',
  'rgba(227, 88, 69, 1)',
  'rgba(84, 175, 21, 1)',
  'rgba(114, 118, 119, 1)',
  'rgba(113, 176, 57, 1)',
  'rgba(235, 81, 15, 1)',
  'rgba(255, 89, 226, 1)',
  'rgba(123, 100, 170, 1)',
  'rgba(223, 165, 25, 1)',
  'rgba(118, 135, 37, 1)',
  'rgba(17, 69, 90, 1)',
  'rgba(116, 118, 122, 1)',
  'rgba(227, 99, 78, 1)',
  'rgba(214, 67, 200, 1)',
  'rgba(181, 214, 76, 1)',
  'rgba(223, 136, 108, 1)',
  'rgba(81, 203, 16, 1)',
  'rgba(239, 143, 109, 1)',
  'rgba(47, 247, 74, 1)',
  'rgba(215, 204, 119, 1)',
  'rgba(90, 174, 91, 1)',
  'rgba(77, 3, 42, 1)',
  'rgba(46, 58, 121, 1)',
  'rgba(253, 10, 162, 1)',
  'rgba(171, 179, 206, 1)',
  'rgba(175, 194, 20, 1)',
  'rgba(135, 92, 177, 1)',
  'rgba(143, 113, 112, 1)',
  'rgba(141, 34, 22, 1)',
  'rgba(54, 72, 214, 1)',
  'rgba(184, 187, 235, 1)',
  'rgba(169, 198, 58, 1)',
  'rgba(190, 206, 188, 1)',
  'rgba(44, 130, 28, 1)',
  'rgba(1, 71, 91, 1)',
  'rgba(26, 242, 94, 1)',
  'rgba(147, 52, 96, 1)',
  'rgba(217, 244, 184, 1)',
  'rgba(162, 181, 30, 1)',
  'rgba(176, 163, 14, 1)',
  'rgba(218, 219, 5, 1)',
  'rgba(86, 124, 108, 1)',
  'rgba(170, 56, 1, 1)',
  'rgba(222, 253, 201, 1)',
  'rgba(179, 8, 140, 1)',
  'rgba(39, 160, 68, 1)',
  'rgba(52, 28, 198, 1)',
  'rgba(255, 112, 197, 1)',
  'rgba(211, 76, 230, 1)',
  'rgba(98, 180, 123, 1)',
  'rgba(112, 178, 234, 1)',
  'rgba(48, 100, 115, 1)',
  'rgba(201, 215, 162, 1)',
  'rgba(38, 130, 245, 1)',
  'rgba(130, 3, 111, 1)',
  'rgba(205, 96, 64, 1)',
  'rgba(124, 242, 88, 1)',
  'rgba(44, 68, 26, 1)',
  'rgba(218, 8, 236, 1)',
  'rgba(99, 182, 155, 1)',
  'rgba(237, 17, 208, 1)',
  'rgba(249, 255, 114, 1)',
  'rgba(163, 7, 211, 1)',
  'rgba(156, 29, 213, 1)',
  'rgba(163, 118, 249, 1)',
  'rgba(13, 115, 222, 1)',
  'rgba(153, 87, 173, 1)',
  'rgba(71, 184, 88, 1)',
  'rgba(131, 172, 238, 1)',
  'rgba(64, 37, 5, 1)',
  'rgba(251, 59, 107, 1)',
  'rgba(33, 156, 197, 1)',
  'rgba(13, 175, 147, 1)',
  'rgba(221, 142, 3, 1)',
  'rgba(82, 147, 23, 1)',
  'rgba(97, 38, 103, 1)',
  'rgba(217, 126, 188, 1)',
  'rgba(126, 231, 55, 1)',
  'rgba(133, 75, 171, 1)',
  'rgba(243, 46, 224, 1)',
  'rgba(148, 218, 88, 1)',
  'rgba(221, 116, 119, 1)',
  'rgba(12, 232, 193, 1)',
  'rgba(37, 69, 219, 1)',
  'rgba(24, 46, 96, 1)',
  'rgba(151, 255, 92, 1)',
  'rgba(62, 124, 218, 1)',
  'rgba(40, 223, 140, 1)',
  'rgba(184, 237, 230, 1)',
  'rgba(10, 82, 248, 1)',
  'rgba(142, 35, 208, 1)',
  'rgba(17, 46, 48, 1)',
  'rgba(37, 33, 25, 1)',
  'rgba(226, 18, 188, 1)',
  'rgba(167, 220, 69, 1)',
  'rgba(40, 31, 123, 1)',
  'rgba(166, 55, 82, 1)',
  'rgba(138, 150, 162, 1)',
  'rgba(235, 48, 161, 1)',
  'rgba(212, 91, 8, 1)',
  'rgba(28, 72, 218, 1)',
  'rgba(2, 56, 30, 1)',
  'rgba(240, 91, 27, 1)',
  'rgba(200, 244, 149, 1)',
  'rgba(230, 112, 58, 1)',
  'rgba(108, 31, 220, 1)',
  'rgba(160, 120, 154, 1)',
  'rgba(195, 124, 87, 1)',
  'rgba(87, 214, 110, 1)',
  'rgba(54, 30, 172, 1)',
  'rgba(186, 108, 0, 1)',
  'rgba(78, 102, 70, 1)',
  'rgba(56, 178, 5, 1)',
  'rgba(49, 195, 161, 1)',
  'rgba(173, 158, 183, 1)',
  'rgba(150, 210, 37, 1)',
  'rgba(68, 113, 52, 1)',
  'rgba(152, 30, 130, 1)',
  'rgba(208, 52, 52, 1)',
  'rgba(159, 171, 153, 1)',
  'rgba(144, 195, 145, 1)',
  'rgba(84, 127, 249, 1)',
  'rgba(66, 254, 212, 1)',
  'rgba(252, 166, 31, 1)',
  'rgba(198, 175, 235, 1)',
  'rgba(157, 152, 229, 1)',
  'rgba(198, 201, 111, 1)',
  'rgba(153, 195, 200, 1)',
  'rgba(124, 112, 25, 1)',
  'rgba(36, 192, 132, 1)',
  'rgba(186, 76, 206, 1)',
  'rgba(222, 53, 37, 1)',
  'rgba(36, 178, 249, 1)',
  'rgba(249, 16, 71, 1)',
  'rgba(24, 26, 149, 1)',
  'rgba(240, 250, 231, 1)',
  'rgba(26, 188, 68, 1)',
  'rgba(85, 226, 31, 1)',
  'rgba(34, 222, 59, 1)',
  'rgba(2, 59, 11, 1)',
  'rgba(213, 76, 179, 1)',
  'rgba(144, 57, 112, 1)',
  'rgba(190, 75, 209, 1)',
  'rgba(91, 232, 149, 1)',
  'rgba(94, 166, 151, 1)',
  'rgba(215, 172, 152, 1)',
  'rgba(78, 1, 62, 1)',
  'rgba(87, 162, 117, 1)',
  'rgba(90, 109, 19, 1)',
  'rgba(134, 92, 131, 1)',
  'rgba(192, 12, 53, 1)',
  'rgba(175, 185, 203, 1)',
  'rgba(109, 213, 183, 1)',
  'rgba(119, 126, 236, 1)',
  'rgba(133, 175, 57, 1)',
  'rgba(64, 42, 10, 1)',
  'rgba(128, 167, 189, 1)',
  'rgba(100, 172, 238, 1)',
  'rgba(236, 174, 96, 1)',
  'rgba(59, 250, 155, 1)',
  'rgba(194, 91, 46, 1)',
  'rgba(172, 68, 54, 1)',
  'rgba(161, 154, 75, 1)',
  'rgba(233, 37, 222, 1)',
  'rgba(45, 64, 74, 1)',
  'rgba(86, 2, 91, 1)',
];

export const backgroundColours = [
         'rgba(62, 100, 137, 0.2)',
         'rgba(216, 233, 100, 0.2)',
         'rgba(90, 182, 154, 0.2)',
         'rgba(67, 56, 173, 0.2)',
         'rgba(19, 113, 39, 0.2)',
         'rgba(181, 19, 174, 0.2)',
         'rgba(237, 179, 156, 0.2)',
         'rgba(20, 255, 251, 0.2)',
         'rgba(41, 49, 113, 0.2)',
         'rgba(155, 99, 79, 0.2)',
         'rgba(52, 137, 168, 0.2)',
         'rgba(42, 115, 205, 0.2)',
         'rgba(148, 99, 115, 0.2)',
         'rgba(27, 43, 1, 0.2)',
         'rgba(27, 22, 115, 0.2)',
         'rgba(203, 40, 252, 0.2)',
         'rgba(161, 151, 92, 0.2)',
         'rgba(89, 13, 239, 0.2)',
         'rgba(174, 148, 63, 0.2)',
         'rgba(103, 168, 32, 0.2)',
         'rgba(81, 70, 8, 0.2)',
         'rgba(233, 254, 74, 0.2)',
         'rgba(34, 54, 54, 0.2)',
         'rgba(6, 84, 100, 0.2)',
         'rgba(52, 249, 140, 0.2)',
         'rgba(240, 80, 34, 0.2)',
         'rgba(70, 243, 41, 0.2)',
         'rgba(12, 185, 207, 0.2)',
         'rgba(204, 172, 106, 0.2)',
         'rgba(246, 10, 49, 0.2)',
         'rgba(89, 86, 39, 0.2)',
         'rgba(149, 61, 79, 0.2)',
         'rgba(112, 93, 93, 0.2)',
         'rgba(179, 41, 19, 0.2)',
         'rgba(193, 147, 172, 0.2)',
         'rgba(192, 179, 42, 0.2)',
         'rgba(116, 203, 201, 0.2)',
         'rgba(189, 43, 232, 0.2)',
         'rgba(221, 228, 101, 0.2)',
         'rgba(226, 71, 60, 0.2)',
         'rgba(111, 19, 193, 0.2)',
         'rgba(20, 106, 125, 0.2)',
         'rgba(5, 230, 23, 0.2)',
         'rgba(113, 218, 93, 0.2)',
         'rgba(79, 210, 70, 0.2)',
         'rgba(14, 162, 226, 0.2)',
         'rgba(56, 126, 236, 0.2)',
         'rgba(165, 201, 30, 0.2)',
         'rgba(119, 60, 243, 0.2)',
         'rgba(91, 141, 117, 0.2)',
         'rgba(202, 228, 119, 0.2)',
         'rgba(60, 43, 185, 0.2)',
         'rgba(101, 40, 230, 0.2)',
         'rgba(149, 179, 175, 0.2)',
         'rgba(234, 255, 186, 0.2)',
         'rgba(142, 160, 237, 0.2)',
         'rgba(232, 160, 78, 0.2)',
         'rgba(173, 23, 91, 0.2)',
         'rgba(30, 62, 142, 0.2)',
         'rgba(116, 95, 37, 0.2)',
         'rgba(33, 96, 45, 0.2)',
         'rgba(2, 97, 21, 0.2)',
         'rgba(51, 133, 208, 0.2)',
         'rgba(2, 88, 177, 0.2)',
         'rgba(5, 13, 80, 0.2)',
         'rgba(176, 128, 176, 0.2)',
         'rgba(228, 106, 10, 0.2)',
         'rgba(176, 123, 225, 0.2)',
         'rgba(139, 35, 86, 0.2)',
         'rgba(255, 208, 192, 0.2)',
         'rgba(79, 146, 89, 0.2)',
         'rgba(66, 61, 138, 0.2)',
         'rgba(83, 122, 86, 0.2)',
         'rgba(16, 100, 127, 0.2)',
         'rgba(218, 214, 48, 0.2)',
         'rgba(104, 80, 103, 0.2)',
         'rgba(137, 179, 196, 0.2)',
         'rgba(90, 29, 144, 0.2)',
         'rgba(238, 236, 55, 0.2)',
         'rgba(167, 42, 66, 0.2)',
         'rgba(93, 118, 161, 0.2)',
         'rgba(146, 103, 61, 0.2)',
         'rgba(178, 46, 152, 0.2)',
         'rgba(81, 242, 250, 0.2)',
         'rgba(1, 54, 110, 0.2)',
         'rgba(233, 250, 57, 0.2)',
         'rgba(100, 162, 215, 0.2)',
         'rgba(129, 232, 177, 0.2)',
         'rgba(103, 110, 104, 0.2)',
         'rgba(77, 201, 229, 0.2)',
         'rgba(190, 161, 241, 0.2)',
         'rgba(11, 149, 1, 0.2)',
         'rgba(91, 253, 11, 0.2)',
         'rgba(32, 19, 93, 0.2)',
         'rgba(91, 74, 197, 0.2)',
         'rgba(245, 254, 171, 0.2)',
         'rgba(154, 230, 188, 0.2)',
         'rgba(72, 91, 35, 0.2)',
         'rgba(93, 199, 160, 0.2)',
         'rgba(183, 85, 255, 0.2)',
         'rgba(160, 122, 0, 0.2)',
         'rgba(223, 7, 107, 0.2)',
         'rgba(0, 176, 35, 0.2)',
         'rgba(117, 240, 69, 0.2)',
         'rgba(189, 191, 135, 0.2)',
         'rgba(119, 38, 192, 0.2)',
         'rgba(104, 154, 147, 0.2)',
         'rgba(46, 188, 220, 0.2)',
         'rgba(57, 98, 248, 0.2)',
         'rgba(104, 223, 157, 0.2)',
         'rgba(250, 221, 53, 0.2)',
         'rgba(77, 72, 56, 0.2)',
         'rgba(63, 250, 57, 0.2)',
         'rgba(41, 111, 220, 0.2)',
         'rgba(19, 57, 50, 0.2)',
         'rgba(147, 247, 61, 0.2)',
         'rgba(139, 69, 227, 0.2)',
         'rgba(6, 42, 99, 0.2)',
         'rgba(9, 39, 194, 0.2)',
         'rgba(37, 131, 89, 0.2)',
         'rgba(122, 30, 136, 0.2)',
         'rgba(229, 244, 185, 0.2)',
         'rgba(162, 254, 23, 0.2)',
         'rgba(34, 114, 100, 0.2)',
         'rgba(24, 233, 126, 0.2)',
         'rgba(119, 197, 159, 0.2)',
         'rgba(63, 196, 117, 0.2)',
         'rgba(76, 154, 58, 0.2)',
         'rgba(89, 161, 161, 0.2)',
         'rgba(72, 47, 55, 0.2)',
         'rgba(232, 121, 205, 0.2)',
         'rgba(203, 156, 38, 0.2)',
         'rgba(90, 58, 225, 0.2)',
         'rgba(164, 108, 103, 0.2)',
         'rgba(187, 82, 132, 0.2)',
         'rgba(140, 76, 170, 0.2)',
         'rgba(228, 129, 52, 0.2)',
         'rgba(159, 148, 231, 0.2)',
         'rgba(20, 109, 218, 0.2)',
         'rgba(245, 127, 195, 0.2)',
         'rgba(116, 49, 169, 0.2)',
         'rgba(46, 170, 166, 0.2)',
         'rgba(34, 178, 106, 0.2)',
         'rgba(237, 123, 18, 0.2)',
         'rgba(34, 77, 28, 0.2)',
         'rgba(244, 38, 63, 0.2)',
         'rgba(108, 46, 233, 0.2)',
         'rgba(152, 7, 217, 0.2)',
         'rgba(57, 122, 57, 0.2)',
         'rgba(192, 245, 205, 0.2)',
         'rgba(204, 2, 193, 0.2)',
         'rgba(4, 127, 140, 0.2)',
         'rgba(110, 138, 77, 0.2)',
         'rgba(145, 150, 229, 0.2)',
         'rgba(39, 68, 226, 0.2)',
         'rgba(246, 224, 254, 0.2)',
         'rgba(64, 247, 213, 0.2)',
         'rgba(114, 153, 42, 0.2)',
         'rgba(251, 219, 202, 0.2)',
         'rgba(41, 3, 180, 0.2)',
         'rgba(63, 209, 13, 0.2)',
         'rgba(21, 179, 226, 0.2)',
         'rgba(93, 68, 199, 0.2)',
         'rgba(219, 36, 93, 0.2)',
         'rgba(1, 2, 188, 0.2)',
         'rgba(255, 197, 149, 0.2)',
         'rgba(34, 139, 94, 0.2)',
         'rgba(66, 12, 60, 0.2)',
         'rgba(221, 195, 14, 0.2)',
         'rgba(149, 53, 68, 0.2)',
         'rgba(160, 136, 201, 0.2)',
         'rgba(111, 18, 58, 0.2)',
         'rgba(43, 57, 240, 0.2)',
         'rgba(5, 219, 66, 0.2)',
         'rgba(104, 50, 188, 0.2)',
         'rgba(181, 98, 70, 0.2)',
         'rgba(157, 14, 204, 0.2)',
         'rgba(23, 126, 98, 0.2)',
         'rgba(113, 171, 236, 0.2)',
         'rgba(219, 106, 248, 0.2)',
         'rgba(120, 135, 57, 0.2)',
         'rgba(152, 187, 142, 0.2)',
         'rgba(68, 188, 205, 0.2)',
         'rgba(133, 147, 5, 0.2)',
         'rgba(166, 198, 13, 0.2)',
         'rgba(220, 219, 178, 0.2)',
         'rgba(145, 118, 87, 0.2)',
         'rgba(225, 163, 54, 0.2)',
         'rgba(242, 190, 93, 0.2)',
         'rgba(222, 151, 170, 0.2)',
         'rgba(52, 198, 206, 0.2)',
         'rgba(144, 215, 151, 0.2)',
         'rgba(203, 251, 170, 0.2)',
         'rgba(175, 20, 37, 0.2)',
         'rgba(38, 113, 197, 0.2)',
         'rgba(127, 130, 116, 0.2)',
         'rgba(137, 18, 73, 0.2)',
         'rgba(59, 248, 191, 0.2)',
         'rgba(87, 84, 17, 0.2)',
         'rgba(43, 157, 110, 0.2)',
         'rgba(131, 232, 148, 0.2)',
         'rgba(46, 106, 157, 0.2)',
         'rgba(142, 130, 131, 0.2)',
         'rgba(67, 188, 249, 0.2)',
         'rgba(71, 77, 111, 0.2)',
         'rgba(102, 233, 110, 0.2)',
         'rgba(234, 3, 15, 0.2)',
         'rgba(140, 203, 168, 0.2)',
         'rgba(171, 197, 118, 0.2)',
         'rgba(13, 231, 63, 0.2)',
         'rgba(104, 155, 150, 0.2)',
         'rgba(68, 145, 29, 0.2)',
         'rgba(84, 111, 16, 0.2)',
         'rgba(100, 170, 49, 0.2)',
         'rgba(101, 128, 159, 0.2)',
         'rgba(106, 126, 87, 0.2)',
         'rgba(57, 102, 64, 0.2)',
         'rgba(215, 147, 69, 0.2)',
         'rgba(245, 223, 31, 0.2)',
         'rgba(69, 156, 214, 0.2)',
         'rgba(101, 249, 202, 0.2)',
         'rgba(144, 169, 0, 0.2)',
         'rgba(156, 200, 105, 0.2)',
         'rgba(121, 32, 6, 0.2)',
         'rgba(12, 182, 231, 0.2)',
         'rgba(119, 243, 29, 0.2)',
         'rgba(43, 173, 75, 0.2)',
         'rgba(196, 113, 53, 0.2)',
         'rgba(182, 5, 160, 0.2)',
         'rgba(22, 5, 214, 0.2)',
         'rgba(151, 72, 117, 0.2)',
         'rgba(168, 30, 66, 0.2)',
         'rgba(186, 167, 1, 0.2)',
         'rgba(143, 176, 226, 0.2)',
         'rgba(106, 38, 90, 0.2)',
         'rgba(128, 147, 98, 0.2)',
         'rgba(5, 79, 26, 0.2)',
         'rgba(82, 150, 54, 0.2)',
         'rgba(122, 153, 247, 0.2)',
         'rgba(196, 81, 51, 0.2)',
         'rgba(77, 198, 105, 0.2)',
         'rgba(14, 86, 118, 0.2)',
         'rgba(193, 28, 110, 0.2)',
         'rgba(226, 128, 88, 0.2)',
         'rgba(238, 214, 239, 0.2)',
         'rgba(225, 204, 78, 0.2)',
         'rgba(234, 67, 195, 0.2)',
         'rgba(167, 7, 62, 0.2)',
         'rgba(114, 151, 202, 0.2)',
         'rgba(181, 22, 164, 0.2)',
         'rgba(155, 123, 140, 0.2)',
         'rgba(65, 8, 240, 0.2)',
         'rgba(255, 18, 38, 0.2)',
         'rgba(236, 94, 153, 0.2)',
         'rgba(211, 99, 140, 0.2)',
         'rgba(187, 181, 71, 0.2)',
         'rgba(142, 203, 191, 0.2)',
         'rgba(105, 169, 200, 0.2)',
         'rgba(93, 41, 72, 0.2)',
         'rgba(115, 62, 163, 0.2)',
         'rgba(157, 3, 111, 0.2)',
         'rgba(28, 104, 108, 0.2)',
         'rgba(229, 80, 142, 0.2)',
         'rgba(62, 111, 168, 0.2)',
         'rgba(215, 187, 160, 0.2)',
         'rgba(180, 216, 26, 0.2)',
         'rgba(78, 197, 179, 0.2)',
         'rgba(29, 4, 247, 0.2)',
         'rgba(28, 122, 238, 0.2)',
         'rgba(125, 239, 132, 0.2)',
         'rgba(8, 93, 84, 0.2)',
         'rgba(21, 221, 201, 0.2)',
         'rgba(153, 242, 94, 0.2)',
         'rgba(225, 67, 95, 0.2)',
         'rgba(181, 86, 209, 0.2)',
         'rgba(9, 35, 6, 0.2)',
         'rgba(49, 186, 227, 0.2)',
         'rgba(130, 110, 169, 0.2)',
         'rgba(238, 30, 194, 0.2)',
         'rgba(63, 34, 112, 0.2)',
         'rgba(10, 42, 193, 0.2)',
         'rgba(84, 232, 180, 0.2)',
         'rgba(182, 160, 236, 0.2)',
         'rgba(192, 250, 84, 0.2)',
         'rgba(243, 115, 32, 0.2)',
         'rgba(181, 137, 13, 0.2)',
         'rgba(19, 143, 39, 0.2)',
         'rgba(174, 123, 25, 0.2)',
         'rgba(158, 248, 27, 0.2)',
         'rgba(132, 190, 113, 0.2)',
         'rgba(3, 157, 30, 0.2)',
         'rgba(146, 224, 154, 0.2)',
         'rgba(153, 197, 25, 0.2)',
         'rgba(158, 35, 85, 0.2)',
         'rgba(120, 149, 62, 0.2)',
         'rgba(218, 178, 174, 0.2)',
         'rgba(223, 53, 12, 0.2)',
         'rgba(225, 241, 78, 0.2)',
         'rgba(20, 185, 240, 0.2)',
         'rgba(116, 137, 164, 0.2)',
         'rgba(68, 234, 211, 0.2)',
         'rgba(96, 68, 42, 0.2)',
         'rgba(191, 233, 156, 0.2)',
         'rgba(162, 123, 234, 0.2)',
         'rgba(143, 11, 124, 0.2)',
         'rgba(111, 210, 115, 0.2)',
         'rgba(106, 90, 227, 0.2)',
         'rgba(90, 145, 21, 0.2)',
         'rgba(21, 42, 217, 0.2)',
         'rgba(101, 17, 114, 0.2)',
         'rgba(161, 224, 42, 0.2)',
         'rgba(202, 65, 33, 0.2)',
         'rgba(63, 248, 219, 0.2)',
         'rgba(191, 4, 166, 0.2)',
         'rgba(172, 24, 101, 0.2)',
         'rgba(53, 23, 236, 0.2)',
         'rgba(55, 216, 169, 0.2)',
         'rgba(163, 91, 160, 0.2)',
         'rgba(157, 81, 215, 0.2)',
         'rgba(89, 7, 135, 0.2)',
         'rgba(11, 163, 242, 0.2)',
         'rgba(205, 87, 167, 0.2)',
         'rgba(230, 255, 179, 0.2)',
         'rgba(222, 78, 104, 0.2)',
         'rgba(157, 212, 220, 0.2)',
         'rgba(127, 67, 213, 0.2)',
         'rgba(41, 43, 111, 0.2)',
         'rgba(105, 133, 43, 0.2)',
         'rgba(4, 85, 60, 0.2)',
         'rgba(17, 104, 23, 0.2)',
         'rgba(215, 1, 101, 0.2)',
         'rgba(94, 34, 142, 0.2)',
         'rgba(208, 101, 242, 0.2)',
         'rgba(45, 51, 201, 0.2)',
         'rgba(251, 223, 160, 0.2)',
         'rgba(175, 34, 245, 0.2)',
         'rgba(53, 164, 2, 0.2)',
         'rgba(37, 121, 22, 0.2)',
         'rgba(232, 26, 89, 0.2)',
         'rgba(212, 233, 150, 0.2)',
         'rgba(28, 33, 102, 0.2)',
         'rgba(169, 13, 196, 0.2)',
         'rgba(67, 82, 80, 0.2)',
         'rgba(137, 214, 57, 0.2)',
         'rgba(112, 54, 24, 0.2)',
         'rgba(101, 247, 10, 0.2)',
         'rgba(237, 80, 29, 0.2)',
         'rgba(130, 38, 205, 0.2)',
         'rgba(115, 63, 132, 0.2)',
         'rgba(68, 19, 69, 0.2)',
         'rgba(195, 64, 165, 0.2)',
         'rgba(4, 150, 126, 0.2)',
         'rgba(178, 216, 160, 0.2)',
         'rgba(85, 118, 12, 0.2)',
         'rgba(253, 2, 49, 0.2)',
         'rgba(67, 112, 206, 0.2)',
         'rgba(90, 163, 171, 0.2)',
         'rgba(157, 25, 36, 0.2)',
         'rgba(100, 25, 237, 0.2)',
         'rgba(116, 86, 248, 0.2)',
         'rgba(177, 89, 248, 0.2)',
         'rgba(11, 197, 215, 0.2)',
         'rgba(126, 14, 131, 0.2)',
         'rgba(244, 131, 109, 0.2)',
         'rgba(51, 225, 244, 0.2)',
         'rgba(93, 147, 106, 0.2)',
         'rgba(109, 185, 115, 0.2)',
         'rgba(114, 33, 14, 0.2)',
         'rgba(186, 194, 26, 0.2)',
         'rgba(22, 76, 19, 0.2)',
         'rgba(7, 90, 187, 0.2)',
         'rgba(209, 36, 245, 0.2)',
         'rgba(115, 192, 53, 0.2)',
         'rgba(100, 89, 42, 0.2)',
         'rgba(198, 49, 47, 0.2)',
         'rgba(212, 94, 123, 0.2)',
         'rgba(106, 183, 223, 0.2)',
         'rgba(181, 2, 13, 0.2)',
         'rgba(249, 233, 115, 0.2)',
         'rgba(203, 90, 37, 0.2)',
         'rgba(231, 137, 223, 0.2)',
         'rgba(125, 64, 72, 0.2)',
         'rgba(197, 130, 49, 0.2)',
         'rgba(227, 108, 134, 0.2)',
         'rgba(19, 191, 75, 0.2)',
         'rgba(198, 176, 206, 0.2)',
         'rgba(228, 242, 177, 0.2)',
         'rgba(20, 82, 24, 0.2)',
         'rgba(80, 152, 82, 0.2)',
         'rgba(232, 125, 50, 0.2)',
         'rgba(178, 133, 35, 0.2)',
         'rgba(70, 165, 111, 0.2)',
         'rgba(200, 67, 108, 0.2)',
         'rgba(251, 133, 36, 0.2)',
         'rgba(186, 33, 222, 0.2)',
         'rgba(114, 66, 72, 0.2)',
         'rgba(33, 84, 17, 0.2)',
         'rgba(8, 145, 175, 0.2)',
         'rgba(30, 226, 241, 0.2)',
         'rgba(220, 48, 255, 0.2)',
         'rgba(22, 0, 25, 0.2)',
         'rgba(83, 67, 138, 0.2)',
         'rgba(61, 201, 217, 0.2)',
         'rgba(145, 119, 66, 0.2)',
         'rgba(154, 133, 169, 0.2)',
         'rgba(66, 151, 89, 0.2)',
         'rgba(167, 234, 201, 0.2)',
         'rgba(71, 213, 42, 0.2)',
         'rgba(46, 233, 110, 0.2)',
         'rgba(167, 79, 80, 0.2)',
         'rgba(75, 87, 183, 0.2)',
         'rgba(31, 170, 200, 0.2)',
         'rgba(140, 242, 192, 0.2)',
         'rgba(223, 208, 37, 0.2)',
         'rgba(254, 1, 232, 0.2)',
         'rgba(177, 34, 136, 0.2)',
         'rgba(186, 250, 59, 0.2)',
         'rgba(22, 226, 180, 0.2)',
         'rgba(225, 176, 41, 0.2)',
         'rgba(0, 234, 39, 0.2)',
         'rgba(196, 20, 253, 0.2)',
         'rgba(10, 34, 210, 0.2)',
         'rgba(127, 249, 28, 0.2)',
         'rgba(119, 150, 3, 0.2)',
         'rgba(162, 4, 169, 0.2)',
         'rgba(43, 204, 205, 0.2)',
         'rgba(53, 25, 15, 0.2)',
         'rgba(252, 238, 104, 0.2)',
         'rgba(232, 111, 147, 0.2)',
         'rgba(232, 36, 66, 0.2)',
         'rgba(47, 234, 3, 0.2)',
         'rgba(147, 73, 243, 0.2)',
         'rgba(27, 144, 232, 0.2)',
         'rgba(248, 39, 216, 0.2)',
         'rgba(49, 246, 37, 0.2)',
         'rgba(168, 66, 161, 0.2)',
         'rgba(233, 12, 19, 0.2)',
         'rgba(0, 6, 119, 0.2)',
         'rgba(185, 196, 47, 0.2)',
         'rgba(204, 167, 59, 0.2)',
         'rgba(230, 176, 54, 0.2)',
         'rgba(133, 163, 106, 0.2)',
         'rgba(184, 192, 99, 0.2)',
         'rgba(76, 22, 139, 0.2)',
         'rgba(252, 219, 97, 0.2)',
         'rgba(167, 34, 108, 0.2)',
         'rgba(159, 190, 164, 0.2)',
         'rgba(199, 37, 53, 0.2)',
         'rgba(127, 132, 137, 0.2)',
         'rgba(214, 47, 187, 0.2)',
         'rgba(120, 188, 144, 0.2)',
         'rgba(59, 221, 120, 0.2)',
         'rgba(87, 143, 145, 0.2)',
         'rgba(132, 204, 1, 0.2)',
         'rgba(251, 37, 97, 0.2)',
         'rgba(243, 44, 98, 0.2)',
         'rgba(108, 128, 204, 0.2)',
         'rgba(57, 223, 128, 0.2)',
         'rgba(61, 44, 204, 0.2)',
         'rgba(252, 242, 143, 0.2)',
         'rgba(108, 182, 89, 0.2)',
         'rgba(232, 230, 30, 0.2)',
         'rgba(65, 157, 189, 0.2)',
         'rgba(182, 89, 20, 0.2)',
         'rgba(248, 195, 211, 0.2)',
         'rgba(226, 196, 74, 0.2)',
         'rgba(246, 148, 13, 0.2)',
         'rgba(165, 21, 113, 0.2)',
         'rgba(98, 29, 112, 0.2)',
         'rgba(112, 103, 51, 0.2)',
         'rgba(158, 126, 212, 0.2)',
         'rgba(148, 20, 237, 0.2)',
         'rgba(96, 128, 135, 0.2)',
         'rgba(34, 253, 142, 0.2)',
         'rgba(10, 94, 122, 0.2)',
         'rgba(109, 162, 72, 0.2)',
         'rgba(227, 205, 138, 0.2)',
         'rgba(199, 16, 5, 0.2)',
         'rgba(176, 208, 143, 0.2)',
         'rgba(203, 120, 120, 0.2)',
         'rgba(180, 159, 130, 0.2)',
         'rgba(21, 53, 1, 0.2)',
         'rgba(64, 181, 244, 0.2)',
         'rgba(79, 90, 30, 0.2)',
         'rgba(41, 155, 44, 0.2)',
         'rgba(155, 19, 9, 0.2)',
         'rgba(160, 173, 235, 0.2)',
         'rgba(220, 44, 73, 0.2)',
         'rgba(64, 254, 64, 0.2)',
         'rgba(67, 216, 194, 0.2)',
         'rgba(30, 78, 90, 0.2)',
         'rgba(214, 206, 0, 0.2)',
         'rgba(170, 194, 172, 0.2)',
         'rgba(165, 150, 173, 0.2)',
         'rgba(243, 81, 236, 0.2)',
         'rgba(34, 200, 189, 0.2)',
         'rgba(81, 130, 88, 0.2)',
         'rgba(37, 184, 218, 0.2)',
         'rgba(140, 176, 197, 0.2)',
         'rgba(140, 174, 53, 0.2)',
         'rgba(109, 193, 56, 0.2)',
         'rgba(7, 186, 50, 0.2)',
         'rgba(90, 125, 35, 0.2)',
         'rgba(48, 132, 47, 0.2)',
         'rgba(86, 129, 29, 0.2)',
         'rgba(204, 69, 89, 0.2)',
         'rgba(62, 20, 248, 0.2)',
         'rgba(68, 17, 137, 0.2)',
         'rgba(13, 177, 99, 0.2)',
         'rgba(48, 213, 58, 0.2)',
         'rgba(241, 49, 156, 0.2)',
         'rgba(203, 39, 108, 0.2)',
         'rgba(55, 121, 160, 0.2)',
         'rgba(37, 211, 75, 0.2)',
         'rgba(91, 70, 139, 0.2)',
         'rgba(3, 36, 222, 0.2)',
         'rgba(106, 140, 106, 0.2)',
         'rgba(200, 134, 220, 0.2)',
         'rgba(14, 182, 211, 0.2)',
         'rgba(51, 218, 16, 0.2)',
         'rgba(109, 75, 212, 0.2)',
         'rgba(211, 108, 22, 0.2)',
         'rgba(100, 59, 91, 0.2)',
         'rgba(131, 71, 86, 0.2)',
         'rgba(110, 95, 33, 0.2)',
         'rgba(64, 182, 161, 0.2)',
         'rgba(13, 203, 37, 0.2)',
         'rgba(5, 255, 177, 0.2)',
         'rgba(10, 24, 12, 0.2)',
         'rgba(23, 24, 137, 0.2)',
         'rgba(239, 137, 225, 0.2)',
         'rgba(4, 53, 126, 0.2)',
         'rgba(211, 98, 159, 0.2)',
         'rgba(171, 133, 233, 0.2)',
         'rgba(137, 11, 100, 0.2)',
         'rgba(142, 211, 72, 0.2)',
         'rgba(226, 74, 75, 0.2)',
         'rgba(200, 17, 204, 0.2)',
         'rgba(123, 72, 94, 0.2)',
         'rgba(135, 198, 72, 0.2)',
         'rgba(216, 190, 33, 0.2)',
         'rgba(133, 196, 116, 0.2)',
         'rgba(1, 144, 191, 0.2)',
         'rgba(224, 40, 123, 0.2)',
         'rgba(161, 159, 212, 0.2)',
         'rgba(247, 118, 96, 0.2)',
         'rgba(32, 221, 67, 0.2)',
         'rgba(155, 221, 159, 0.2)',
         'rgba(239, 71, 239, 0.2)',
         'rgba(137, 94, 122, 0.2)',
         'rgba(99, 168, 80, 0.2)',
         'rgba(122, 230, 73, 0.2)',
         'rgba(178, 21, 138, 0.2)',
         'rgba(132, 243, 17, 0.2)',
         'rgba(225, 161, 88, 0.2)',
         'rgba(238, 180, 126, 0.2)',
         'rgba(121, 202, 114, 0.2)',
         'rgba(245, 90, 200, 0.2)',
         'rgba(29, 42, 165, 0.2)',
         'rgba(145, 229, 122, 0.2)',
         'rgba(195, 214, 216, 0.2)',
         'rgba(32, 230, 223, 0.2)',
         'rgba(80, 200, 30, 0.2)',
         'rgba(172, 169, 190, 0.2)',
         'rgba(223, 32, 107, 0.2)',
         'rgba(169, 125, 108, 0.2)',
         'rgba(195, 165, 91, 0.2)',
         'rgba(215, 130, 75, 0.2)',
         'rgba(171, 34, 237, 0.2)',
         'rgba(229, 88, 21, 0.2)',
         'rgba(82, 146, 193, 0.2)',
         'rgba(255, 166, 219, 0.2)',
         'rgba(93, 205, 10, 0.2)',
         'rgba(120, 147, 86, 0.2)',
         'rgba(229, 68, 114, 0.2)',
         'rgba(172, 59, 201, 0.2)',
         'rgba(164, 94, 205, 0.2)',
         'rgba(65, 2, 111, 0.2)',
         'rgba(211, 47, 115, 0.2)',
         'rgba(203, 176, 226, 0.2)',
         'rgba(189, 171, 82, 0.2)',
         'rgba(222, 219, 102, 0.2)',
         'rgba(23, 226, 252, 0.2)',
         'rgba(107, 136, 54, 0.2)',
         'rgba(240, 78, 172, 0.2)',
         'rgba(140, 82, 231, 0.2)',
         'rgba(105, 44, 188, 0.2)',
         'rgba(15, 144, 136, 0.2)',
         'rgba(131, 248, 67, 0.2)',
         'rgba(117, 245, 208, 0.2)',
         'rgba(225, 251, 17, 0.2)',
         'rgba(234, 150, 116, 0.2)',
         'rgba(46, 223, 6, 0.2)',
         'rgba(10, 205, 29, 0.2)',
         'rgba(68, 168, 76, 0.2)',
         'rgba(26, 159, 29, 0.2)',
         'rgba(91, 241, 86, 0.2)',
         'rgba(250, 188, 117, 0.2)',
         'rgba(27, 100, 74, 0.2)',
         'rgba(63, 233, 201, 0.2)',
         'rgba(253, 69, 240, 0.2)',
         'rgba(128, 66, 238, 0.2)',
         'rgba(251, 60, 66, 0.2)',
         'rgba(175, 71, 193, 0.2)',
         'rgba(63, 130, 244, 0.2)',
         'rgba(108, 52, 149, 0.2)',
         'rgba(1, 163, 237, 0.2)',
         'rgba(73, 140, 98, 0.2)',
         'rgba(3, 15, 242, 0.2)',
         'rgba(105, 229, 126, 0.2)',
         'rgba(112, 213, 139, 0.2)',
         'rgba(226, 67, 22, 0.2)',
         'rgba(220, 188, 226, 0.2)',
         'rgba(24, 223, 190, 0.2)',
         'rgba(244, 73, 41, 0.2)',
         'rgba(206, 227, 190, 0.2)',
         'rgba(129, 131, 112, 0.2)',
         'rgba(138, 246, 197, 0.2)',
         'rgba(131, 111, 10, 0.2)',
         'rgba(52, 75, 121, 0.2)',
         'rgba(51, 110, 130, 0.2)',
         'rgba(32, 214, 178, 0.2)',
         'rgba(78, 9, 107, 0.2)',
         'rgba(6, 247, 219, 0.2)',
         'rgba(145, 1, 161, 0.2)',
         'rgba(207, 195, 104, 0.2)',
         'rgba(94, 8, 149, 0.2)',
         'rgba(153, 24, 224, 0.2)',
         'rgba(223, 48, 206, 0.2)',
         'rgba(62, 158, 249, 0.2)',
         'rgba(255, 222, 54, 0.2)',
         'rgba(183, 86, 1, 0.2)',
         'rgba(253, 218, 52, 0.2)',
         'rgba(243, 49, 209, 0.2)',
         'rgba(221, 232, 122, 0.2)',
         'rgba(36, 99, 5, 0.2)',
         'rgba(203, 133, 75, 0.2)',
         'rgba(213, 210, 193, 0.2)',
         'rgba(192, 78, 228, 0.2)',
         'rgba(116, 92, 210, 0.2)',
         'rgba(81, 85, 96, 0.2)',
         'rgba(218, 176, 101, 0.2)',
         'rgba(109, 13, 161, 0.2)',
         'rgba(131, 119, 192, 0.2)',
         'rgba(214, 6, 166, 0.2)',
         'rgba(110, 34, 86, 0.2)',
         'rgba(135, 161, 141, 0.2)',
         'rgba(173, 187, 6, 0.2)',
         'rgba(76, 72, 176, 0.2)',
         'rgba(191, 234, 189, 0.2)',
         'rgba(163, 109, 141, 0.2)',
         'rgba(185, 107, 31, 0.2)',
         'rgba(199, 53, 172, 0.2)',
         'rgba(55, 217, 48, 0.2)',
         'rgba(177, 100, 73, 0.2)',
         'rgba(166, 184, 139, 0.2)',
         'rgba(164, 20, 246, 0.2)',
         'rgba(0, 248, 242, 0.2)',
         'rgba(253, 168, 136, 0.2)',
         'rgba(189, 195, 137, 0.2)',
         'rgba(118, 231, 251, 0.2)',
         'rgba(225, 237, 15, 0.2)',
         'rgba(114, 114, 10, 0.2)',
         'rgba(2, 120, 210, 0.2)',
         'rgba(249, 110, 186, 0.2)',
         'rgba(42, 100, 203, 0.2)',
         'rgba(83, 191, 150, 0.2)',
         'rgba(19, 155, 137, 0.2)',
         'rgba(245, 35, 99, 0.2)',
         'rgba(229, 45, 153, 0.2)',
         'rgba(19, 63, 26, 0.2)',
         'rgba(212, 101, 213, 0.2)',
         'rgba(9, 73, 175, 0.2)',
         'rgba(155, 189, 58, 0.2)',
         'rgba(170, 138, 113, 0.2)',
         'rgba(241, 154, 24, 0.2)',
         'rgba(216, 158, 203, 0.2)',
         'rgba(177, 133, 251, 0.2)',
         'rgba(178, 53, 154, 0.2)',
         'rgba(147, 144, 188, 0.2)',
         'rgba(100, 249, 219, 0.2)',
         'rgba(236, 20, 132, 0.2)',
         'rgba(8, 10, 67, 0.2)',
         'rgba(16, 34, 180, 0.2)',
         'rgba(204, 111, 187, 0.2)',
         'rgba(179, 184, 206, 0.2)',
         'rgba(16, 14, 227, 0.2)',
         'rgba(166, 183, 45, 0.2)',
         'rgba(173, 100, 63, 0.2)',
         'rgba(70, 87, 16, 0.2)',
         'rgba(80, 89, 29, 0.2)',
         'rgba(247, 168, 29, 0.2)',
         'rgba(174, 167, 10, 0.2)',
         'rgba(132, 207, 157, 0.2)',
         'rgba(1, 54, 98, 0.2)',
         'rgba(174, 115, 38, 0.2)',
         'rgba(160, 110, 1, 0.2)',
         'rgba(124, 131, 238, 0.2)',
         'rgba(110, 242, 35, 0.2)',
         'rgba(59, 1, 136, 0.2)',
         'rgba(57, 184, 160, 0.2)',
         'rgba(183, 142, 220, 0.2)',
         'rgba(120, 107, 154, 0.2)',
         'rgba(171, 125, 124, 0.2)',
         'rgba(163, 212, 234, 0.2)',
         'rgba(250, 0, 49, 0.2)',
         'rgba(24, 24, 73, 0.2)',
         'rgba(104, 31, 79, 0.2)',
         'rgba(4, 146, 155, 0.2)',
         'rgba(183, 132, 27, 0.2)',
         'rgba(225, 232, 158, 0.2)',
         'rgba(45, 175, 205, 0.2)',
         'rgba(25, 238, 142, 0.2)',
         'rgba(3, 77, 8, 0.2)',
         'rgba(205, 198, 12, 0.2)',
         'rgba(17, 223, 159, 0.2)',
         'rgba(186, 184, 25, 0.2)',
         'rgba(215, 68, 57, 0.2)',
         'rgba(143, 29, 146, 0.2)',
         'rgba(55, 247, 237, 0.2)',
         'rgba(44, 141, 36, 0.2)',
         'rgba(205, 125, 177, 0.2)',
         'rgba(162, 237, 137, 0.2)',
         'rgba(238, 234, 201, 0.2)',
         'rgba(74, 205, 12, 0.2)',
         'rgba(162, 116, 165, 0.2)',
         'rgba(174, 156, 225, 0.2)',
         'rgba(67, 209, 116, 0.2)',
         'rgba(223, 144, 250, 0.2)',
         'rgba(234, 214, 0, 0.2)',
         'rgba(16, 186, 194, 0.2)',
         'rgba(166, 238, 13, 0.2)',
         'rgba(111, 254, 250, 0.2)',
         'rgba(54, 56, 209, 0.2)',
         'rgba(211, 75, 130, 0.2)',
         'rgba(21, 240, 66, 0.2)',
         'rgba(60, 49, 202, 0.2)',
         'rgba(139, 77, 246, 0.2)',
         'rgba(39, 69, 204, 0.2)',
         'rgba(145, 174, 13, 0.2)',
         'rgba(79, 238, 215, 0.2)',
         'rgba(19, 113, 8, 0.2)',
         'rgba(55, 233, 147, 0.2)',
         'rgba(111, 144, 248, 0.2)',
         'rgba(140, 159, 217, 0.2)',
         'rgba(192, 9, 7, 0.2)',
         'rgba(197, 203, 104, 0.2)',
         'rgba(7, 33, 6, 0.2)',
         'rgba(189, 170, 156, 0.2)',
         'rgba(239, 219, 157, 0.2)',
         'rgba(59, 164, 180, 0.2)',
         'rgba(131, 100, 238, 0.2)',
         'rgba(118, 207, 150, 0.2)',
         'rgba(15, 137, 223, 0.2)',
         'rgba(26, 88, 19, 0.2)',
         'rgba(235, 31, 81, 0.2)',
         'rgba(252, 150, 144, 0.2)',
         'rgba(197, 8, 130, 0.2)',
         'rgba(13, 90, 45, 0.2)',
         'rgba(83, 105, 212, 0.2)',
         'rgba(133, 205, 32, 0.2)',
         'rgba(30, 108, 196, 0.2)',
         'rgba(171, 207, 243, 0.2)',
         'rgba(181, 61, 23, 0.2)',
         'rgba(133, 122, 83, 0.2)',
         'rgba(124, 88, 212, 0.2)',
         'rgba(52, 1, 56, 0.2)',
         'rgba(214, 97, 70, 0.2)',
         'rgba(199, 2, 214, 0.2)',
         'rgba(201, 28, 17, 0.2)',
         'rgba(179, 242, 196, 0.2)',
         'rgba(205, 16, 8, 0.2)',
         'rgba(39, 3, 252, 0.2)',
         'rgba(227, 12, 255, 0.2)',
         'rgba(248, 43, 217, 0.2)',
         'rgba(137, 226, 142, 0.2)',
         'rgba(197, 33, 204, 0.2)',
         'rgba(103, 146, 144, 0.2)',
         'rgba(86, 217, 105, 0.2)',
         'rgba(86, 71, 94, 0.2)',
         'rgba(33, 5, 158, 0.2)',
         'rgba(190, 168, 91, 0.2)',
         'rgba(200, 66, 233, 0.2)',
         'rgba(21, 187, 78, 0.2)',
         'rgba(20, 19, 6, 0.2)',
         'rgba(165, 48, 24, 0.2)',
         'rgba(13, 201, 220, 0.2)',
         'rgba(89, 74, 234, 0.2)',
         'rgba(175, 156, 115, 0.2)',
         'rgba(35, 106, 200, 0.2)',
         'rgba(185, 96, 94, 0.2)',
         'rgba(16, 41, 94, 0.2)',
         'rgba(80, 104, 116, 0.2)',
         'rgba(41, 234, 153, 0.2)',
         'rgba(251, 107, 93, 0.2)',
         'rgba(179, 137, 33, 0.2)',
         'rgba(167, 106, 181, 0.2)',
         'rgba(26, 157, 163, 0.2)',
         'rgba(36, 82, 100, 0.2)',
         'rgba(214, 72, 225, 0.2)',
         'rgba(46, 203, 115, 0.2)',
         'rgba(87, 80, 50, 0.2)',
         'rgba(211, 172, 140, 0.2)',
         'rgba(85, 185, 22, 0.2)',
         'rgba(248, 89, 242, 0.2)',
         'rgba(126, 176, 193, 0.2)',
         'rgba(179, 132, 196, 0.2)',
         'rgba(165, 0, 227, 0.2)',
         'rgba(8, 194, 224, 0.2)',
         'rgba(114, 91, 176, 0.2)',
         'rgba(224, 116, 184, 0.2)',
         'rgba(74, 15, 207, 0.2)',
         'rgba(253, 65, 8, 0.2)',
         'rgba(118, 251, 185, 0.2)',
         'rgba(253, 78, 164, 0.2)',
         'rgba(140, 69, 82, 0.2)',
         'rgba(192, 87, 12, 0.2)',
         'rgba(134, 200, 187, 0.2)',
         'rgba(26, 153, 223, 0.2)',
         'rgba(7, 64, 186, 0.2)',
         'rgba(148, 217, 246, 0.2)',
         'rgba(97, 155, 60, 0.2)',
         'rgba(208, 80, 196, 0.2)',
         'rgba(209, 25, 147, 0.2)',
         'rgba(230, 149, 236, 0.2)',
         'rgba(95, 152, 49, 0.2)',
         'rgba(42, 49, 76, 0.2)',
         'rgba(224, 215, 203, 0.2)',
         'rgba(121, 128, 74, 0.2)',
         'rgba(100, 25, 88, 0.2)',
         'rgba(220, 191, 39, 0.2)',
         'rgba(30, 26, 95, 0.2)',
         'rgba(82, 229, 56, 0.2)',
         'rgba(146, 48, 48, 0.2)',
         'rgba(12, 222, 97, 0.2)',
         'rgba(227, 88, 69, 0.2)',
         'rgba(84, 175, 21, 0.2)',
         'rgba(114, 118, 119, 0.2)',
         'rgba(113, 176, 57, 0.2)',
         'rgba(235, 81, 15, 0.2)',
         'rgba(255, 89, 226, 0.2)',
         'rgba(123, 100, 170, 0.2)',
         'rgba(223, 165, 25, 0.2)',
         'rgba(118, 135, 37, 0.2)',
         'rgba(17, 69, 90, 0.2)',
         'rgba(116, 118, 122, 0.2)',
         'rgba(227, 99, 78, 0.2)',
         'rgba(214, 67, 200, 0.2)',
         'rgba(181, 214, 76, 0.2)',
         'rgba(223, 136, 108, 0.2)',
         'rgba(81, 203, 16, 0.2)',
         'rgba(239, 143, 109, 0.2)',
         'rgba(47, 247, 74, 0.2)',
         'rgba(215, 204, 119, 0.2)',
         'rgba(90, 174, 91, 0.2)',
         'rgba(77, 3, 42, 0.2)',
         'rgba(46, 58, 121, 0.2)',
         'rgba(253, 10, 162, 0.2)',
         'rgba(171, 179, 206, 0.2)',
         'rgba(175, 194, 20, 0.2)',
         'rgba(135, 92, 177, 0.2)',
         'rgba(143, 113, 112, 0.2)',
         'rgba(141, 34, 22, 0.2)',
         'rgba(54, 72, 214, 0.2)',
         'rgba(184, 187, 235, 0.2)',
         'rgba(169, 198, 58, 0.2)',
         'rgba(190, 206, 188, 0.2)',
         'rgba(44, 130, 28, 0.2)',
         'rgba(1, 71, 91, 0.2)',
         'rgba(26, 242, 94, 0.2)',
         'rgba(147, 52, 96, 0.2)',
         'rgba(217, 244, 184, 0.2)',
         'rgba(162, 181, 30, 0.2)',
         'rgba(176, 163, 14, 0.2)',
         'rgba(218, 219, 5, 0.2)',
         'rgba(86, 124, 108, 0.2)',
         'rgba(170, 56, 1, 0.2)',
         'rgba(222, 253, 201, 0.2)',
         'rgba(179, 8, 140, 0.2)',
         'rgba(39, 160, 68, 0.2)',
         'rgba(52, 28, 198, 0.2)',
         'rgba(255, 112, 197, 0.2)',
         'rgba(211, 76, 230, 0.2)',
         'rgba(98, 180, 123, 0.2)',
         'rgba(112, 178, 234, 0.2)',
         'rgba(48, 100, 115, 0.2)',
         'rgba(201, 215, 162, 0.2)',
         'rgba(38, 130, 245, 0.2)',
         'rgba(130, 3, 111, 0.2)',
         'rgba(205, 96, 64, 0.2)',
         'rgba(124, 242, 88, 0.2)',
         'rgba(44, 68, 26, 0.2)',
         'rgba(218, 8, 236, 0.2)',
         'rgba(99, 182, 155, 0.2)',
         'rgba(237, 17, 208, 0.2)',
         'rgba(249, 255, 114, 0.2)',
         'rgba(163, 7, 211, 0.2)',
         'rgba(156, 29, 213, 0.2)',
         'rgba(163, 118, 249, 0.2)',
         'rgba(13, 115, 222, 0.2)',
         'rgba(153, 87, 173, 0.2)',
         'rgba(71, 184, 88, 0.2)',
         'rgba(131, 172, 238, 0.2)',
         'rgba(64, 37, 5, 0.2)',
         'rgba(251, 59, 107, 0.2)',
         'rgba(33, 156, 197, 0.2)',
         'rgba(13, 175, 147, 0.2)',
         'rgba(221, 142, 3, 0.2)',
         'rgba(82, 147, 23, 0.2)',
         'rgba(97, 38, 103, 0.2)',
         'rgba(217, 126, 188, 0.2)',
         'rgba(126, 231, 55, 0.2)',
         'rgba(133, 75, 171, 0.2)',
         'rgba(243, 46, 224, 0.2)',
         'rgba(148, 218, 88, 0.2)',
         'rgba(221, 116, 119, 0.2)',
         'rgba(12, 232, 193, 0.2)',
         'rgba(37, 69, 219, 0.2)',
         'rgba(24, 46, 96, 0.2)',
         'rgba(151, 255, 92, 0.2)',
         'rgba(62, 124, 218, 0.2)',
         'rgba(40, 223, 140, 0.2)',
         'rgba(184, 237, 230, 0.2)',
         'rgba(10, 82, 248, 0.2)',
         'rgba(142, 35, 208, 0.2)',
         'rgba(17, 46, 48, 0.2)',
         'rgba(37, 33, 25, 0.2)',
         'rgba(226, 18, 188, 0.2)',
         'rgba(167, 220, 69, 0.2)',
         'rgba(40, 31, 123, 0.2)',
         'rgba(166, 55, 82, 0.2)',
         'rgba(138, 150, 162, 0.2)',
         'rgba(235, 48, 161, 0.2)',
         'rgba(212, 91, 8, 0.2)',
         'rgba(28, 72, 218, 0.2)',
         'rgba(2, 56, 30, 0.2)',
         'rgba(240, 91, 27, 0.2)',
         'rgba(200, 244, 149, 0.2)',
         'rgba(230, 112, 58, 0.2)',
         'rgba(108, 31, 220, 0.2)',
         'rgba(160, 120, 154, 0.2)',
         'rgba(195, 124, 87, 0.2)',
         'rgba(87, 214, 110, 0.2)',
         'rgba(54, 30, 172, 0.2)',
         'rgba(186, 108, 0, 0.2)',
         'rgba(78, 102, 70, 0.2)',
         'rgba(56, 178, 5, 0.2)',
         'rgba(49, 195, 161, 0.2)',
         'rgba(173, 158, 183, 0.2)',
         'rgba(150, 210, 37, 0.2)',
         'rgba(68, 113, 52, 0.2)',
         'rgba(152, 30, 130, 0.2)',
         'rgba(208, 52, 52, 0.2)',
         'rgba(159, 171, 153, 0.2)',
         'rgba(144, 195, 145, 0.2)',
         'rgba(84, 127, 249, 0.2)',
         'rgba(66, 254, 212, 0.2)',
         'rgba(252, 166, 31, 0.2)',
         'rgba(198, 175, 235, 0.2)',
         'rgba(157, 152, 229, 0.2)',
         'rgba(198, 201, 111, 0.2)',
         'rgba(153, 195, 200, 0.2)',
         'rgba(124, 112, 25, 0.2)',
         'rgba(36, 192, 132, 0.2)',
         'rgba(186, 76, 206, 0.2)',
         'rgba(222, 53, 37, 0.2)',
         'rgba(36, 178, 249, 0.2)',
         'rgba(249, 16, 71, 0.2)',
         'rgba(24, 26, 149, 0.2)',
         'rgba(240, 250, 231, 0.2)',
         'rgba(26, 188, 68, 0.2)',
         'rgba(85, 226, 31, 0.2)',
         'rgba(34, 222, 59, 0.2)',
         'rgba(2, 59, 11, 0.2)',
         'rgba(213, 76, 179, 0.2)',
         'rgba(144, 57, 112, 0.2)',
         'rgba(190, 75, 209, 0.2)',
         'rgba(91, 232, 149, 0.2)',
         'rgba(94, 166, 151, 0.2)',
         'rgba(215, 172, 152, 0.2)',
         'rgba(78, 1, 62, 0.2)',
         'rgba(87, 162, 117, 0.2)',
         'rgba(90, 109, 19, 0.2)',
         'rgba(134, 92, 131, 0.2)',
         'rgba(192, 12, 53, 0.2)',
         'rgba(175, 185, 203, 0.2)',
         'rgba(109, 213, 183, 0.2)',
         'rgba(119, 126, 236, 0.2)',
         'rgba(133, 175, 57, 0.2)',
         'rgba(64, 42, 10, 0.2)',
         'rgba(128, 167, 189, 0.2)',
         'rgba(100, 172, 238, 0.2)',
         'rgba(236, 174, 96, 0.2)',
         'rgba(59, 250, 155, 0.2)',
         'rgba(194, 91, 46, 0.2)',
         'rgba(172, 68, 54, 0.2)',
         'rgba(161, 154, 75, 0.2)',
         'rgba(233, 37, 222, 0.2)',
         'rgba(45, 64, 74, 0.2)',
         'rgba(86, 2, 91, 0.2)',
       ];
