import React, { useState, useEffect } from 'react';

// Appt Components
import Box from '../../components/simple/box/Box';
import Button from '../../components/simple/button/Button';
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome';
import Form from '../../components/simple/form/Form.js';
import FormField from '../../components/simple/formField/FormField.js';
import Divider from '../../components/simple/divider/Divider';
import H2 from '../../components/simple/heading/H2.js';
import RatingButtonGroup from '../../components/compound/ratingButtonGroup/RatingButtonGroup';
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid';
import Text from '../../components/simple/text/Text';
import TextArea from '../../components/simple/input/TextArea.js';

function PhysicalTechniques(props) {
  const [questions, setQuestions] = useState(props.questions);

  const [localIncidentValues, setLocalIncidentValues] = useState(
    props.incidentValues,
  );

  const [physicalTechniqueArray, setPhysicalTechniqueArray] = useState([]);

  const previousPage = () => {
    buildTechniqueArray();

    props.previousPage(localIncidentValues);
  };

  const nextPage = () => {
    buildTechniqueArray();

    props.nextPage(localIncidentValues);
  };

  const submitForm = () => {
    buildTechniqueArray();

    props.submitForm(localIncidentValues, 'Draft');
  };

  useEffect(() => {
    if (
      props.incidentValues['restrictedPhysicalInterventionUsed'] === 'No' &&
      props.incidentValues['physicalInterventionUsed'] === 'No'
    ) {
      if (props.goingBack) {
        previousPage();
      } else {
        nextPage();
      }
    }
  }, []);

  const buildForm = () => {
    const questionList = [];
    questions.forEach((item, index) => {
      if (index !== questions.length - 1) {
        var value = 'n/a';
        if (
          localIncidentValues[item.key] &&
          localIncidentValues[item.key] !== ''
        ) {
          value = localIncidentValues[item.key];
        }

        questionList.push(
          <FormField
            key={index}
            label={questions[index].questionText}
            name={questions[index].key}
          >
            <RatingButtonGroup
              clear="n/a"
              id={questions[index].key}
              name={questions[index].key}
              value={value}
              minRange={1}
              maxRange={10}
              step={1}
              disabled={props.formId}
            />
          </FormField>,
        );
      } else {
        if (
          localIncidentValues.otherPhysicalTechniques &&
          localIncidentValues.otherPhysicalTechniques !== 'n/a'
        ) {
          questionList.push(
            <FormField
              key={index}
              label={questions[index].questionText}
              name={questions[index].key}
            >
              <TextArea name={questions[index].key} />
            </FormField>,
          );
        }
      }
    });

    return questionList;
  };

  const buildTechniqueArray = () => {
    const techniqueArray = [];

    questions.forEach((item, index) => {
      if (index !== questions.length - 1) {
        techniqueArray.push({
          key: questions[index].key,
          value: localIncidentValues[questions[index].key],
        });
      }
    });

    setPhysicalTechniqueArray(techniqueArray);

    setLocalIncidentValues(localValues => ({
      ...localValues,
      physicalTechnique: JSON.stringify(techniqueArray),
    }));
  };

  return (
    <>
      {props.incidentValues['restrictedPhysicalInterventionUsed'] === 'No' &&
      props.incidentValues['physicalInterventionUsed'] === 'No' ? (
        <></>
      ) : (
        <Box
          gridArea="main"
          background="white"
          direction="column"
          gap="small"
          round="small"
        >
          <Box
            gap="small"
            margin={{ horizontal: 'small' }}
            pad="small"
            round="small"
          >
            <Box fill direction="row" gap="small">
              <H2 margin={{ vertical: 'none' }}>
                Physical Techniques used and effectiveness
              </H2>
              <Button
                label={
                  <Text>
                    <FontAwesome
                      color="brand"
                      icon={['fas', 'question-circle']}
                    />
                  </Text>
                }
                plain
                tip={{
                  content: (
                    <Box
                      background={{ color: 'white' }}
                      border={{ color: 'brand', size: 'small' }}
                      direction="column"
                      pad="small"
                    >
                      <Text>
                        Please record the effectiveness of any de-escalation
                        techniques. De-escalation techniques should also be used
                        throughout the intervention.
                      </Text>
                    </Box>
                  ),
                }}
              />
            </Box>

            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />
            <Form
              //   validate='blur'
              onChange={nextValue => {
                setLocalIncidentValues(nextValue);
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue);
              }}
              value={localIncidentValues}
            >
              {!questions?.length && (
                <Text>
                  No physical techniques are setup for this{' '}
                  {props?.terms?.service || 'service'}.
                </Text>
              )}

              {questions.length > 0 && (
                <Box direction="row">
                  <Text margin={{ right: 'small' }}>
                    1=Not Effective 10=Very Effective
                  </Text>
                  <Button
                    label={
                      <Text>
                        <FontAwesome
                          color="brand"
                          icon={['fas', 'question-circle']}
                        />
                      </Text>
                    }
                    plain
                    tip={{
                      content: (
                        <Box
                          background={{ color: 'white' }}
                          border={{ color: 'brand', size: 'small' }}
                          direction="column"
                          pad="small"
                        >
                          <Text>
                            In your opinion how effective was the physical
                            techniques used.
                          </Text>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              )}

              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['auto', 'auto'],
                  large: ['auto', 'auto'],
                  xlarge: ['auto', 'auto'],
                }}
                gap="small"
              >
                {buildForm()}
              </ResponsiveGrid>

              <Divider
                color="primary"
                margin={{ top: 'medium', bottom: 'none' }}
              />

              <Box
                direction="row"
                gap="small"
                justify="between"
                margin={{ top: 'medium' }}
              >
                <Button
                  label="< Back"
                  onClick={() => previousPage()}
                  secondary
                />
                {!props.formId && (
                  <Button
                    icon={<FontAwesome icon={['fal', 'save']} color="#fff" />}
                    label="Save for later"
                    onClick={() => submitForm()}
                  />
                )}
                <Button label="Next Page" onClick={() => nextPage()} primary />
              </Box>
            </Form>
          </Box>
        </Box>
      )}
    </>
  );
}
export default PhysicalTechniques;
