import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Appt Components
import ReactToPrint from "react-to-print"
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid.js'
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box.js'
import Button from '../../components/simple/button/Button.js'
import Divider from '../../components/simple/divider/Divider.js'
import Text from '../../components/simple/text/Text.js'

// App widgets

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar.js'

import { Polar, Bar } from 'react-chartjs-2';
import SimpleCounter from '../../components/compound/simpleCounter/SimpleCounter';

import LocalStorageService from '../../services/LocalStorageService.js'
import { capitalize } from '../../utils/text.js'
import { backgroundColours, borderColours } from './colours.js'

const PILExtrasReport = props => {
  const history = useHistory();
  const terms = LocalStorageService.get('terms');

  const [numBullyingUsers, setBullyingUsers] = useState([]);
  const [numRacistUsers, setRacistUsers] = useState([]);
  const [numSexistUsers, setSexistUsers] = useState([]);
  const [numHomophobicUsers, setHomophobicUsers] = useState([]);
  const [numBiphobicUsers, setBiphobicUsers] = useState([]);
  const [numTransphobicUsers, setTransphobicUsers] = useState([]);
  const [numDisabilityUsers, setDisabilityUsers] = useState([]);
  const [numDerogatoryUsers, setDerogatoryUsers] = useState([]);
  const [numSexualHarassmentUsers, setSexualHarassmentUsers] = useState([]);
  const [numStudentOnStudentUsers, setStudentOnStudentUsers] = useState([]);
  const [numSelfInjuriousUsers, setSelfInjuriousUsers] = useState([]);
  const [numSuspendedStudents, setSupendedStudents] = useState([]);
  const [numExpelledStudents, setExpelledStudents] = useState([]);
   const [numOfExtras, setnumOfExtras] = useState([]);

  // Incidents by Student Group
   const numberOfExtras = {
     labels: numOfExtras?.at(0),
     datasets: [
       {
         data: numOfExtras?.at(1),
         backgroundColor: backgroundColours,
         borderColor: borderColours,
         borderWidth: 1,
       },
     ],
   };
   const suspendedStudents = {
     labels: numSuspendedStudents?.at(0),
     datasets: [
       {
         data: numSuspendedStudents?.at(1),
         backgroundColor: backgroundColours,
         borderColor: borderColours,
         borderWidth: 1,
       },
     ],
   };
    const expelledStudents = {
      labels: numExpelledStudents?.at(0),
      datasets: [
        {
          data: numExpelledStudents?.at(1),
          backgroundColor: backgroundColours,
          borderColor: borderColours,
          borderWidth: 1,
        },
      ],
    };

  const bullyingUsers = {
    labels: numBullyingUsers?.at(0),
    datasets: [
      {
        data: numBullyingUsers?.at(1),
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };
   const racistUsers = {
     labels: numRacistUsers?.at(0),
     datasets: [
       {
         data: numRacistUsers?.at(1),
         backgroundColor: backgroundColours,
         borderColor: borderColours,
         borderWidth: 1,
       },
     ],
   };
    const sexistUsers = {
      labels: numSexistUsers?.at(0),
      datasets: [
        {
          data: numSexistUsers?.at(1),
          backgroundColor: backgroundColours,
          borderColor: borderColours,
          borderWidth: 1,
        },
      ],
    };
    const disabilityUsers = {
      labels: numDisabilityUsers?.at(0),
      datasets: [
        {
          data: numDisabilityUsers?.at(1),
          backgroundColor: backgroundColours,
          borderColor: borderColours,
          borderWidth: 1,
        },
      ],
    };
    const homophobicUsers = {
      labels: numHomophobicUsers?.at(0),
      datasets: [
        {
          data: numHomophobicUsers?.at(1),
          backgroundColor: backgroundColours,
          borderColor: borderColours,
          borderWidth: 1,
        },
      ],
    };
     const biphobicUsers = {
       labels: numBiphobicUsers?.at(0),
       datasets: [
         {
           data: numBiphobicUsers?.at(1),
           backgroundColor: backgroundColours,
           borderColor: borderColours,
           borderWidth: 1,
         },
       ],
     };
     const transphobicUsers = {
       labels: numTransphobicUsers?.at(0),
       datasets: [
         {
           data: numTransphobicUsers?.at(1),
           backgroundColor: backgroundColours,
           borderColor: borderColours,
           borderWidth: 1,
         },
       ],
     };
     const derogatoryUsers = {
       labels: numDerogatoryUsers?.at(0),
       datasets: [
         {
           data: numDerogatoryUsers?.at(1),
           backgroundColor: backgroundColours,
           borderColor: borderColours,
           borderWidth: 1,
         },
       ],
     };
     const sexualHarassmentUsers = {
       labels: numSexualHarassmentUsers?.at(0),
       datasets: [
         {
           data: numSexualHarassmentUsers?.at(1),
           backgroundColor: backgroundColours,
           borderColor: borderColours,
           borderWidth: 1,
         },
       ],
     };
      const studentOnStudent = {
        labels: numStudentOnStudentUsers?.at(0),
        datasets: [
          {
            data: numStudentOnStudentUsers?.at(1),
            backgroundColor: backgroundColours,
            borderColor: borderColours,
            borderWidth: 1,
          },
        ],
      };
         const selfInjurious = {
           labels: numSelfInjuriousUsers?.at(0),
           datasets: [
             {
               data: numSelfInjuriousUsers?.at(1),
               backgroundColor: backgroundColours,
               borderColor: borderColours,
               borderWidth: 1,
             },
           ],
         };

  const printScreen = () => {
    window.print();
  };

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets
    console.log(`ALEX`, props?.location?.state);
    ;

    if (props?.location?.state?.pilExtrasReport !== null) {
      setnumOfExtras(props?.location?.state?.pilExtrasReport);
    }

    if (props?.location?.state?.pilExtrasByUserSuspension !== null) {
      setSupendedStudents(props?.location?.state?.pilExtrasByUserSuspension);
    }
    if (props?.location?.state?.pilExtrasByUserPermanentExclusion !== null) {
      setExpelledStudents(
        props?.location?.state?.pilExtrasByUserPermanentExclusion,
      );
    }

    if (props?.location?.state?.pilExtrasByUserBiphobic !== null) {
      setTransphobicUsers(props?.location?.state?.pilExtrasByUserBiphobic);
    }

    if (props?.location?.state?.pilExtrasByUserBiphobic !== null) {
      setBiphobicUsers(props?.location?.state?.pilExtrasByUserBiphobic);
    }

    if (props?.location?.state?.pilExtrasByUserBullying !== null) {
      setBullyingUsers(props?.location?.state?.pilExtrasByUserBullying);
    }

    if (props?.location?.state?.pilExtrasByUserDisability !== null) {
      setDisabilityUsers(props?.location?.state?.pilExtrasByUserDisability);
    }

    if (props?.location?.state?.pilExtrasByUserHomophobic !== null) {
      setHomophobicUsers(props?.location?.state?.pilExtrasByUserHomophobic);
    }

    if (props?.location?.state?.pilExtrasByUserRacist !== null) {
      setRacistUsers(props?.location?.state?.pilExtrasByUserRacist);
    }

    if (
      props?.location?.state?.pilExtrasByUserSelfInjuriousBehaviour !== null
    ) {
      setSelfInjuriousUsers(
        props?.location?.state?.pilExtrasByUserSelfInjuriousBehaviour,
      );
    }

    if (props?.location?.state?.pilExtrasByUserSexist !== null) {
      setSexistUsers(props?.location?.state?.pilExtrasByUserSexist);
    }

    if (props?.location?.state?.pilExtrasByUserSexualharassment !== null) {
      setSexualHarassmentUsers(
        props?.location?.state?.pilExtrasByUserSexualharassment,
      );
    }

    if (props?.location?.state?.pilExtrasByUserStudentOnStudent !== null) {
      setStudentOnStudentUsers(
        props?.location?.state?.pilExtrasByUserStudentOnStudent,
      );
    }

    if (
      props?.location?.state?.pilExtrasByUserUseOfDerogatoryLanguage !== null
    ) {
      setDerogatoryUsers(
        props?.location?.state?.pilExtrasByUserUseOfDerogatoryLanguage,
      );
    }
  }, []);

  let componentRef = null;
  let setComponentRef = ref => {
    componentRef = ref;
  };

  let reactToPrintContent = () => {
    return componentRef;
  };
  const reactToPrintTrigger = () => {
    return (
      <Button
        label={<FontAwesome icon={['fal', 'print']} size="xs" />}
        className="noprint"
        plain
        target="_self"
      />
    );
  };

  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        <Box direction="row" justify="between">
          <Text>
            Discriminatory and prejudiced behaviour. Suspensions and exclusions.
          </Text>

          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </Box>
      </BreadcrumbBar>

      <Box ref={setComponentRef} direction="column" gap="small" round="small">
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['50%', '50%'],
            medium: ['50%', '50%'],
            large: ['50%', '50%'],
            xlarge: ['50%', '50%'],
          }}
          gap="small"
          pad="xxsmall"
        >
          <SimpleCounter
            count={
              props?.location?.state?.pilExtrasReportExclusions
                ? String(props.location.state.pilExtrasReportExclusions[1][0])
                : '0'
            }
            label="Suspensions"
          />
          <SimpleCounter
            count={
              props?.location?.state?.pilExtrasReportExclusions
                ? String(props.location.state.pilExtrasReportExclusions[1][1])
                : '0'
            }
            label=" Permanent Exclusion"
          />
        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['100%'],
            medium: ['100%'],
            large: ['100%'],
            xlarge: ['100%'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Incidents
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numOfExtras?.length !== 0 ? (
              <Bar
                data={numberOfExtras}
                height={25}
                legend={{ display: false }}
                // options={{ maintainAspectRatio: false }}
                width={'100%'}
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>
        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['50%', '50%'],
            medium: ['50%', '50%'],
            large: ['50%', '50%'],
            xlarge: ['50%', '50%'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Suspensions
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numSuspendedStudents?.length !== 0 ? (
              <Polar
                data={suspendedStudents}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Permanent Exclusion
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numExpelledStudents?.length !== 0 ? (
              <Polar
                data={expelledStudents}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>
        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['33%', '33%', '33%'],
            medium: ['33%', '33%', '33%'],
            large: ['33%', '33%', '33%'],
            xlarge: ['33%', '33%', '33%'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Bullying
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numBullyingUsers?.length !== 0 ? (
              <Polar
                data={bullyingUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Racist
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numRacistUsers?.length !== 0 ? (
              <Polar
                data={racistUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Sexist
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numSexistUsers?.length !== 0 ? (
              <Polar
                data={sexistUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>
        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['33%', '33%', '33%'],
            medium: ['33%', '33%', '33%'],
            large: ['33%', '33%', '33%'],
            xlarge: ['33%', '33%', '33%'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Homophobic
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numHomophobicUsers?.length !== 0 ? (
              <Polar
                data={homophobicUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Biphobic
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numBiphobicUsers?.length !== 0 ? (
              <Polar
                data={biphobicUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Transphobic
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numTransphobicUsers?.length !== 0 ? (
              <Polar
                data={transphobicUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>
        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['33%', '33%', '33%'],
            medium: ['33%', '33%', '33%'],
            large: ['33%', '33%', '33%'],
            xlarge: ['33%', '33%', '33%'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Disablist
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numDisabilityUsers?.length !== 0 ? (
              <Polar
                data={disabilityUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Derogatory Language Used
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numDerogatoryUsers?.length !== 0 ? (
              <Polar
                data={derogatoryUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Sexual Harassment
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numSexualHarassmentUsers?.length !== 0 ? (
              <Polar
                data={sexualHarassmentUsers}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>
        </ResponsiveGrid>

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['50%', '50%'],
            medium: ['50%', '50%'],
            large: ['50%', '50%'],
            xlarge: ['50%', '50%'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              {capitalize(terms?.serviceUser || 'Service User')} On{' '}
              {capitalize(terms?.serviceUser || 'Service User')}
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numStudentOnStudentUsers?.length !== 0 ? (
              <Polar
                data={studentOnStudent}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Self Injurious
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {numSelfInjuriousUsers?.length !== 0 ? (
              <Polar
                data={selfInjurious}
                height={150}
                width={150}
                options={{ maintainAspectRatio: false }}
                legend={{ display: true }}
                // width='400px'
              />
            ) : (
              <Text textAlign="center">Not enough data available</Text>
            )}
          </Box>
        </ResponsiveGrid>
      </Box>
    </>
  );
};

export default PILExtrasReport;
