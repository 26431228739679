import ApiService from './ApiService'

class UserGroupMemberService {
  create = async (apiToken, options) => {
    var url = '/api/usergroupmember'

    var params = {}

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupMemberService:create ', result)
    return result
  }

  getMultiple = async (apiToken, customParams, where) => {
    var url = '/api/usergroupmember'

    var params = {
      fields: 'id,usergroup,user',
      limit: 20,
      ...customParams
    }

    if (where) {
      params.where = where
    }

    try {
      var groups = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupMemberService:getMultiple ', groups)
    return groups
  }

  removeFromGroup = async (apiToken, groupId) => {
    var url = `/api/usergroupmember/${groupId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupMemberService:removeFromGroup ', result)
    return result
  }
}
export default new UserGroupMemberService()
