// This is used to determine if a user is authenticated by checking if an apiToken exists
// and if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

// Appt Components
import Box from './components/simple/box/Box'

// Services
import LocalStorageService from './services/LocalStorageService'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const apiToken = LocalStorageService.get('apiToken')

  return (
    <Route
      {...rest}
      render={props =>
        apiToken ? (
          <Box className='privateRouteWrapper' width='xlarge' alignSelf='start'>
            <Component {...rest} />
          </Box>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
       )}
    />
  )
}

export default PrivateRoute
