// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { MaskedInput as GMaskedInput } from 'grommet'

/**
 * `MaskedInput` renders a Grommet MaskedInput box
 */
class MaskedInput extends React.Component {
  render () {
    const onChange = (input) => {
      if (this.props.onChange) {
        this.props.onChange(input)
      }
    }

    return (
      <GMaskedInput
        {...this.props}
        onChange={(input) => onChange(input)}
      />)
  }
}

MaskedInput.propTypes = {
  /**
   * Custom label to be used by screen readers.
   *
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * An optional icon to show. This could be used to provide an indication of what kind of input is expected,
   * like an email icon, or what the input will be used for, like a search icon.
   *
   * "element"
   */
  icon: PropTypes.element,
  /**
   * The id attribute of the input.
   */
  id: PropTypes.string,
  /**
   * Describes the structure of the mask.
   * If a regexp is provided, it should allow both the final full string element as well as partial strings as the user types characters one by one.
   *
   * [
   * &nbsp;{
   * &nbsp;&nbsp;length: [1, 2],
   * &nbsp;&nbsp;regexp: /^1[1-2]$|^[0-9]$/,
   * &nbsp;&nbsp;placeholder: 'hh',
   * &nbsp;},
   * &nbsp;{
   * &nbsp;&nbsp;fixed: ':',
   * &nbsp;},
   * &nbsp;&nbsp;...
   * ]
   */
  mask: PropTypes.array,
  /**
   * The name attribute of the input.
   *
   * "string"
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user leaves the field.
   *
   * "function"
   */
  onBlur: PropTypes.func,
  /**
   * Function that will be called when the user types or pastes text.
   *
   * "function"
   */
  onChange: PropTypes.func,
  /**
   * Whether an icon should be reversed so that the icon is at the end of the input.
   *
   * true
   * false
   */
  reverse: PropTypes.bool,
  /**
   * The size of the text.
   *
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * "string"
   */
  size: PropTypes.string,
  /**
   * What text to put in the input.
   * The caller should ensure that it is initially valid with respect to the mask.
   *
   * "string"
   * number
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default MaskedInput
