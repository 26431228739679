// React
import React from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box'
import RadioButtonGroup from '../../simple/input/RadioButtonGroup'
import Text from '../../simple/text/Text'

/**
 * `RatingButtonGroup` renders a RadioButtonGroup
 */
function RatingButtonGroup (props) {
  const valueArray = []

  const buildValueArray = () => {
    let i = props.minRange

    while (i <= props.maxRange) {
      valueArray[i] = i
      i = i + props.step
    }

    if (props.clear) {
      valueArray[i] = props.clear
    }
  }

  buildValueArray()

  return (
    <RadioButtonGroup
      direction='row'
      options={valueArray}
      {...props}
    >
      {(option, { checked, hover }) => {
        let background
        if (checked) background = 'brand'
        else if (hover) background = 'light-4'
        else background = 'none'
        return (
          <Box align='center' background={background} border={{ color: 'brand', size: 'small' }} height='25px' justify='center' round='full' width='25px'>
            <Text color={checked ? '#fff' : '#000'} size='small'>{option}</Text>
          </Box>
        )
      }}
    </RadioButtonGroup>)
}

RatingButtonGroup.defaultProps = {
  minRange: 1,
  maxRange: 10,
  step: 1
}

RatingButtonGroup.propTypes = {
  /**
   * Can act as a null setting for the group and is added to the end of the group
   */
  clear: PropTypes.string,
  /**
   * group id
   */
  id: PropTypes.string,
  /**
   * group name
   */
  name: PropTypes.string,
  /**
   * Minimum value in range
   */
  minRange: PropTypes.number,
  /**
   * Maximum value in range
   */
  maxRange: PropTypes.number,
  /**
   * Range step value
   *
   * eg. minRange=1, maxRange=5, step=2
   * [1,3,5]
   */
  step: PropTypes.number
}

export default RatingButtonGroup
