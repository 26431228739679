import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
// import FileUpload from '../components/simple/input/FileUpload.js'
import Form from '../../components/simple/form/Form.js'
import Text from '../../components/simple/text/Text'

import { CSVReader } from 'react-papaparse'
import { buildInsertFields, validateInvalidFields, validateRequiredFields } from '../../utils/csv.js'

function UserUpload (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [userData, setUserData] = useState([])

  const submitForm = async () => {
    // Only submit if a file has been uploaded
    if (userData.length) {
      // Check to see if there are any unknown fields
      const fileValidated = validateInvalidFields(userData, props.type === 'serviceuser' ? 'student' : 'user')

      if (!fileValidated.valid) {
        NotificationService.error(fileValidated.error)
        return
      }

      // // Check that all required fields have been populated
      // const filePopulated = validateRequiredFields(userData, props.type === 'serviceuser' ? 'student' : 'user')
      // if (!filePopulated.valid) {
      //   NotificationService.error(filePopulated.error)
      //   return
      // }

      if (fileValidated.warning) {
        NotificationService.warning(fileValidated.warning)
      }

      // strip out blank rows
      const filtereUserData = userData.filter(u => !(u.data[0] === '' && u.data[1] === '' && u.data[2] === '' && u.data[3] === ''))

      // Now build an insert statement
      const dataToInsert = buildInsertFields(filtereUserData, props.type === 'serviceuser' ? 'student' : 'user')
      let userError = false
      NotificationService.warning("Uploading data... Please wait and do not refresh the screen, this can take up to 5 minutes.")
      for (const student of dataToInsert) {
        console.log(student)
        const response = await submitUsers(student)

        if (response.error) {
          NotificationService.error(response.error)
          userError = true
        }
      }

      if (!userError) {
        NotificationService.success('All users added')
        history.push('/serviceusers')
      }
    }
  }

  // Submit Users from CSV file
  const submitUsers = async (student) => {
    student.type = props.type === 'serviceuser' ? 'student' : 'user'

    var studentToSave = student

    // If service user separate key contacts
    // from user details
    if (props.type === 'serviceuser') {
      studentToSave = {
        firstName: student.firstName,
        lastName: student.lastName,
        dob: student.dob ? new Date(student.dob) : null, // force the dob to be a date
        email: student.email,
        ethnicity: student.ethnicity,
        gender: student.gender,
        reference: student.reference,
        type: student.type
      }

      var contactsToSave = []

      const contact1 = {
        firstName: student.contact1FirstName,
        lastName: student.contact1LastName,
        role: student.contact1Role,
        email: student.contact1Email
      }

      const contact2 = {
        firstName: student.contact2FirstName,
        lastName: student.contact2LastName,
        role: student.contact2Role,
        email: student.contact2Email
      }

      contactsToSave.push(contact1)
      contactsToSave.push(contact2)
    }
    const data = {
      data: studentToSave,
      organisation: activeOrg.id
    }

    const userAdded = await UserService.createUser(apiToken, data)

    if (userAdded.error) {
      NotificationService.error(userAdded.error)
    } else {
      // Save service user key contacts
      if (props.type === 'serviceuser') {
        submitContacts(userAdded.data.id[0], contactsToSave)
      }
    }

    return userAdded
  }

  // Submit Contact Details - userAdded.data.id[0]
  const submitContacts = async (userId, contacts) => {
    // console.log('submitContact ', values)

    const data = [
      {
        key: 'keycontacts',
        organisation: activeOrg.id,
        type: 'keycontacts',
        user: userId,
        value: JSON.stringify(contacts)
      }
    ]

    console.log(data)

    const tagSet = await UserTagService.set(apiToken, data)
    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    }
  }

  // Handle Drag & Drop functionality
  const handleOnDrop = (data) => {
    // Assume first row is a header row
    console.log('on drop', data)

    // // Generate headings from first row
    // const headings = []
    // for (const key in data[0]) {
    //   headings.push(key)
    // }

    // setUserHeadings(headings)

    // // No populate each row in the same order as the headings
    // for (const dataRow of data) {
    //   var thisRow = []
    //   for (const thisKey of headings) {
    //     const value = dataRow[thisKey] || null
    //     thisRow.push(value)
    //   }
    //   rows.push(thisRow)
    // }

    // Validate records to ensure required fields are populated
    // const response = validateFile(data)

    // if (response.status) {
    //   NotificationService.error('Row ' + response.row + ', column ' + response.col + ' is missing. Please fix and add the file again.')
    // } else {
    //   setUserData(data)
    // }
    setUserData(data)
  }

  const handleOnError = (err, file, inputElem, reason) => {
    NotificationService.error(err)
  }

  const handleOnRemoveFile = (data) => {
    console.log('on remove file', data)
  }

  const validateFile = (data) => {
    // Columns should be
    // firstName
    // lastName
    // email
    // ethnicity
    // gender
    // dob
    //
    // Assume all required for now
    let row = 1
    const error = {
      status: false,
      row: null,
      col: null
    }

    data.forEach((itemRow) => {
      // Skip first row (header) or if error found
      if (row !== 1 || !error.status) {
        let col = 1
        itemRow.data.forEach((itemCol) => {
          if (!error.status) {
            if (itemCol === '') {
              error.row = row
              error.col = col
              error.status = true
            }
          }
          col++
        })
      }

      row++
    })

    return error
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  // Basically componentDidMount
  useEffect(() => {
    console.log(props)
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Bulk User Upload</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Text margin={{ top: 'xsmall' }} size='xlarge'>Bulk User Upload</Text>

            <Text>To bulk upload users, please follow the instructions below carefully.</Text>

            <Text size='large' weight={600}>Step 1</Text>
            <Text>Click here to <Anchor href={`/bulk-import-template-${props.type}.csv`}>download the bulk upload template file</Anchor></Text>

            <Text size='large' weight={600}>Step 2</Text>
            <Text>Add data to the template file.</Text>
            {props.type === 'serviceuser' &&
              <>
                <Text>The <Text weight={500}>ethnicity</Text> field must be either: white, mixed, asian, black, other or left blank.</Text>
                <Text>The <Text weight={500}>gender</Text> field must be either: male, female, other or left blank.</Text>
                <Text>The <Text weight={500}>dob</Text> field must be in the format dd/mm/yyyy.</Text>
              </>}
            {props.type === 'user' &&
              <Text>The <Text weight={500}>role</Text> field can be either Restricted or Full User.</Text>}

            <Text size='large' weight={600}>Step 3</Text>
            <Text>Save the file. It must be saved as a CSV to import. Before saving please check that the headings in the first row haven't been changed.</Text>

            <Text size='large' weight={600}>Step 4</Text>
            <Text>Drag and drop the file into the box below and press save to import the data</Text>

            <Form
              onSubmit={({ value: nextValue }) => {
                console.log(nextValue)
                submitForm(nextValue)
              }}
            //   value={}
            >

              <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                onRemoveFile={handleOnRemoveFile}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserUpload
