import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import ApiService from '../../services/ApiService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationService from '../../services/OrganisationService'
import UserService from '../../services/UserService'
import RoleService from '../../services/RoleService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'

// Shared Components
import QuickActionBar from '../shared/QuickActionBar'
import BreadcrumbBar from '../shared/BreadcrumbBar'

function UserEdit (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  // Initialize userDetails otherwise we get error
  // A component is changing an uncontrolled input of type text to be controlled
  const [userDetails, setUserDetails] = useState({
    organisation: 0,
    role: 0,
    // username: '',
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: ''
  })

  // Contains array of org objects for populating the School dropdown
  const [orgValues, setOrgValues] = useState([])

  const [saveLocked, setSaveLocked] = useState(false)


  // Contains an Organisation object that has been selected from the School dropdown
  const [selectedOrg, setSelectedOrg] = useState(null)

  // Contains array of role objects for populating the Role dropdown
  const [roleValues, setRoleValues] = useState([])

  const [originalRole, setOriginalRole] = useState(null)


  // const roles = ['Teacher', 'Supervisor', 'Headteacher']

  const params = useParams()

  const userId = params.userid

  // Submit form values
  const submitForm = async (values) => {
    // console.log('submitForm ', values)

    if (userId === 'new') {

      setSaveLocked(true)

      var userAdded = await UserService.inviteUser(apiToken, values)
      if (userAdded.error) {
        // If the error message is about a user limit, display it
        var errorStr = 'Error saving the user, please try again.'
        if (userAdded?.error?.errorType === 'userlimit') {
          errorStr = userAdded.error.error
        }
        NotificationService.error(errorStr)
      } else {
        NotificationService.info('User added')
        history.push('/users', { message: userAdded.data.message })
      }
    } else {
      var userUpdated = await UserService.updateUser(apiToken, values)
      if (userUpdated.error) {
        NotificationService.error('Error saving the user, please try again - ', userUpdated.error)
        return
      }

      // if the role has changed, update the role
      if (values.role && originalRole !== values.role) {
        var roleUpdated = await RoleService.addRoleUser(apiToken, null, values.role, values.id)
        if (roleUpdated.error) {
          NotificationService.error('Error saving the user, please try again - ', roleUpdated.error)
          return
        }
      }

      NotificationService.success('User updated successfully')
      history.push('/users', { message: 'User updated successfully' })

    }
  }

  // A different school has been selected
  const changeOrganisation = (org) => {
    setSelectedOrg(org)
  }

  // Reset User password
  const resetPassword = async (email) => {
    try {
      var passwordResetStatus = await ApiService.resetPassword(null, email)
    } catch (err) {
      NotificationService.error('Password reset error - ', err)
      console.log(err)
      return
    }

    NotificationService.success('Password reset email has been sent', passwordResetStatus)
  }

  const deleteUser = () => {
    history.push('userdelete')
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    // get the current user's role
    const getRoleForUser = async () => {
      // don't do anything if the user is new
      if (userId === 'new') {
        return
      }

      // don't do anything if we don't have any roles
      if (!roleValues.length) {
        return
      }

      // don't do anything if we don't have a selectedOrg
      if (!selectedOrg.id) {
        return
      }

      const roles = await RoleService.getRoleForUser(apiToken, userId)
      if (roles?.error) {
        NotificationService.error(roles.error)
      } else if (roles?.data) {
        // get the role that belongs to the current organisation
        var roleForThisOrg = roles.data.find(role => parseInt(role.organisation) === selectedOrg.id)
        if (roleForThisOrg) {
          setUserDetails({
            ...userDetails,
            role: roleForThisOrg.id
          })

          setOriginalRole(roleForThisOrg.id)

        }
      }
    }

    if (selectedOrg) {
      getRoleForUser()
    }
  }, [roleValues, selectedOrg])

  useEffect(() => {
    const getRoles = async () => {
      // don't do anything if we don't have a selected org
      if (!selectedOrg?.id) {
        return
      }
      const roles = await OrganisationService.getRolesForOrg(apiToken, selectedOrg.id)
      if (roles?.error) {
        NotificationService.error(roles.error)
      } else if (roles?.data) {
        console.log('roles ', roles)
        for (let role of roles.data) {
          if ( role.name === 'Admin') {
            role.name = 'Full User'
          } else if (role.name === 'Standard') {
            role.name = 'Restricted User'
          }
        }
        setRoleValues(roles.data)
      }
    }

    if (selectedOrg) {
      getRoles()
    }
  }, [selectedOrg])

  useEffect(() => {
    let unmounted = false
    const getUserDetails = async (userId) => {
      const params = {
        fields: 'id,firstName,lastName,email,jobTitle',
        type: 'user'
      }

      const response = await UserService.getUser(apiToken, params, userId)
      if (response.error) {
        NotificationService.error(response.error)
      } else {
        var newDetails = {
          ...userDetails,
          ...response.data[0]
        }
        setUserDetails(newDetails)
      }
    }
    if (!unmounted) {
      if (userId !== 'new') {
        getUserDetails(userId)
      }
    }
    return () => { unmounted = true }
  }, [orgValues])

  useEffect(() => {
    let unmounted = false

    const getOrganisations = async () => {
      var organisations = LocalStorageService.get('userOrganisations')
      if (organisations.error) {
        NotificationService.error(organisations.error)
      } else {
        setOrgValues(organisations)
        setSelectedOrg(activeOrg)

        // make sure the organisations comes back as an array
        var toSave = {
          ...userDetails,
          organisation: activeOrg.id
        }
        setUserDetails(toSave)
      }
    }
    if (!unmounted) {
      getOrganisations()
    }
    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        User: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}
      </BreadcrumbBar>

      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>User: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box> */}

      {userDetails.id &&
        <QuickActionBar button1Click={() => resetPassword(userDetails.email)} button1Label={<Text><FontAwesomeIcon icon={['fal', 'key']} /> Reset Password</Text>} button2Click={deleteUser} button2Label={<Text><FontAwesomeIcon icon={['fal', 'trash']} /> Delete User</Text>} />}

      <Box background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >

          {userId === 'new' &&
            <>
              <Text margin={{ top: 'medium' }} size='xlarge'>Invite User</Text>
              <Text margin={{ top: 'xsmall' }} size='small'>Enter the email address of the user you want to invite to your service in BehaviourSmart. They'll be sent a link via email inviting them to create an account.</Text>
            </>}

          {userId !== 'new' && <Text size='xlarge'>User Details</Text> }


          <Box direction='column' gap='medium'>

            <Form
              //   validate='blur'
              onChange={nextValue => {
                // console.log(nextValue)
                setUserDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={userDetails}
            >
              <Box direction='row-responsive' gap='medium' margin={{ bottom: 'medium', top: 'none'}}>

                <FormField
                  label='System Role'
                  name='role'
                  required
                >
                  <Select
                    id='role'
                    name='role'
                    labelKey='title'
                    placeholder='System Role'
                    options={roleValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>
                {userId === 'new' &&
                  <FormField
                    label='Email'
                    name='email'
                    required
                  >
                    <TextInput
                      name='email'
                      type='email'
                    />
                  </FormField>}


              </Box>

              {userId !== 'new' &&
                <Box>

                  <Box direction='row-responsive' gap='medium'>
                    <FormField
                      label='Firstname'
                      name='firstName'
                      required
                    >
                      <TextInput
                        name='firstName'
                        type='text'
                      />
                    </FormField>

                    <FormField
                      label='Lastname'
                      name='lastName'
                      required
                    >
                      <TextInput
                        name='lastName'
                        type='text'
                      />
                    </FormField>
                  </Box>

                  <Box direction='row-responsive' gap='medium'>
                    <FormField
                      label='Email'
                      name='email'
                      required
                    >
                      <TextInput
                        name='email'
                        type='email'
                      />
                    </FormField>

                    <FormField
                      label='Job Title'
                      name='jobTitle'
                      required
                    >
                      <TextInput
                        name='jobTitle'
                        type='text'
                      />
                    </FormField>
                  </Box>
                </Box>}




              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label={(userId === 'new' ? 'Send Invite' : 'Save')} primary disabled={saveLocked} />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserEdit
