import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'
import OrganisationService from '../services/OrganisationService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import DataTable from '../components/simple/dataTable/DataTable.js'
import Text from '../components/simple/text/Text'
import H1 from '../components/simple/heading/H1'

import NotificationLayer from '../components/compound/notification/NotificationLayer'

function OrganisationList () {
  const [showDeleteOptions, showDelete] = useState(false)
  const [orgToDelete, setOrgToDelete] = useState(null)
  const [orgValues, setOrgValues] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  // const { error, showError } = useErrorHandler(null)

  // Prompt user to delete
  const showDeleteOrgOptions = (org) => {
    setOrgToDelete(org)
    showDelete(true)
  }

  // Delete an Organisation
  const deleteOrg = async () => {
    // Delete from server
    const response = await OrganisationService.deleteOrg(apiToken, orgToDelete)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newOrgValues = orgValues.filter((item) => item.id !== orgToDelete.id)
      setOrgValues(newOrgValues)
      NotificationService.info('Organisation deleted')
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get organisations
    const getOrganisations = async () => {
      const apiToken = LocalStorageService.get('apiToken')

      setLoading(true)
      const organisations = await OrganisationService.getOrganisations(apiToken)
      if (organisations.error) {
        NotificationService.error(organisations.error)
      } else {
        if (!unmounted) {
          // if there's no organisations, do nothing
          if (!organisations || !organisations.data) {
            return
          }

          const mappedOrganisations = organisations.data.map((data, index) => ({
            id: data.id,
            name: data.name,
            createdAt: data.createdAt
          }))

          setOrgValues(mappedOrganisations)
        }
      }
    }

    getOrganisations()

    setLoading(false)

    return () => { unmounted = true }
  }, [])

  const dataColumns = [
    {
      property: 'name',
      header: <Text>Name</Text>,
      primary: true
    },
    {
      property: 'createdAt',
      header: <Text>Date Created</Text>
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          <Button href={`service/${datum.id}/edit`} label={<Text><FontAwesomeIcon icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
          <Button label={<Text><FontAwesomeIcon icon={['fal', 'trash']} /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => showDeleteOrgOptions(datum)} target='_self' />
        </Box>
      )
    }
  ]

  return (
    <>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Service </H1>
          {/* {error &&
            <FlashMessage message={error} />} */}
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteOrg} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete organisation {orgToDelete.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box direction='row-responsive' gap='xlarge' justify='between' pad={{ horizontal: 'medium', top: 'medium' }}>
          <Box>
            <Text color='#000' size='large' weight='bold'>Services</Text>
          </Box>
          <Box direction='row-responsive' gap='xxsmall'>
            <Button href='service/new/edit' label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add Service</Text>} primary target='_self' />
          </Box>
        </Box>

        <Box direction='column' gap='medium' pad={{ horizontal: 'medium', bottom: 'small' }}>
          <Box align='center'>
            <PulseLoader
              size={15}
              color='orange'
              loading={loading}
            />
          </Box>
          <DataTable
            columns={dataColumns}
            data={orgValues}
          />
        </Box>
      </Box>
    </>
  )
}

export default OrganisationList
