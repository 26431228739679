import React, { useEffect, useState } from 'react'

// Appt Components
import Select from '../../../components/simple/input/Select'

const OperatorBox = (props) => {
  const [operatorOptions, setOperatorOptions] = useState([])

  const autoCompleteQueries = ['is', 'is not']
  const booleanQueries = ['is', 'is not']
  const dateQueries = ['on', 'after', 'before']
  const integerQueries = ['equal to', 'not equal to', 'greater than', 'less than', 'greater than or equal', 'less than or equal']
  const listQueries = ['is', 'is not']
  const timeQueries = ['is', 'after', 'before'] // ['on', 'after', 'before']

  useEffect(() => {
    let unmounted = false

    // Set operator options based on field datatype
    const setOperator = async () => {
      if (props.operators) {
        setOperatorOptions(props.operators)
        return
      }

      switch (props.type) {
        case 'autoComplete':
          setOperatorOptions(autoCompleteQueries)
          break
        case 'boolean':
        case 'yesno':
          setOperatorOptions(booleanQueries)
          break
        case 'date':
          setOperatorOptions(dateQueries)
          break
        case 'integer':
          setOperatorOptions(integerQueries)
          break
        case 'list':
          setOperatorOptions(listQueries)
          break
        case 'time':
          setOperatorOptions(timeQueries)
          break
        default:
          setOperatorOptions(autoCompleteQueries)
      }
    }

    setOperator()

    return () => { unmounted = true }
  }, [props.type, props.operators])

  useEffect(() => {
    props.setDefaultOperatorValue(operatorOptions[0], props.index)
  }, [operatorOptions])
  return (
    <>
      <Select
        // defaultValue={operatorOptions[0]}
        options={operatorOptions}
        // value={props.value || operatorOptions[0]}
        // value={operatorOptions[0]}
        {...props}
        // id='student'
        //   disabled={props.userId !== 'new'}
        //   name={questions[0].key}
        // labelKey='questionText'

        // valueKey={{ key: 'id', reduce: true }}
      />
    </>
  )
}

export default OperatorBox
