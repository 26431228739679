import React, { useEffect, useState } from 'react'

// Spinner
// import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import RadioButtonGroup from '../../components/simple/input/RadioButtonGroup'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text.js'
import TextArea from '../../components/simple/input/TextArea.js'

function BehaviourTechniques (props) {
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState(props.questions)
  const [deescalationTechniques, setDeescalationTechniques] = useState()
  const [physicalTechniques, setPhysicalTechniques] = useState()

  const [localValues, setLocalValues] = useState(props.behaviourPlanValues)
  useEffect(() => {
    setLocalValues(props.behaviourPlanValues)
  }, [props.behaviourPlanValues])

  const submitForm = () => {
    // props.setBehaviourPlanValues(localValues)
    props.submitForm(true, localValues)
  }
  const previousPage = () => {
    props.previousPage(localValues)
  }

  const nextPage = () => {
    props.nextPage(localValues)
  }

  const deleteDraft = () => {
    props.deleteDraft()
  }

  const buildForm = () => {
    const techniques = ['deescalationTechniques', 'physicalTechniques']

    let filteredQuestions = null

    // Build array of technique questions
    techniques.forEach((tech) => {
      const questionList = []

      filteredQuestions = questions.filter(function (el) {
        return el.type === tech
      })

      // Loop round the two technique types
      // to get the questions for each type
      filteredQuestions.forEach((item, index) => {
        questionList.push(
          <>
            <FormField
              key={index}
              label={item.questionText}
              name={item.key}
            >
              <RadioButtonGroup
                direction='row'
                id={item.key}
                name={item.key}
                // onChange={event =>
                //   setActionButton(event.target.value)}
                options={['Try', 'Avoid']}
              >
                {(option, { checked, hover }) => {
                  let background
                  if (checked) background = 'brand'
                  else if (hover) background = 'secondary'
                  else background = 'light-4'
                  return (
                    <Box background={background} pad='small'>{option}</Box>
                  )
                }}
              </RadioButtonGroup>
            </FormField>

            <FormField
              label='Notes'
              name={`${item.key}-notes`}
            >
              <TextArea
                name={`${item.key}-notes`}
              />
            </FormField>
          </>
        )
      })
      if (tech === 'deescalationTechniques') {
        setDeescalationTechniques(questionList)
      } else {
        setPhysicalTechniques(questionList)
      }
    })

    // return questionList
    // }
  }

  useEffect(() => {
    const techniques = buildForm()
    console.log('techx', techniques)
  }, [])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <H2 margin={{ vertical: 'none' }}>Behaviour Management Techniques</H2>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            setLocalValues(nextValue)
          }}
          onSubmit={() => {
            submitForm(localValues)
          }}
          value={localValues}
        >
          <Box>
            <Text size='large' weight='bold'>Deescalation Techniques</Text>
            <Grid
              rows={['auto', 'auto']}
              columns={['auto', 'auto']}
              gap='small'
              areas={[
                { name: 'main', start: [0, 0], end: [1, 1] }
              ]}
            >
              {deescalationTechniques}
            </Grid>
          </Box>

          <Box>
            <Text size='large' weight='bold'>Physical Techniques</Text>
            <Grid
              rows={['auto', 'auto']}
              columns={['auto', 'auto']}
              gap='small'
              areas={[
                { name: 'main', start: [0, 0], end: [1, 1] }
              ]}
            >
              {physicalTechniques}
            </Grid>
          </Box>
          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

          <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            <Box direction='row' gap='small'>
              <Button icon={<FontAwesome icon={['fal', 'save']} color='#fff' />} label='Save for later' onClick={() => submitForm()} />
              <Button icon={<FontAwesome icon={['fal', 'trash']} color='#fff' />} label='Delete Draft' onClick={() => deleteDraft()} />
            </Box>
            <Button label='Next Page' onClick={() => nextPage()} primary />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}
export default BehaviourTechniques
