import React, { useEffect } from 'react'

// Appt Components
import Box from '../components/simple/box/Box'
import qs from 'qs'
import NotificationService from '../services/NotificationService'
import ApiService from '../services/ApiService'
import { useHistory } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader';

function MyLogin (props) {
  const history = useHistory()

  useEffect(() => {
    (async () => {
      const code = qs.parse(window.location.href.split('?')[1], {
        ignoreQueryPrefix: true,
      }).code;
      const loginStatus = await ApiService.myLoginLogin(code);

      // stop if the login failed
      if (loginStatus.error) {
        history.push('/');
        NotificationService.error(loginStatus.error);
      } else {
        props.handleLogin(loginStatus.data);
        history.push('/dashboard');
      }
    })();
  }, []);

  return (
    <>
      <Box
        direction="column"
        gap="medium"
        pad={{ horizontal: 'medium', bottom: 'small' }}
      >
        <Box align="center">
          <PulseLoader size={15} color="orange" loading={true} />
        </Box>
      </Box>
    </>
  );
}

export default MyLogin
