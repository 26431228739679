import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ReportService from '../../services/ReportService'
import LocalStorageService from '../../services/LocalStorageService'
import UserTagService from '../../services/UserTagService'

import moment from 'moment'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FieldBox from '../search/shared/FieldBox.js'
import FormField from '../../components/simple/formField/FormField.js'
import BehaviourDraftResults from './BehaviourDraft-Results.js'
import JoinBox from '../search/shared/JoinBox.js'
import OperatorBox from '../search/shared/OperatorBox.js'
import Text from '../../components/simple/text/Text'
import ValueBox from '../search/shared/ValueBox.js'
import BreadcrumbBar from '../shared/BreadcrumbBar'

// Shared Components


function BehaviourtDrafts (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')
  const [queryResults, setQueryResults] = useState()


  const buildQuery = async () => {
    await getResults([])
  }

  const getResults = async (queryArray) => {
    if (queryArray) {
      const body = {
        submissionType: 'behaviourplan',
        query: queryArray,
        reports: ['behaviourDrafts']
      }

      const params = {
        orgId: activeOrg.id
      }

      const results = await ReportService.getReport(apiToken, params, body)

      setQueryResults(results.behaviourDrafts)

      return results.behaviourDrafts
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get first 20 Published incidentreports on load
    buildQuery()

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Incomplete Smart Plans
      </BreadcrumbBar>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad={{ horizontal: 'small', bottom: 'medium' }}
          round='small'
        >
          <Box direction='column' gap='small'>
            <Box direction='column' gap='xsmall' margin={{ top: 'small' }}>
              <Text margin={{ top: 'small', bottom: 'small' }} size='xlarge'>Results</Text>

              {queryResults?.length > 0 &&
                <BehaviourDraftResults
                  results={queryResults}
                  terms={props.terms}
                />}
              {!queryResults?.length > 0 && <Text color='#666'>- No results to display -</Text>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BehaviourtDrafts
