import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import FormService from '../../services/FormService';
import LocalStorageService from '../../services/LocalStorageService';
import NotificationService from '../../services/NotificationService';
import OrganisationTagService from '../../services/OrganisationTagService';
import UserService from '../../services/UserService';
import UserTagService from '../../services/UserTagService';
import TechniqueService from '../../services/TechniqueService';

// Spinner
import PulseLoader from 'react-spinners/PulseLoader';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

// utils
import { capitalize, removeSpaces, commaSeparate } from '../../utils/text';
import _ from 'lodash';

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor';
import Box from '../../components/simple/box/Box';
import Button from '../../components/simple/button/Button';
import Divider from '../../components/simple/divider/Divider';
import Meter from '../../components/simple/meter/Meter';
import NotificationLayer from '../../components/compound/notification/NotificationLayer';
import Tabs from '../../components/simple/tabs/Tabs.js';
import Text from '../../components/simple/text/Text';

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar';

import BehaviourDetails from './BehaviourPlan-BehaviourDetails';
import BehaviourTechniques from './BehaviourPlan-BehaviourTechniques';
import FollowUp from './BehaviourPlan-FollowUp';
// import PhysicalTechniques from './IncidentEdit-PhysicalTechniques'
// import PostIncidentLearning from './IncidentEdit-PostIncidentLearning'
import ReportSend from './BehaviourPlan-ReportSend';
import RiskAssessment from './BehaviourPlan-RiskAssessment';

function BehaviourPlan(props) {
  const history = useHistory();

  const specialKeys = [
    'lowLevelBehaviour-select',
    'lowLevelTried-select',
    'lowLevelOtherThings-select',
    'lowLevelHelpMyself-select',
    'mediumLevelBehaviour-select',
    'mediumLevelTried-select',
    'mediumLevelOtherThings-select',
    'mediumLevelHelpMyself-select',
    'highLevelBehaviour-select',
    'highLevelTried-select',
    'highLevelOtherThings-select',
    'highLevelHelpMyself-select',
  ];

  const [loading, setLoading] = useState(false);
  const [userValues, setUserValues] = useState([]);
  const [studentValues, setStudentValues] = useState([]);
  const [concernSubmissions, setConcernSubmissions] = useState([]);
  const [concerns, setConcerns] = useState([]);

  const [isDraft, setIsDraft] = useState(false);

  const [showSubmitOptions, setShowSubmitOptions] = useState(false);

  const [behaviourPlanValues, setBehaviourPlanValues] = useState({
    // Risk Assessment
    studentName: '',
    dateOfPlan: '',
    reviewDate: '',
    completedBy: '',
    riskNotes: '',
    additionalAdjustments: '',
    triggers: '',
    triggerSupport: '',
    // Behaviour Details
    lowLevelBehaviour: '',
    lowLevelTried: '',
    lowLevelOtherThings: '',
    lowLevelHelpMyself: '',
    mediumLevelBehaviour: '',
    mediumLevelTried: '',
    mediumLevelOtherThings: '',
    mediumLevelHelpMyself: '',
    highLevelBehaviour: '',
    highLevelTried: '',
    highLevelOtherThings: '',
    highLevelHelpMyself: '',
    doDifferentNextTime: '',
    // FollowUp
    recoveryIShow: '',
    helpMyself: '',
    staffHelp: '',
    recoveryStrategy: '',
    interests: '',
    factors: '',
    otherInformation: '',
    // Behaviour Management Techniques
    calmTalking: '',
    calmTalkingNotes: '',
    optionsOffered: '',
    optionsOfferedNotes: '',
    plannedIgnoring: '',
    plannedIgnoringNotes: '',
    successReminded: '',
    successRemindedNotes: '',
    contingentTouch: '',
    contingentTouchNotes: '',
    listening: '',
    listeningNotes: '',
    negotiation: '',
    negotiationNotes: '',
    takeUpTime: '',
    takeUpTimeNotes: '',
    empathy: '',
    empathyNotes: '',
  });

  const [notifications, setNotifications] = useState([]);

  const [userDetails, setUserDetails] = useState({
    organisation: 0,
    role: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    jobtitle: '',
  });

  const [keyContacts, setKeyContacts] = useState([]);

  const [meterValue, setMeterValue] = useState();

  const [responses, setResponses] = useState();

  const [techniqueNoteKeys, setTechniqueNoteKeys] = useState([]);
  const [techniqueKeys, setTechniqueKeys] = useState([]);

  // This is the tabId for the techniques
  const techniqueTabId = 3;

  const [questions, setQuestions] = useState([
    // Risk Assessment
    {
      tabId: 0,
      questions: [
        {
          questionText: `${capitalize(
            props?.terms?.serviceUser || 'Service User',
          )} Name`,
          key: 'studentName',
        },
        {
          questionText: 'Date of Plan',
          key: 'dateOfPlan',
        },
        {
          questionText: 'Review Date of Plan',
          key: 'reviewDate',
        },
        {
          questionText: 'Completed By',
          key: 'completedBy',
        },
        {
          questionText: 'Notes',
          key: 'riskNotes',
        },
        {
          questionText: 'What should be done additionally?',
          key: 'additionalAdjustments',
        },
        {
          questionText: 'Triggers',
          key: 'triggers',
        },
        {
          questionText: 'Support for Triggers',
          key: 'triggerSupport',
        },
      ],
    },
    // Behaviour Details
    {
      tabId: 1,
      questions: [
        {
          questionText: 'Low Level Behaviour I show',
          key: 'lowLevelBehaviour',
        },
        {
          questionText: "What's been tried?",
          key: 'lowLevelTried',
        },
        {
          questionText: 'Other things that could be tried',
          key: 'lowLevelOtherThings',
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'lowLevelHelpMyself',
        },
        {
          questionText: 'Medium Level Behaviour I show',
          key: 'mediumLevelBehaviour',
        },
        {
          questionText: "What's been tried?",
          key: 'mediumLevelTried',
        },
        {
          questionText: 'Other things that could be tried',
          key: 'mediumLevelOtherThings',
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'mediumLevelHelpMyself',
        },
        {
          questionText: 'High Level Behaviour I show',
          key: 'highLevelBehaviour',
        },
        {
          questionText: "What's been tried?",
          key: 'highLevelTried',
        },
        {
          questionText: 'Other things that could be tried',
          key: 'highLevelOtherThings',
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'highLevelHelpMyself',
        },
        {
          questionText: 'What will I do differently next time?',
          key: 'doDifferentNextTime',
        },
      ],
    },
    // Follow Up
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Recovery Behaviour I sometimes show',
          key: 'recoveryIShow',
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'helpMyself',
        },
        {
          questionText: 'What can staff do to help me?',
          key: 'staffHelp',
        },
        {
          questionText: 'What interests me?',
          key: 'interests',
        },
        {
          questionText: 'Are there any factors to consider when debriefing?',
          key: 'factors',
        },
        {
          questionText: 'Any other information',
          key: 'otherInformation',
        },
        {
          questionText: 'Other things that could be tried',
          key: 'recoveryStrategy',
        },
      ],
    },
    // Behaviour Management Techniques
    {
      tabId: 3,
      questions: [
        // {
        //   questionText: '#1: C.A.L.M talking',
        //   key: 'calmTalking',
        //   type: 'deescalationTechnique'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'calmTalkingNotes'
        // },
        // {
        //   questionText: '#2: Options Offered',
        //   key: 'optionsOffered'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'optionsOfferedNotes'
        // },
        // {
        //   questionText: '#3: Planned Ignoring',
        //   key: 'plannedIgnoring'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'plannedIgnoringNotes'
        // },
        // {
        //   questionText: '#4: Success Reminded',
        //   key: 'successReminded'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'successRemindedNotes'
        // },
        // {
        //   questionText: '#5: Contingent touch',
        //   key: 'contingentTouch'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'contingentTouchNotes'
        // },
        // {
        //   questionText: '#1: Listening',
        //   key: 'listening'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'listeningNotes'
        // },
        // {
        //   questionText: '#2: Negotiation',
        //   key: 'negotiation'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'negotiationNotes'
        // },
        // {
        //   questionText: '#3: Negotiation???',
        //   key: 'negotiation?'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'negotiationNotes'
        // },
        // {
        //   questionText: '#4: Take up time',
        //   key: 'takeUpTime'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'takeUpTimeNotes'
        // },
        // {
        //   questionText: '#5: Empathy',
        //   key: 'empathy'
        // },
        // {
        //   questionText: 'Notes',
        //   key: 'empathyNotes'
        // }
      ],
    },
    {
      tabId: 4,
      questions: [
        {
          questionText:
            'Which key contacts should receive a copy of this report?',
          key: 'notify',
        },
      ],
    },
  ]);

  const apiToken = LocalStorageService.get('apiToken');
  const activeOrg = LocalStorageService.get('activeOrg');
  const activeUser = LocalStorageService.get('userDetails');

  const params = useParams();

  const userId = params.userid;

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0);
  const onActiveTab = nextIndex => {
    setMeterValue(((nextIndex + 1) * 100) / questions.length);
    setTabIndex(nextIndex);
  };

  const getContacts = async () => {
    const params = {
      fields: 'user,type,key,organisation,value',
    };

    const where = {
      user: behaviourPlanValues.studentName,
    };

    let contacts = await UserTagService.get(apiToken, params, where);

    if (contacts?.length) {
      // Filter out the 'keycontacts'
      contacts = contacts.filter(item => item.key === 'keycontacts');
    }

    if (contacts?.length) {
      const parsedContacts = JSON.parse(contacts[0].value);
      const mappedContacts = parsedContacts.map((contact, index) => ({
        display: (
          <>
            <Box direction="column">
              <Text weight="bold">
                {contact.firstName} {contact.lastName}
              </Text>
              <Text>{contact.role}</Text>
            </Box>
          </>
        ),
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        name: contact.firstName + ' ' + contact.lastName,
        role: contact.role,
      }));

      setKeyContacts(mappedContacts);
    }
  };

  const getTechniques = async techniqueType => {
    const params = {
      fields: 'value,key',
      limit: 2,
    };

    const where = {
      organisation: activeOrg.id,
      type: 'besmart',
      key: techniqueType,
    };

    const techniques = await OrganisationTagService.get(
      apiToken,
      params,
      where,
    );

    return techniques;
  };

  const getTechniqueQuestions = async () => {
    const techniqueType = ['deescalationTechniques', 'physicalTechniques'];

    const techniqueQuestions = {
      tabId: techniqueTabId,
      questions: [],
    };

    let techniques = null;

    var rankings = await getRankings();

    var techniqueKeysToSave = [];
    var techniqueNoteKeysToSave = [];

    techniqueType.forEach(async techniqueType => {
      techniques = await getTechniques(techniqueType);
      // Get organisation's deescalation/physical techniques
      // and add them to the relevant tab of questions

      var techniqueRankings = rankings.physical;
      if (techniqueType === 'deescalationTechniques') {
        techniqueRankings = rankings.deescalation;
      }

      if (techniques) {
        const parsedTechniques = JSON.parse(techniques[0].value);

        let mappedTechnique = {};

        for (const technique of parsedTechniques) {
          // If technique is marked for selection
          // then convert it into the correct format
          // and save to new array
          if (technique.selected) {
            mappedTechnique = {};
            mappedTechnique.questionText = technique.technique;
            mappedTechnique.key = removeSpaces(
              technique.technique.toLowerCase(),
            );
            mappedTechnique.responseType = 'number';
            mappedTechnique.type = techniqueType;
            techniqueQuestions.questions.push(mappedTechnique);

            // calculate the ranking
            mappedTechnique.ranking = 0;
            if (techniqueRankings?.[mappedTechnique.key]?.average) {
              mappedTechnique.ranking =
                techniqueRankings[mappedTechnique.key]?.average;
            }
            // display the ranking if there is one
            if (mappedTechnique.ranking > 0) {
              mappedTechnique.questionText += ` (Effectiveness: ${mappedTechnique.ranking}/10)`;
            }

            // sort by ranking
            techniqueQuestions.questions = techniqueQuestions.questions.sort(
              (x, y) => y.ranking - x.ranking,
            );

            // Add the Notes question
            techniqueQuestions.questions.push({
              questionText: 'Notes',
              key: mappedTechnique.key + '-notes',
            });

            techniqueKeysToSave.push(mappedTechnique.key);
            techniqueNoteKeysToSave.push(mappedTechnique.key + '-notes');
          }
        }
      }
    });

    const newQuestions = questions;
    newQuestions[techniqueTabId] = techniqueQuestions;
    setQuestions(newQuestions);
    setTechniqueKeys(techniqueKeysToSave);

    setTechniqueNoteKeys(techniqueNoteKeysToSave);
  };

  // Check if the passed in input field has any value
  const checkInputHasContent = input => {
    let inputHasContent = false;

    switch (typeof input) {
      case 'string':
        inputHasContent = input.length > 0;
        break;
      case 'number':
        inputHasContent = input > 0;
        break;
      case 'boolean':
        break;
      case 'object':
        inputHasContent = input.length > 0;
        break;
    }

    return inputHasContent;
  };

  // Submit form values
  const submitForm = async (draft, formValues) => {
    // Check if we've confirmed to notify key contacts
    if (showSubmitOptions) {
      setShowSubmitOptions(false);
    } else {
      // If we have key contact notifications
      // then prompt whether to notify them
      if (notifications.length > 0) {
        setShowSubmitOptions(true);
        return;
      }
    }

    var submitArray = [];
    var item = {
      key: '',
      questionText: '',
      answer: '',
    };

    var planValuesToUse = formValues ? formValues : behaviourPlanValues;

    // Loop through each form value
    for (var key in planValuesToUse) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(
          question => question.key === key,
        );

        if (found && checkInputHasContent(planValuesToUse[key])) {
          item = {
            questionKey: key,
            questionText: found.questionText,
            response: planValuesToUse[key],
          };
          submitArray.push(item);
        }
      });
    }

    // Store our special keys
    specialKeys.map(key => {
      try {
        submitArray.push({
          questionKey: key,
          questionText: key,
          response: JSON.stringify(planValuesToUse[key]),
        });
      } catch (err) {}
    });

    // Add notifications
    // if (formDetailsToSave?.notify?.length > 0) {
    if (notifications.length > 0) {
      var notifyArray = [];

      let contactFound = {};

      // Build array of notifications
      // notifications.forEach(notification => {
      notifications.forEach(notification => {
        contactFound = keyContacts.find(
          contact => contact.email === notification,
        );
        notifyArray.push({
          firstName: contactFound.firstName,
          lastName: contactFound.lastName,
          email: contactFound.email,
        });
      });
    }

    // Build output data
    const data = {
      // user: planValuesToUse.studentName,
      user: userId,
      organisation: activeOrg.id,
      status: draft ? 'Draft' : 'Published',
      type: 'behaviourplan',
      reference: '',
      notify: JSON.stringify(notifyArray),
      responses: submitArray,
      author: activeUser.id,
    };

    var submissionAdded = await FormService.makeSubmissions(apiToken, data);
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error);
    } else {
      NotificationService.info('Behaviour Plan added');
      history.push('/serviceusers');
    }
  };

  const deleteDraft = async () => {
    // check for a draft
    try {
      const params = {
        fields: 'id,user,organisation,reference,type,author,status',
        limit: 1,
        sort: 'createdAt DESC',
      };
      const where = {
        type: 'behaviourplan',
        user: userId,
      };
      const lastSubmission = await FormService.getSubmissions(
        apiToken,
        params,
        where,
      );

      if (lastSubmission?.data?.[0]?.status !== 'Draft') {
        return;
      }

      if (
        window.confirm(
          "Are you sure you want to delete this draft? This will erase any changes you've made.",
        )
      ) {
        await FormService.updateSubmission(
          apiToken,
          lastSubmission.data[0].id,
          { status: 'Archived' },
        );
        window.location.reload(); // this is not ideal
      }
    } catch (err) {
      console.error(err);
    }
  };
  // Go Back
  const goBack = () => {
    history.goBack();
  };

  const previousPage = formValues => {
    if (formValues) {
      setBehaviourPlanValues(formValues);
    }
    if (tabIndex === 0) {
      goBack();
    } else {
      const prevPageIndex = tabIndex - 1;
      onActiveTab(prevPageIndex);
    }
  };

  const nextPage = formValues => {
    if (formValues) {
      setBehaviourPlanValues(formValues);
    }
    if (tabIndex === tabContent.length - 1) {
      submitForm();
    } else {
      const nextPageIndex = tabIndex + 1;
      onActiveTab(nextPageIndex);
    }
  };

  const getUsers = async (type = 'user') => {
    const params = {
      fields: 'id,firstName,lastName,reference,type',
      limit: 1000,
      orgId: activeOrg.id,
      type,
    };

    setLoading(true);
    const users = await UserService.getUsers(apiToken, params);
    if (!users || users?.error) {
      // NotificationService.error(users.error)
    } else {
      if (users?.error) {
        setUserValues(null);
      } else if (users?.data) {
        if (type === 'user') {
          // Get system users
          var mappedUsers = users.data.map((data, index) => ({
            id: data.id,
            // learnerNumber: data.reference,
            name: data.firstName + ' ' + data.lastName,
          }));
          mappedUsers = mappedUsers.sort((a, b) =>
            a.name.localeCompare(b.name),
          );

          setUserValues(mappedUsers);
        } else if (type === 'student') {
          // console.log(mappedUsers)
          // Now get students
          var studentUsers = users.data.map((data, index) => ({
            id: data.id,
            learnerNumber: data.reference,
            name: data.firstName + ' ' + data.lastName,
          }));
          studentUsers = studentUsers.sort((a, b) =>
            a.name.localeCompare(b.name),
          );

          setStudentValues(studentUsers);
        }
      }
    }
  };

  const getUserDetails = async (userId, type = 'user') => {
    const params = {
      fields:
        'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
      limit: 1,
      orgId: activeOrg.id,
      type,
    };

    const userDetails = await UserService.getUser(apiToken, params, userId);
    if (userDetails?.error) {
      NotificationService.error(userDetails.error);
    } else {
      if (userDetails?.data) {
        setUserDetails(userDetails.data[0]);
      }
    }
  };

  const getConcerns = async userId => {
    const params = {
      orgId: activeOrg.id,
      fields: 'id',
      limit: 2000,
      sort: 'id DESC',
    };

    const where = {
      type: 'concern',
      status: 'Published',
      subject: userId,
    };

    const concernSubmissions = await FormService.getSubmissions(
      apiToken,
      params,
      where,
    );

    if (concernSubmissions && concernSubmissions.data) {
      const submissionIds = concernSubmissions.data.map(
        (submission, index) => submission.id,
      );

      setConcernSubmissions(submissionIds);
    }
  };

  // Get all Published Submissions for this User
  const getCumulativeBehaviourPlanFields = async userId => {
    // is there a last draft? If so, use that instead

    var submissions = {};

    try {
      const params = {
        fields: 'id,user,organisation,reference,type,author,status',
        limit: 1,
        sort: 'createdAt DESC',
      };
      var where = {
        type: 'behaviourplan',
        user: userId,
      };

      const lastSubmission = await FormService.getSubmissions(
        apiToken,
        params,
        where,
      );

      if (lastSubmission?.data?.[0]?.status === 'Draft') {
        if (lastSubmission?.data?.[0]?.author === activeUser.id) {
          submissions = lastSubmission;
          setIsDraft(true);
        }
      }
    } catch (err) {
      console.error(err);
    }

    if (!submissions?.data?.[0]?.id) {
      const params = {
        fields: 'id,user,organisation,reference,type',
        limit: 1,
        sort: 'createdAt DESC',
      };

      where = {
        status: 'Published',
        type: 'behaviourplan',
        user: userId,
      };
      submissions = await FormService.getSubmissions(apiToken, params, where);
    }

    if (submissions?.error) {
      // NotificationService.error(submissions.error)
    } else {
      // Now get all Responses for each of the Submissions
      const submissionIds = submissions.data.map(item => {
        return item.id;
      });
      if (submissions?.data) {
        const params = {
          fields: 'questionKey,questionText,response,responseType',
          limit: 1000,
        };

        var where = {
          user: userId,
          // submission: submissions.data[0].id
          submission: submissionIds,
        };

        const responses = await FormService.getResponses(
          apiToken,
          params,
          where,
        );
        if (responses?.error) {
          // NotificationService.error(responses.error)
        } else {
          const behaviourResponses = {};
          let followUpString = '';

          // Copy Incident Report responses
          // into the Behaviour Plan

          const keysToImport = [
            'riskNotes',
            'dateOfPlan',
            'reviewDate',
            'completedBy',
            'riskNotes',
            // 'additionalAdjustments',
            // 'triggers',
            'triggerSupport',
            // 'lowLevelBehaviour',
            // 'lowLevelTried',
            // 'lowLevelOtherThings',
            'lowLevelHelpMyself',
            // 'mediumLevelBehaviour',
            // 'mediumLevelTried',
            // 'mediumLevelOtherThings',
            'mediumLevelHelpMyself',
            // 'highLevelBehaviour',
            // 'highLevelTried',
            // 'highLevelOtherThings',
            'highLevelHelpMyself',
            'doDifferentNextTime',
            'recoveryIShow',
            'helpMyself',
            'staffHelp',
            'interests',
            'factors',
            'otherInformation',
            'recoveryStrategy',
          ];

          const keysToCheck = [].concat(
            keysToImport,
            techniqueNoteKeys,
            techniqueKeys,
          );
          responses.data.forEach(item => {
            if (
              keysToCheck.includes(item.questionKey) ||
              item.questionKey.includes('-notes')
            ) {
              if (behaviourResponses?.[item.questionKey]) {
                behaviourResponses[item.questionKey] =
                  behaviourResponses[item.questionKey] + ', ' + item.response;
              } else {
                behaviourResponses[item.questionKey] = item.response;
              }
            }
          });

          setBehaviourPlanValues(behaviourValues => ({
            ...behaviourValues,
            ...behaviourResponses,
          }));
        }
      }
    }
  };

  const getBehaviourDetailsSelectData = async userId => {
    let params = {
      fields: 'id,user,organisation,reference,type,author,status',
      limit: 1,
      sort: 'createdAt DESC',
    };
    let where = {
      type: 'behaviourplan',
      user: userId,
    };
    const lastSubmission = await FormService.getSubmissions(
      apiToken,
      params,
      where,
    );

    // No data to get
    if (!lastSubmission?.data?.length) {
      return;
    }

    params = {
      fields: 'questionKey,questionText,response,responseType',
      limit: 1000,
      sort: 'createdAt DESC',
    };

    where = {
      user: userId,
      submission: lastSubmission.data[0].id,
      author: activeUser.id,
    };
    const behaviourResponses = {};
    const responses = await FormService.getResponses(apiToken, params, where);
    responses.data.forEach(item => {
      var keyToMapTo = item.questionKey;

      if (specialKeys.includes(keyToMapTo)) {
        try {
          behaviourResponses[keyToMapTo] = JSON.parse(item.response);
        } catch (err) {}
      }
    });

    const mergeArraysByValue = (arr1, arr2) => {
      // Create a map to store objects indexed by the 'value' parameter
      const map = new Map();

      // Add objects from the first array to the map
      if (arr1 && Array.isArray(arr1)) {
        arr1.forEach(obj => {
          map.set(obj.value, obj);
        });
      }

      // Add or update objects from the second array to the map
      if (arr2 && Array.isArray(arr2)) {
        arr2.forEach(obj => {
          map.set(obj.value, obj);
        });
      }
      // Convert the map back to an array
      const mergedArray = Array.from(map.values());

      return mergedArray;
    };

    setBehaviourPlanValues(behaviourValues => {
      let theData = {
        ...behaviourValues,
      };

      for (let key of specialKeys) {
        let existing = theData[key];
        let newData = behaviourResponses[key];
        if (existing) {
          theData[key] = {
            extra: existing?.extra?.length
              ? mergeArraysByValue(existing?.extra, newData?.extra)
              : newData?.extra,
            original: existing?.original?.length
              ? mergeArraysByValue(existing?.original, newData?.original)
              : newData?.original,
          };
        }
      }

      return theData;
    });
  };

  const getUserSubmissions = async userId => {
    let params = {
      fields: 'id,user,organisation,reference,type',
      limit: 1000,
      sort: 'createdAt DESC',
    };

    let where = {
      status: 'Published',
      type: 'incidentreport',
      user: userId,
    };

    const restructureData = data => {
      try {
        let jsonData = data;

        if (!jsonData) jsonData = [];

        // This is json data we want in the correct format
        if (
          Object(jsonData).hasOwnProperty('original') &&
          Object(jsonData[0]).hasOwnProperty('extra')
        )
          return jsonData;

        // Else format it into the data we want
        let returnData = {
          original: [],
          extra: [{ checked: false, value: '' }],
        };

        // Filter the duplicates
        const uniqueSet = new Set(
          jsonData.map(item => item.trim().toLowerCase()),
        );
        jsonData = Array.from(uniqueSet);

        // Move the data into the format we want
        for (let d of jsonData) {
          returnData.original.push({ checked: true, value: d });
        }

        return returnData;
      } catch (e) {} // Do nothing

      // No data just return an array
      return [];
    };

    const submissions = await FormService.getSubmissions(
      apiToken,
      params,
      where,
    );
    if (submissions?.error) {
      const behaviourResponses = {};

      // reconstruct all the plain data to the json format we want
      for (let key of specialKeys) {
        behaviourResponses[key] = restructureData(behaviourResponses[key]);
      }

      setBehaviourPlanValues(behaviourValues => ({
        ...behaviourValues,
        ...behaviourResponses,
      }));

      // NotificationService.error(submissions.error)
      console.error(submissions.error);
    } else {
      // Now get all Responses for each of the Submissions
      const submissionIds = submissions.data.map(item => {
        return item.id;
      });
      if (submissions?.data) {
        params = {
          fields: 'questionKey,questionText,response,responseType',
          limit: 1000,
          sort: 'createdAt DESC',
        };

        where = {
          user: userId,
          // submission: submissions.data[0].id
          submission: submissionIds,
          author: activeUser.id,
        };
        const responses = await FormService.getResponses(
          apiToken,
          params,
          where,
        );
        if (responses?.error) {
          NotificationService.error(responses.error);
        } else {
          const behaviourResponses = {};

          const keysToImport = [
            'additionalAdjustments',
            'triggers',
            'triggerSupport',
            'lowLevelBehaviour',
            'lowLevelResponse',
            'lowLevelStrategy',
            'lowLevelHelpMyself',
            'mediumLevelBehaviour',
            'mediumLevelResponse',
            'mediumLevelStrategy',
            'mediumLevelHelpMyself',
            'highLevelBehaviour',
            'highLevelResponse',
            'highLevelStrategy',
            'highLevelHelpMyself',
            'recoveryBehaviour',
            'recoveryResponse',
            'recoveryStrategy',
            'recoveryHelpMyself',
            'adjustments',
            'likelyTriggers',
            'doDifferentNextTime',
            'recoveryIShow',
            // 'helpMyself',
            'staffHelp',
            'interests',
            'factors',
            'otherInformation',
            'recoveryStrategy',
          ];

          const keyTranslation = {
            lowLevelResponse: 'lowLevelTried',
            lowLevelStrategy: 'lowLevelOtherThings',
            mediumLevelResponse: 'mediumLevelTried',
            mediumLevelStrategy: 'mediumLevelOtherThings',
            highLevelResponse: 'highLevelTried',
            highLevelStrategy: 'highLevelOtherThings',
            recoveryBehaviour: 'recoveryIShow',
            recoveryResponse: 'staffHelp',
            adjustments: 'additionalAdjustments',
            likelyTriggers: 'triggers',
          };

          const keysToCheck = [].concat(keysToImport, techniqueNoteKeys);
          responses.data.forEach(item => {
            var keyToMapTo = item.questionKey;
            if (keyTranslation?.[item.questionKey]) {
              keyToMapTo = keyTranslation[item.questionKey];
            }

            // create an array of strings with prefix select-plain
            if (
              [
                'lowLevelBehaviour',
                'lowLevelTried',
                'lowLevelOtherThings',
                'lowLevelHelpMyself',
                'mediumLevelBehaviour',
                'mediumLevelTried',
                'mediumLevelOtherThings',
                'mediumLevelHelpMyself',
                'highLevelBehaviour',
                'highLevelTried',
                'highLevelOtherThings',
                'highLevelHelpMyself',
              ].includes(keyToMapTo)
            ) {
              if (
                behaviourResponses?.[keyToMapTo] &&
                behaviourResponses?.[`${keyToMapTo}-select`]
              ) {
                behaviourResponses[`${keyToMapTo}-select`].push(item.response);
              } else {
                behaviourResponses[`${keyToMapTo}-select`] = [item.response];
              }
            }

            if (keysToCheck.includes(item.questionKey)) {
              if (behaviourResponses?.[keyToMapTo]) {
                behaviourResponses[keyToMapTo] =
                  behaviourResponses[keyToMapTo] + ', ' + item.response;
              } else {
                behaviourResponses[keyToMapTo] = item.response;
              }
            }
          });

          // reconstruct all the plain data to the json format we want
          for (let key of specialKeys) {
            behaviourResponses[key] = restructureData(behaviourResponses[key]);
          }

          setBehaviourPlanValues(behaviourValues => ({
            ...behaviourValues,
            ...behaviourResponses,
          }));
        }
      }
    }
  };

  const getRankings = async () => {
    // get the technique rankings
    const rankings = await TechniqueService.getRankingsForStudent(userId);

    // convert the response to an object using the ranking name as a key
    var newPRankings = {};
    if (rankings?.data?.physical) {
      for (const ranking of rankings.data.physical) {
        const key = removeSpaces(ranking.name.toLowerCase());
        newPRankings[key] = ranking;
      }
    }

    var newDRankings = {};
    if (rankings?.data?.deescalation) {
      for (const ranking of rankings.data.deescalation) {
        const key = removeSpaces(ranking.name.toLowerCase());
        newDRankings[key] = ranking;
      }
    }

    return {
      physical: newPRankings,
      deescalation: newDRankings,
    };
  };

  // This adds to an array of historical Concerns (savedConcerns) for display purposes
  // key = key to be added
  // response = historical Concern
  const buildConcernHistory = (savedConcerns, response, key) => {
    savedConcerns.find((obj, index) => {
      if (obj.submission === response.submission) {
        switch (key) {
          case 'id':
            savedConcerns[index][key] = response.id;
            break;
          case 'recordedBy':
            {
              const userName = userValues.find(
                contact => contact.id === parseInt(response.response),
              );
              savedConcerns[index][key] = userName.name;
            }
            break;
          case 'actionedBy':
            {
              const userName = userValues.find(
                contact => contact.id === parseInt(response.response),
              );
              savedConcerns[index][key] = userName.name;
            }
            break;
          default:
            savedConcerns[index][key] = response.response;
            break;
        }
        savedConcerns[index].dateCol = (
          <Box direction="column">
            <Text>
              {moment(savedConcerns[index].concernDate).format('DD/MM/YY')}{' '}
              {savedConcerns[index].concernTime}
            </Text>
          </Box>
        );
        savedConcerns[index].concernCol = (
          <Box direction="column">
            <Text>{savedConcerns[index].concern}</Text>
          </Box>
        );
        savedConcerns[index].recordedByCol = (
          <Box direction="column">
            <Text>{savedConcerns[index].recordedBy}</Text>
          </Box>
        );
        savedConcerns[index].actionCol = (
          <Box direction="column">
            <Text>{savedConcerns[index].action}</Text>
          </Box>
        );
        savedConcerns[index].actionDateCol = (
          <Box direction="column">
            <Text>
              {moment(savedConcerns[index].actionDate).format('DD/MM/YY')}{' '}
              {savedConcerns[index].actionTime}
            </Text>
          </Box>
        );
        savedConcerns[index].actionedByCol = (
          <Box direction="column">
            <Text>{savedConcerns[index].actionedBy}</Text>
          </Box>
        );
      }
    });
  };

  useEffect(() => {
    (async () => {
      await getUsers('user');
      await getUsers('student');
      await getTechniqueQuestions();
      if (userId !== 'new') {
        await getUserDetails(userId, 'student');

        await getUserSubmissions(userId);

        await getBehaviourDetailsSelectData(userId);

        await getConcerns(userId);

        setBehaviourPlanValues(behaviourValues => ({
          ...behaviourValues,
          studentName: parseInt(userId),
        }));
      }

      setLoading(false);
      // Set initial Meter setting
      setMeterValue(100 / questions.length);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getCumulativeBehaviourPlanFields(userId);
    })();
  }, [techniqueKeys]);

  // useEffect(() => {
  //   if (userId !== 'new') {
  //     getCumulativeBehaviourPlanFields(userId)
  //   }
  // }, [techniqueNoteKeys])

  useEffect(() => {
    if (behaviourPlanValues?.studentName) {
      getContacts();
    }
  }, [behaviourPlanValues?.studentName]);

  useEffect(() => {
    setBehaviourPlanValues(behaviourPlanValues => ({
      ...behaviourPlanValues,
      ...responses,
      studentName: parseInt(userId),
    }));
  }, [responses]);

  useEffect(() => {
    (async () => {
      if (concernSubmissions?.length) {
        let savedConcerns = [];

        concernSubmissions.forEach(submission => {
          // savedConcerns[submission] = {
          //   concernDate: '',
          //   action: ''
          // }
          savedConcerns.push({
            submission: submission,
            concernDate: '',
            action: '',
            id: '',
          });
        });
        const params = {
          fields: 'id,questionKey,response,responseType,submission',
          limit: 1000,
        };

        const where = {
          submission: concernSubmissions,
        };

        const concernResponses = await FormService.getResponses(
          apiToken,
          params,
          where,
        );

        if (concernResponses?.error) {
          NotificationService.error(concernResponses.error);
        } else {
          if (concernResponses?.data.length) {
            concernResponses.data.forEach(response => {
              switch (response.questionKey) {
                case 'addToSmartPlan':
                  buildConcernHistory(
                    savedConcerns,
                    response,
                    'addToSmartPlan',
                  );
                  break;
                case 'concern':
                  buildConcernHistory(savedConcerns, response, 'concern');
                  break;
                case 'concernDate':
                  buildConcernHistory(savedConcerns, response, 'concernDate');
                  break;
                case 'concernTime':
                  buildConcernHistory(savedConcerns, response, 'concernTime');
                  break;
                case 'recordedBy':
                  buildConcernHistory(savedConcerns, response, 'recordedBy');
                  break;
                case 'actionDate':
                  buildConcernHistory(savedConcerns, response, 'actionDate');
                  break;
                case 'actionTime':
                  buildConcernHistory(savedConcerns, response, 'actionTime');
                  break;
                case 'actionedBy':
                  buildConcernHistory(savedConcerns, response, 'actionedBy');
                  break;
                case 'action':
                  buildConcernHistory(savedConcerns, response, 'action');

                  // Add action id to display full screen later
                  buildConcernHistory(savedConcerns, response, 'id');
                  break;
                default:
                  break;
              }
            });

            // Only display the Concerns marked to be added to the Smart Plan
            savedConcerns = savedConcerns.filter(
              item => item?.addToSmartPlan === 'true',
            );
            setConcerns(savedConcerns);
          }
        }
      }
    })();
  }, [concernSubmissions]);

  const tabContent = [
    {
      name: 'Behaviour Plan',
      hideTitle: true,
      content: (
        <RiskAssessment
          deleteDraft={deleteDraft}
          submitForm={submitForm}
          nextPage={nextPage}
          previousPage={previousPage}
          behaviourPlanValues={behaviourPlanValues}
          questions={questions[0].questions}
          setBehaviourPlanValues={setBehaviourPlanValues}
          setStudentValues={setStudentValues}
          studentValues={studentValues}
          userId={userId}
          userValues={userValues}
          setUserValues={setUserValues}
        />
      ),
    },
    {
      name: 'Behaviour Details',
      hideTitle: true,
      content: (
        <BehaviourDetails
          deleteDraft={deleteDraft}
          submitForm={submitForm}
          nextPage={nextPage}
          previousPage={previousPage}
          behaviourPlanValues={behaviourPlanValues}
          questions={questions[1].questions}
          setBehaviourPlanValues={setBehaviourPlanValues}
          specialKeys={specialKeys}
        />
      ),
    },
    {
      name: 'Follow Up Support',
      hideTitle: true,
      content: (
        <FollowUp
          deleteDraft={deleteDraft}
          submitForm={submitForm}
          nextPage={nextPage}
          previousPage={previousPage}
          concerns={concerns}
          behaviourPlanValues={behaviourPlanValues}
          questions={questions[2].questions}
          setBehaviourPlanValues={setBehaviourPlanValues}
        />
      ),
    },
    {
      name: 'Behaviour Management Techniques',
      hideTitle: true,
      content: (
        <BehaviourTechniques
          deleteDraft={deleteDraft}
          submitForm={submitForm}
          nextPage={nextPage}
          previousPage={previousPage}
          behaviourPlanValues={behaviourPlanValues}
          questions={questions[3].questions}
          setBehaviourPlanValues={setBehaviourPlanValues}
        />
      ),
    },
    {
      name: 'Send Report',
      hideTitle: true,
      content: (
        <ReportSend
          deleteDraft={deleteDraft}
          submitForm={submitForm}
          nextPage={nextPage}
          previousPage={previousPage}
          contacts={keyContacts}
          questions={questions[4].questions}
          setNotifications={setNotifications}
        />
      ),
    },
  ];

  return (
    <Box width="xlarge">
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        Smart Behaviour Plan: {userDetails.firstName} {userDetails.lastName}
      </BreadcrumbBar>
      {isDraft && (
        <Box
          background="stickyNote"
          direction="column"
          gap="small"
          margin={{ bottom: 'medium' }}
          round="small"
          elevation="small"
        >
          <Box
            direction="row-responsive"
            justify="between"
            pad={{ horizontal: 'medium', vertical: 'small' }}
          >
            <Text weight="bold">
              <FontAwesomeIcon icon={['fal', 'save']} /> Editing Draft:{' '}
              <Text weight="normal">
                New incidents will not be visible on this plan until the draft
                is submitted or deleted.
              </Text>
            </Text>
          </Box>
        </Box>
      )}
      <Box
        gridArea="main"
        background="white"
        direction="column"
        gap="small"
        round="small"
        flex="grow"
      >
        <Box
          gap="small"
          margin={{ horizontal: 'small' }}
          pad="small"
          round="small"
        >
          <Meter
            background="light-2"
            size="full"
            thickness="xsmall"
            type="bar"
            values={[{ color: 'brand', value: meterValue }]}
          />

          {showSubmitOptions && (
            <NotificationLayer
              button1Text="Yes"
              button1Click={submitForm}
              button2Text="No"
              button2Click={() => {
                setShowSubmitOptions(false);
              }}
            >
              Are you sure you wish to send this report to the selected key
              contacts?
            </NotificationLayer>
          )}

          {studentValues ? (
            <Tabs
              activeIndex={tabIndex}
              content={tabContent}
              onActive={onActiveTab}
              full
            />
          ) : (
            <Box align="center">
              <PulseLoader size={15} color="orange" loading={loading} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BehaviourPlan;
