import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

// Services
import LocalStorageService from '../services/LocalStorageService'
import OrganisationService from '../services/OrganisationService'
import OrganisationSubscriptionService from '../services/OrganisationSubscriptionService'
import NotificationService from '../services/NotificationService'

// Utils
import { search } from '../utils/array'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import Divider from '../components/simple/divider/Divider'
import Form from '../components/simple/form/Form.js'
import FormField from '../components/simple/formField/FormField.js'
import H2 from '../components/simple/heading/H2'
import Select from '../components/simple/input/Select'
import Text from '../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from './shared/BreadcrumbBar'

// Stripe
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'

function ServiceSubscription () {
  const history = useHistory()

  const [subscriptions, setSubscriptions] = useState([])
  const [intent, setIntent] = useState({})
  const [payConfirm, setPayConfirm] = useState({})

  const [currentNumUsers, setCurrentNumUsers] = useState(0)

  const stripe = useStripe()
  const elements = useElements()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  // Initialize userDetails otherwise we get error
  // A component is changing an uncontrolled input of type text to be controlled
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    userLicenses: ''
  })

  const selectLicense = async (license) => {
    const intent = await OrganisationSubscriptionService.intent(license.id)
    if (intent?.error) {
      NotificationService.error(intent.error)
    } else {
      setIntent(intent.data)
    }
  }

  const handlePayment = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    })

    if (payload.error) {
      NotificationService.error(payload.error.message)
    } else {
      console.log('[PaymentMethod]', payload)

      const confirmPayment = await stripe.confirmCardPayment(
        intent.clientSecret,
        {
          payment_method: payload.paymentMethod.id
        }
      )

      if (confirmPayment.error) {
        NotificationService.error(confirmPayment.error.message)
      } else {
        console.log('Confirm ', confirmPayment)
        setPayConfirm(confirmPayment.paymentIntent.id)

        NotificationService.info('Thankyou for your payment')

        const completeIntent = await OrganisationSubscriptionService.completeIntent(confirmPayment.paymentIntent.id)

        console.log('completeIntent ', completeIntent)
        if (completeIntent.error) {
          NotificationService.error(completeIntent.error.message)
        } else {
          // submitForm()
        }
      }
    }
  }

  // Submit form values
  // ToDo: Add error checking and loading spinner
  // const submitForm = async (values) => {
  //   console.log('submitForm ', values)

  //   const tagsToSubmit = []

  //   // Set up tags for Service Defaults
  //   const serviceDefaultsToSubmit = ['serviceUserTerm', 'serviceUserTermPlural', 'serviceTerm', 'serviceTermPlural', 'academicYearStartDay', 'academicYearStartMonth', 'enabledAcademicYears']

  //   serviceDefaultsToSubmit.forEach(field => {
  //     tagsToSubmit.push({
  //       organisation: activeOrg.id,
  //       key: field,
  //       type: 'besmart',
  //       value: field === 'enabledAcademicYears' ? JSON.stringify(values[field]) : values[field]
  //     })
  //   })

  //   const tagSet = await OrganisationTagService.set(apiToken, tagsToSubmit)

  //   console.log('tag set ', tagSet)

  //   NotificationService.success('Your changes have been saved')
  // }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    let unmounted = false

    const getSubscriptions = async () => {
      const params = {
        fields: 'id,name,description,annualFee,numAllowedUsers',
        limit: 1000
      }

      const subscriptions = await OrganisationSubscriptionService.available(params)
      if (subscriptions?.error) {
        NotificationService.error(subscriptions.error)
      } else {
        setSubscriptions(subscriptions.data)

        const organisation = await OrganisationService.getOrganisation(apiToken, activeOrg.id)
        if (organisation?.error) {
          NotificationService.error(organisation.error)
        } else {
          const currentSubscription = search(subscriptions.data, 'numAllowedUsers', organisation.data[0].numAllowedUsers)
          let numUsers = currentSubscription?.numAllowedUsers ? currentSubscription.numAllowedUsers : 0
          setCurrentNumUsers(numUsers)

          setSubscriptionDetails(subscriptionDetails => ({
            ...subscriptionDetails,
            userLicenses: currentSubscription
          }))
        }
      }
    }

    // getServiceSetup()
    getSubscriptions()

    return () => { unmounted = true }
  }, [])

  return (
    <Box alignSelf='start' width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Service Subscriptions
      </BreadcrumbBar>

      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>
            <Text size='large' weight={400} margin={{ top: 'none' }}>Need access for more users?</Text>
            <Text size='medium' weight={400} margin={{ top: 'none' }}>You can upgrade your account quickly and easily here.</Text>
          </Box>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              onChange={nextValue => {
                selectLicense(nextValue.userLicenses)
                setSubscriptionDetails(nextValue)
              }}
              // onSubmit={({ value: nextValue }) => {
              //   submitForm(nextValue)
              // }}
              value={subscriptionDetails}
            >

              <Text margin={{ top: 'xsmall', bottom: 'medium' }} size='large'>Subscription Management</Text>

              <Box direction='row' gap='medium' pad={{ top: 'small' }}>
                <FormField
                  label='How many users do you require?'
                  name='userLicenses'
                  required
                >
                  <Select
                    id='userLicenses'
                    labelKey='name'
                    name='userLicenses'
                    placeholder='User Licenses'
                    options={subscriptions}
                  />
                </FormField>
              </Box>

              {subscriptionDetails?.userLicenses &&
                <Box>
                  {/* <Text size='medium' weight={600}>
                    New Annual Fee: £{subscriptionDetails?.userLicenses?.annualFee / 100}
                  </Text> */}
                <Text color='status-error' size='medium'>To increase your user limit during the free trial, please contact <Anchor color='status-error' href='mailto:support@behavioursmart.co.uk'>support@behavioursmart.co.uk</Anchor>.</Text>

                </Box>}

              {/* subscriptionDetails.userLicenses?.id && subscriptionDetails.userLicenses?.numAllowedUsers !== currentNumUsers &&
                <>
                  <Divider margin={{ top: 'medium', bottom: 'none' }} />

                  <Box alignSelf='start'>
                    <H2>Payment</H2>
                    <Text>Enter your card details to continue.</Text>
                  </Box>
                  <Box direction='column' gap='medium'>
                    <Form
                      onSubmit={handlePayment}
                    >
                      <Box pad={{ horizontal: '14px', vertical: '10px' }} border={{ size: 'xsmall' }}>
                        <CardElement />
                      </Box>

                      <Box align='end'>
                        <Text color='status-error'>{subscriptionDetails.userLicenses?.name || 'Please select a License before continuing.'}</Text>
                        <Button label='Make Payment' primary type='submit' disabled={!stripe || !subscriptionDetails.userLicenses} />
                      </Box>
                    </Form>
                  </Box>
                </> */}

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ vertical: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                {/* <Button type='submit' label='Save' primary /> */}
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ServiceSubscription
