import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import List from '../../components/simple/list/List.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import ReactToPrint from "react-to-print";

// App widgets
import SimpleCounter from '../../components/compound/simpleCounter/SimpleCounter'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

import { Bar, Doughnut, Line, Polar } from 'react-chartjs-2'
import { backgroundColours, borderColours } from './colours.js';

const InvolvementReport = (props) => {
  const history = useHistory()

  const [numberOfStaffInvolvedPerIncident, setNumberOfStaffInvolvedPerIncident] = useState(0)

  const [totalNumberOfIncidentsByDate, setTotalNumberOfIncidentsByDate] = useState([])

  const [otherChildrenInvolvedInIncident, setOtherChildrenInvolvedInIncident] = useState([])
  const [staffInvolvedInIncident, setStaffInvolvedInIncident] = useState([])

  const [averageNumberOfStaffPerIncidentByHour, setAverageNumberOfStaffPerIncidentByHour] = useState([])

  const [averageNumberOfStaffPerIncidentByLocation, setAverageNumberOfStaffPerIncidentByLocation] = useState([])

  const [staffAndWitnesses, setStaffAndWitnesses] = useState([])
  const [otherChildren, setOtherChildren] = useState([])


  // Location
  const staffByLocation = {
    labels: averageNumberOfStaffPerIncidentByLocation[1],
    datasets: [
      {
        // label: '# of Votes',
        data: averageNumberOfStaffPerIncidentByLocation[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Other Children
  const otherChildrenData = {
    labels: otherChildrenInvolvedInIncident[1],
    datasets: [
      {
        // label: '# of Votes',
        data: otherChildrenInvolvedInIncident[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Other Children
  const staffData = {
    labels: staffInvolvedInIncident[1],
    datasets: [
      {
        // label: '# of Votes',
        data: staffInvolvedInIncident[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Date
  const incidentsByDate = {
    labels: totalNumberOfIncidentsByDate[1], // ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
    datasets: [
      {
        // label: '# of Incidents',
        data: totalNumberOfIncidentsByDate[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by hour
  const incidentsByHour = {
    labels: averageNumberOfStaffPerIncidentByHour[1], // ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16'],
    datasets: [
      {
        // label: '# of Incidents',
        data: averageNumberOfStaffPerIncidentByHour[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  const printScreen = () => {
    window.print()
  }

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets

    // Staff per incident
    if (props?.location?.state?.numberOfStaffInvolvedPerIncident !== null) {
      const numberOfStaffInvolvedPerIncident = props?.location?.state?.numberOfStaffInvolvedPerIncident
      if (numberOfStaffInvolvedPerIncident && numberOfStaffInvolvedPerIncident.length) {

        var average = 0
        var totalStaff = 0
        var totalCount = 0

        for (const record of numberOfStaffInvolvedPerIncident) {
          if (record.count) {
            totalStaff += record.numStaff * record.count
            totalCount += record.count
          }
        }
        average = Math.ceil(totalStaff / totalCount)
        setNumberOfStaffInvolvedPerIncident(average)
      }
    }

    // Staff
    if (props?.location?.state?.staffInvolvedInIncident) {
      const staffInvolvedInIncident = props?.location?.state?.staffInvolvedInIncident

      // Remove dupicates and add counts
      const staffCount = staffInvolvedInIncident.reduce((newArray, staff) => {
        const str = JSON.stringify(staff)
        newArray[str] = (newArray[str] || 0) + 1
        return newArray
      }, {})

      const staffInvolvedInIncidentLabels = []
      const staffInvolvedInIncidentData = []

      for (const x in staffCount) {
        staffInvolvedInIncidentLabels.push(JSON.parse(x).name)
        staffInvolvedInIncidentData.push(staffCount[x])
      }
      setStaffInvolvedInIncident([staffInvolvedInIncidentData, staffInvolvedInIncidentLabels])
    }

    // Other Children
    if (props?.location?.state?.otherChildrenInvolvedInIncident) {
      const otherChildrenInvolvedInIncident = props?.location?.state?.otherChildrenInvolvedInIncident

      // Remove dupicates and add counts
      const childrenCount = otherChildrenInvolvedInIncident.reduce((newArray, child) => {
        const str = JSON.stringify(child)
        newArray[str] = (newArray[str] || 0) + 1
        return newArray
      }, {})

      const otherChildrenInvolvedInIncidentLabels = []
      const otherChildrenInvolvedInIncidentData = []

      for (const x in childrenCount) {
        otherChildrenInvolvedInIncidentLabels.push(JSON.parse(x).name)
        otherChildrenInvolvedInIncidentData.push(childrenCount[x])
      }
      setOtherChildrenInvolvedInIncident([otherChildrenInvolvedInIncidentData, otherChildrenInvolvedInIncidentLabels])
    }

    // Date
    var totalNumberOfIncidentsByDateLabels = []
    var totalNumberOfIncidentsByDateData = []

    if (props?.location?.state?.totalNumberOfIncidentsByDate) {
      const totalNumberOfIncidentsByDate = props?.location?.state?.totalNumberOfIncidentsByDate
      if (totalNumberOfIncidentsByDate?.length) {
        totalNumberOfIncidentsByDateLabels = totalNumberOfIncidentsByDate.map((data, index) => {
          return data.month || 'n/a'
        })

        totalNumberOfIncidentsByDateData = totalNumberOfIncidentsByDate.map((data, index) => {
          return data.count
        })

        setTotalNumberOfIncidentsByDate([totalNumberOfIncidentsByDateData, totalNumberOfIncidentsByDateLabels])
      }
    }

    // staff / witnesses
    // Common Interventions
    if (props?.location?.state?.otherChildrenInvolvedInIncident) {
      const otherChildrenInvolvedInIncident = props?.location?.state?.otherChildrenInvolvedInIncident

      setOtherChildren(otherChildrenInvolvedInIncident)
    }

    // Time
    if (props?.location?.state?.totalNumberOfIncidentsByHour) {
      const averageNumberOfStaffPerIncidentByHour = props?.location?.state?.averageNumberOfStaffPerIncidentByHour

      const averageNumberOfStaffPerIncidentByHourLabels = []
      const averageNumberOfStaffPerIncidentByHourData = []

      // debugger

      for (var x in averageNumberOfStaffPerIncidentByHour) {
        if (x === 'null') { x = null }
        averageNumberOfStaffPerIncidentByHourLabels.push((x || 'n/a'))
        averageNumberOfStaffPerIncidentByHourData.push(averageNumberOfStaffPerIncidentByHour[x].numIncidents)
        console.log(averageNumberOfStaffPerIncidentByHour[x].numIncidents)
      }
      // const averageNumberOfStaffPerIncidentByHourLabels = averageNumberOfStaffPerIncidentByHour.map((data, index) => {
      //   return data.hour
      // })

      // const averageNumberOfStaffPerIncidentByHourData = averageNumberOfStaffPerIncidentByHour.map((data, index) => {
      //   return data.count
      // })

      setAverageNumberOfStaffPerIncidentByHour([averageNumberOfStaffPerIncidentByHourData, averageNumberOfStaffPerIncidentByHourLabels])
    }

    // Location
    if (props?.location?.state?.averageNumberOfStaffPerIncidentByLocation) {
      const averageNumberOfStaffPerIncidentByLocation = props?.location?.state?.averageNumberOfStaffPerIncidentByLocation

      const averageNumberOfStaffPerIncidentByLocationLabels = []
      const averageNumberOfStaffPerIncidentByLocationData = []

      for (const x in averageNumberOfStaffPerIncidentByLocation) {
        averageNumberOfStaffPerIncidentByLocationLabels.push(x)
        averageNumberOfStaffPerIncidentByLocationData.push(averageNumberOfStaffPerIncidentByLocation[x].numIncidents)
      }

      setAverageNumberOfStaffPerIncidentByLocation([averageNumberOfStaffPerIncidentByLocationData, averageNumberOfStaffPerIncidentByLocationLabels])
    }
  }, [])

  let componentRef = null
  let setComponentRef = (ref) => {
    componentRef = ref;
  };

  let reactToPrintContent = () => {
    return componentRef;
  };
  const reactToPrintTrigger = () => {
    return <Button label={<FontAwesome icon={['fal', 'print']} size='xs' />} className='noprint' plain target='_self' />
  };

  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        <Box direction="row" justify="between">
          <Text>Involvement Report</Text>

          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </Box>
      </BreadcrumbBar>

      <Box ref={setComponentRef} direction="column" gap="small" round="small">
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto', 'auto', 'auto'],
            large: ['auto', 'auto', 'auto'],
            xlarge: ['auto', 'auto', 'auto'],
          }}
          gap="small"
        >
          {props?.location?.state?.totalNumberOfIncidents ? (
            <SimpleCounter
              count={String(props.location.state.totalNumberOfIncidents)}
              label="Number of Incidents"
            />
          ) : (
            <SimpleCounter count="0" label="Number of Incidents" />
          )}

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Staff
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {staffInvolvedInIncident.length !== 0 ? (
              <Doughnut
                data={staffData}
                height={50}
                legend={{ display: false }}
                // options={{ maintainAspectRatio: false }}
                width={50}
              />
            ) : (
              <Text textAlign="center">No data available</Text>
            )}
          </Box>

          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Other{' '}
              {props?.terms?.serviceUser
                ? props.terms.serviceUser.charAt(0).toUpperCase() +
                  props?.terms?.serviceUser.slice(1)
                : 'Student'}
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {otherChildrenInvolvedInIncident.length !== 0 ? (
              <Doughnut
                data={otherChildrenData}
                height={50}
                legend={{ display: false }}
                // options={{ maintainAspectRatio: false }}
                width={50}
              />
            ) : (
              <Text textAlign="center">No data available</Text>
            )}
          </Box>
        </ResponsiveGrid>

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto'],
            large: ['auto'],
            xlarge: ['auto'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Date
            </Text>
            <Text margin={{ bottom: 'small' }} size="small">
              Total number of Incidents
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {incidentsByDate.length !== 0 ? (
              <Bar
                data={incidentsByDate}
                height={25}
                legend={{ display: false }}
                // options={{ maintainAspectRatio: false }}
                width={50}
              />
            ) : (
              <Text textAlign="center">No data available</Text>
            )}
          </Box>
        </ResponsiveGrid>

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto'],
            large: ['auto'],
            xlarge: ['auto'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Time
            </Text>
            <Text margin={{ bottom: 'small' }} size="small">
              Average number of Incidents
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {incidentsByHour.length !== 0 ? (
              <Bar
                data={incidentsByHour}
                height={25}
                legend={{ display: false }}
                // options={{ maintainAspectRatio: false }}
                width={50}
              />
            ) : (
              <Text textAlign="center">No data available</Text>
            )}
          </Box>
        </ResponsiveGrid>

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto'],
            large: ['auto'],
            xlarge: ['auto'],
          }}
          gap="small"
        >
          <Box background="white" direction="column" pad="small" round="small">
            <Text size="medium" weight="bold">
              Location
            </Text>
            <Text margin={{ bottom: 'small' }} size="small">
              Average # of staff
            </Text>
            <Divider
              color="primary"
              margin={{ top: 'none', bottom: 'small' }}
            />

            {staffByLocation.length !== 0 ? (
              <Polar
                data={staffByLocation}
                height={25}
                legend={{ display: false }}
                // options={{ maintainAspectRatio: false }}
                width={50}
              />
            ) : (
              <Text textAlign="center">No data available</Text>
            )}
          </Box>
        </ResponsiveGrid>
      </Box>
    </>
  );
}

export default InvolvementReport
