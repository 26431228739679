import React from 'react'

// Appt Components
import Select from '../../../components/simple/input/Select'
import { capitalize } from '../../../utils/text'

import LocalStorageService from '../../../services/LocalStorageService'

import constants from '../../../utils/constants'

const FieldBox = (props) => {
  // replace the name of the service user with the stored term
  const terms = LocalStorageService.get('terms')

  var searchFields = []
  for (var thisField of constants.reportSearch) {
    if (thisField?.label.includes('[Student]')) {
      thisField.label = thisField.label.replace('[Student]', capitalize(terms?.serviceUser || 'Service User'))
    }
    searchFields.push(thisField)
  }

  // sort alphabetically
  //searchFields.sort(function (a, b) {
  //  return a.label.localeCompare(b.label)
  //})

  return (
    <>
      <Select
        {...props}
        // name='reportSearch'
        labelKey='label'
        onChange={({ value: nextValue }) => {
          props.handleFieldChanged(props.name, nextValue)
        }}
        options={searchFields}
        valueKey={{ key: 'value' }}
      />
    </>
  )
}

export default FieldBox
