import React, { useState } from 'react'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function BehaviourDetails (props) {
  const [questions, setQuestions] = useState(props.questions)
  const [localIncidentValues, setLocalIncidentValues] = useState(props.incidentValues)

  const [editingDisabled] = useState(props.formId)


  const previousPage = () => {
    props.previousPage(localIncidentValues)
  }

  const nextPage = () => {
    props.nextPage(localIncidentValues)
  }

  // Submit form values
  const submitForm = async () => {
    props.submitForm(localIncidentValues, 'Draft')
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Behaviour Details</H2>
          <Button
            label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
            plain
            tip={{
              content: (
                <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                  <Text>When completing the low to high level behaviour section what you put in the lowlevel medium level and high level boxes will be automatically added to the Smart Plan.</Text>
                  <Text>With this in mind if a child was tapping on a table we would input "tapping on table" if our response was we made the individual laugh we would just input the word "humour".</Text>
                </Box>)
            }}
          />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            setLocalIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={localIncidentValues}
        >
          <Box>
            {/* Description */}
            <FormField
              label={questions[9].questionText}
              name={questions[9].key}
            >
              {!editingDisabled && <TextArea
                name={questions[9].key}
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[9].key] || ''}</Text>}
            </FormField>
          </Box>


          <Box>
            <Text weight='bold'>As concisely as possible</Text>
          </Box>
          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['1/3', '1/3', '1/3'],
              large: ['1/3', '1/3', '1/3'],
              xlarge: ['1/3', '1/3', '1/3']
            }}
            gap='small'
          >
            {/* Low level behaviour student shows */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[0].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Low level behaviours might include:</Text>
                          <Text>Making noises</Text>
                          <Text>Going quiet</Text>
                          <Text>Showing signs of anxiety</Text>
                          <Text>Hiding face in hands</Text>
                          <Text>Pulling down hat</Text>
                          <Text>Tapping</Text>
                          <Text>Withdrawing from group</Text>
                          <Text>Refusing to speak or dismissive</Text>
                          <Text>Refusing to co-operate</Text>
                          <Text>Eye contact increased or decreased</Text>
                          <Text>Changes in breathing</Text>
                        </Box>)
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[0].key}
              required
            >
              {!editingDisabled && <TextInput
                name={questions[0].key}
                type='text'
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[0].key] || ''}</Text>}
            </FormField>

            {/* Low level how respond */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Box height='40px'>
                    <Text size='small'>{questions[1].questionText}</Text>
                  </Box>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Low level responses:</Text>
                          <Text>Read the body language</Text>
                          <Text>Read the behaviour - Assess the situation</Text>
                          <Text>Intervene early</Text>
                          <Text>Communicate - "Talk and I'll listen"</Text>
                          <Text>Inform of desired Behaviour</Text>
                          <Text>Use appropriate humour</Text>
                          <Text>Dispay CALM stance & body language</Text>
                          <Text>Talk low and slow and quietly</Text>
                          <Text>Offer reassurance - including positive physical prompts</Text>
                          <Text>Divert and distract by introducing another activity or topic</Text>
                        </Box>)
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[1].key}
              required
            >
              {!editingDisabled && <TextInput
                name={questions[1].key}
                type='text'
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[1].key] || ''}</Text>}

            </FormField>

            {/* Low level strategies */}
            <FormField
              label={<Box height='40px'><Text size='small'>{questions[2].questionText}</Text></Box>}
              name={questions[2].key}
              required
            >
              {!editingDisabled && <TextInput
                name={questions[2].key}
                type='text'
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[2].key] || ''}</Text>}

            </FormField>

            {/* Medium level behaviour student shows */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[3].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Medium level behaviours might include:</Text>
                          <Text>Adopting defensive postures</Text>
                          <Text>Body position & tension- arms crossed</Text>
                          <Text>Clenched jaws or fists</Text>
                          <Text>Making personal and offensive remarks</Text>
                          <Text>Talking louder – higher - quicker</Text>
                          <Text>Adopting aggressive postures</Text>
                          <Text>Changes in eye contact</Text>
                          <Text>Pacing around</Text>
                          <Text>Breaking minor rules</Text>
                          <Text>Low level destruction</Text>
                          <Text>Picking up objects which could be used as weapons</Text>
                          <Text>Beligerant and abusive</Text>
                        </Box>),
                      dropProps: { plain: true }
                    }}
                  />
                </Box>)}
              name={questions[3].key}
              required
            >
              {!editingDisabled && <TextInput
                name={questions[3].key}
                type='text'
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[3].key] || ''}</Text>}
            </FormField>

            {/* Medium level how respond */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Box height='40px'>
                    <Text size='small'>{questions[4].questionText}</Text>
                  </Box>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Continue to use Level 1 de-escalation responses +</Text>
                          <Text>State desired behaviours clearly</Text>
                          <Text>Set clear enforceable limits</Text>
                          <Text>Offer alternatives and options</Text>
                          <Text>Offer clear choices</Text>
                          <Text>Give a get out with dignity</Text>
                          <Text>Assess the situation and consider making the environment safer and getting help</Text>
                          <Text>Guide the elbows towards safety</Text>
                        </Box>)
                    }}
                  />
                </Box>)}
              name={questions[4].key}
              required
            >
              {!editingDisabled && <TextInput
                name={questions[4].key}
                type='text'
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[4].key] || ''}</Text>}
            </FormField>

            {/* Medium level strategies */}
            <FormField
              label={<Box height='40px'><Text size='small'>{questions[5].questionText}</Text></Box>}
              name={questions[5].key}
              required
            >
              {!editingDisabled && <TextInput
                name={questions[5].key}
                type='text'
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[5].key] || ''}</Text>}
            </FormField>

            {/* High level behaviour student shows */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[6].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>High level behaviours might include:</Text>
                          <Text>Hurting self</Text>
                          <Text>Hurting others</Text>
                          <Text>Damaging property</Text>
                          <Text>Throwing objects</Text>
                          <Text>Picking up objects which could be used as weapons</Text>
                          <Text>Kicking</Text>
                          <Text>Punching</Text>
                        </Box>)
                    }}
                  />
                </Box>)}
              name={questions[6].key}
              required
            >
              {!editingDisabled && <TextInput
                name={questions[6].key}
                type='text'
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[6].key] || ''}</Text>}

            </FormField>

            {/* High level how respond */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Box height='40px'>
                    <Text height='large' size='small'>{questions[7].questionText}</Text>
                  </Box>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Continue to use all the Level 1 and 2 de-escalation responses +</Text>
                          <Text>Make the environment safer</Text>
                          <Text>Moving furniture and removing weapon objects</Text>
                          <Text>Guide assertively - hold or restrain if absolutely necessary</Text>
                          <Text>Ensure face, voice and posture are supportive not aggressive</Text>
                          <Text>Use "Help Protocol" to save face by cahnging face</Text>
                        </Box>)
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[7].key}
            >
              {!editingDisabled && <TextInput
                name={questions[7].key}
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[7].key] || ''}</Text>}

            </FormField>

            {/* High level strategies */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Box height='40px'>
                    <Text height='large' size='small'>{questions[8].questionText}</Text>
                  </Box>
                </Box>)}
              name={questions[8].key}
            >
              {!editingDisabled && <TextInput
                name={questions[8].key}
              />}
              {editingDisabled && <Text>{props.incidentValues?.[questions[8].key] || ''}</Text>}
            </FormField>

            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[10].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Recovery behaviours might include:</Text>
                          <Text>Crying</Text>
                          <Text>Apologising</Text>
                          <Text>Defensive postures Body position & tension- arms crossed</Text>
                          <Text>Making personal and offensive remarks </Text>
                          <Text>Changes in eye contact</Text>
                          <Text>Breaking minor rules</Text>
                          <Text>Belligerent and abusive</Text>
                          <Text>Being quiet</Text>
                          <Text>Hiding face in hands</Text>
                          <Text>Pulling down hat</Text>
                          <Text>Tapping</Text>
                          <Text>Withdrawing from group</Text>
                          <Text>Refusing to speak or dismissive</Text>
                          <Text>Refusing to co-operate</Text>
                          <Text>Eye contact increased or decreased</Text>
                          <Text>Changes in breathing</Text>
                        </Box>)
                    }}
                  />
                </Box>)}
              name={questions[10].key}
              required
            >
              <TextInput
                name={questions[10].key}
                type='text'
              />
            </FormField>

            <FormField
              label={<Box height='40px'><Text size='small'>{questions[11].questionText}</Text></Box>}
              name={questions[11].key}
              required
            >
              <TextInput
                name={questions[11].key}
                type='text'
              />
            </FormField>

            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[12].questionText}</Text>
                </Box>)}
              name={questions[12].key}
              required
            >
              <TextInput
                name={questions[12].key}
                type='text'
              />
            </FormField>
          </ResponsiveGrid>

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

          <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            <Button label='Next Page' onClick={() => nextPage()} primary />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default BehaviourDetails
