import React from 'react'

import { useHistory } from 'react-router-dom'

// Grommet
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import LocalStorageService from '../../services/LocalStorageService.js';

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function Reports (props) {
  const history = useHistory()
  const activeOrg = LocalStorageService.get('activeOrg');
  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        Analytics
      </BreadcrumbBar>

      <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          direction="row-responsive"
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text margin={{ top: 'xsmall' }} size="xlarge">
            Available Analytics
          </Text>
        </Box>
      </Box>

      <ResponsiveGrid
        rows={['auto']}
        columns={{
          small: ['auto'],
          medium: ['33%', '33%', '33%'],
          large: ['33%', '33%', '33%'],
          xlarge: ['33%', '33%', '33%'],
        }}
        gap="small"
      >
        <Box background="white" margin="xsmall" pad="small" round="small">
          <Button
            onClick={() => {
              history.push('/incident/report/search');
            }}
            icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />}
            fill="horizontal"
            label="Global Incident Report"
            plain
          />
          <Box align="center" width="100%" margin={{ top: 'xsmall' }}>
            <Text size="small">
              Top individuals, groups, times and locations.
            </Text>
          </Box>
        </Box>
        {/* <Box background='white' margin='xsmall' pad='small' round='small'>
          <Button disabled icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />} fill='horizontal' label='Global Behaviour Report' plain />
        </Box> */}
        <Box background="white" margin="xsmall" pad="small" round="small">
          <Button
            onClick={() => {
              history.push('/intervention/report/search');
            }}
            icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />}
            fill="horizontal"
            label="Intervention Report"
            plain
          />
          <Box align="center" width="100%" margin={{ top: 'xsmall' }}>
            <Text size="small">Overview of interventions.</Text>
          </Box>
        </Box>
        <Box background="white" margin="xsmall" pad="small" round="small">
          <Button
            onClick={() => {
              history.push('/involvement/report/search');
            }}
            icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />}
            fill="horizontal"
            label="Involvement Report"
            plain
          />
          <Box align="center" width="100%" margin={{ top: 'xsmall' }}>
            <Text size="small">Key involvement in incidents.</Text>
          </Box>
        </Box>
        <Box background="white" margin="xsmall" pad="small" round="small">
          <Button
            onClick={() => {
              history.push('/medicalIntervention/report/search');
            }}
            icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />}
            fill="horizontal"
            label="Medical Intervention Report"
            plain
          />
          <Box align="center" width="100%" margin={{ top: 'xsmall' }}>
            <Text size="small">Overview of medical intervention.</Text>
          </Box>
        </Box>

        {activeOrg.enablePILExtras ? (
          <>
            <Box background="white" margin="xsmall" pad="small" round="small">
              <Button
                onClick={() => {
                  history.push('/incidentreportExtras/report/search');
                }}
                icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />}
                fill="horizontal"
                label="Discriminatory and prejudiced behaviour. Suspensions and
                  exclusions."
                plain
              />
            </Box>
          </>
        ) : (
          <></>
        )}
      </ResponsiveGrid>
    </>
  );
}

export default Reports
